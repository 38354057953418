
export interface PropertyModel {
    id: any
    assignements:any
    candidatures:any
    loader: boolean
    properties_list:any
    typology:any
    category:any
    lot:any
    surface:any
    loyer:any
    address:any
    candidates:any
    tenant_email:any
    prop_id:any
}

export const initsProperty: PropertyModel = {
    tenant_email:"",
    prop_id:"",
    id:'',
    assignements:[],
    candidatures:[],
    loader: false,
    properties_list:[],
    candidates:0,
    typology:"",
    category:"",
    lot:"",
    surface:"",
    loyer:"",
    address:"",

}


