/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { ErrorMessage } from 'formik'
import { KTSVG } from '../../../../../_metronic/helpers'

const Etape10: React.FC<{ handleChangeCheckbox: any, onChange: any, state: any }> = ({ handleChangeCheckbox, onChange, state }) => {
  return (
    <div className='w-100' key={`etape10
    `}>


      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>		Montant ou fourchette inscrit dans le diagnostic de performance énergétique  </span>
        </label>
        <input
          type='number'
          className='form-control form-control-lg form-control-solid'
          name={`depenses_energetiques`}
          placeholder=''
          onChange={onChange}
          value={state.depenses_energetiques}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={`depenses_energetiques`} />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>	Année de référence des prix énergétiques du diagnostic énergétique à l'origine de l'estimation </span>
        </label>
        <input
          type='number'
          className='form-control form-control-lg form-control-solid'
          name={`annee_reference_estimation`}
          placeholder=''
          onChange={onChange}
          value={state.annee_reference_estimation}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={`annee_reference_estimation`} />
        </div>
      </div>


      <div className=' mb-10 form-check form-switch form-switch-sm form-check-custom form-check-solid'>
        <input
          className='form-check-input'
          type='checkbox'
          onChange={handleChangeCheckbox}

          value={state.travaux_conformite}
          name='travaux_conformite'
        // defaultChecked={true}
        />
        <label className='form-label fw-bold mx-3'>      si travaux de mise en conformité</label>
      </div>

      {state.travaux_conformite && (<>
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>montant des travaux de mise en conformité		</span>
          </label>
          <input
            type='number'
            className='form-control form-control-lg form-control-solid'
            name={`montant_travaux_conformite`}
            placeholder=''
            onChange={onChange}
            value={state.montant_travaux_conformite}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`montant_travaux_conformite`} />
          </div>
        </div>
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>nature des travaux de mise en conformité	</span>
          </label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={`nature_travaux_conformite`}
            placeholder=''
            onChange={onChange}
            value={state.nature_travaux_conformite}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`nature_travaux_conformite`} />
          </div>
        </div>
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>			montant de majoration du loyer  </span>
          </label>
          <input
            type='number'
            className='form-control form-control-lg form-control-solid'
            name={`montant_majoration`}
            placeholder=''
            onChange={onChange}
            value={state.montant_majoration}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`montant_majoration`} />
          </div>
        </div>
      </>)}





      {/* <div className=' mb-10 form-check form-switch form-switch-sm form-check-custom form-check-solid'>
        <input
          className='form-check-input'
          type='checkbox'
          onChange={handleChangeCheckbox}

          value={state.travaux_amelioration}
          name='travaux_amelioration'
        // defaultChecked={true}
        />
        <label className='form-label fw-bold mx-3'> si travaux ou achat équipement pour l'amélioration du logement</label>
      </div>
      {state.travaux_amelioration && (<>
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>nature travaux			</span>
          </label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={`nature_travaux_amelioration`}
            placeholder=''
            onChange={onChange}
            value={state.nature_travaux_amelioration}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`nature_travaux_amelioration`} />
          </div>
        </div>
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>	nature équipements </span>
          </label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={`nature_equipements`}
            placeholder=''
            onChange={onChange}
            value={state.nature_equipements}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`nature_equipements`} />
          </div>
        </div>

        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>		modalité exécution </span>
          </label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={`modalite_execution`}
            placeholder=''
            onChange={onChange}
            value={state.modalite_execution}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`modalite_execution`} />
          </div>
        </div>

        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>			délai de réalisation/ acquisition  </span>
          </label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={`delai_realisation`}
            placeholder=''
            onChange={onChange}
            value={state.delai_realisation}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`delai_realisation`} />
          </div>
        </div>


        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>			montant de majoration du loyer  </span>
          </label>
          <input
            type='number'
            className='form-control form-control-lg form-control-solid'
            name={`montant_majoration`}
            placeholder=''
            onChange={onChange}
            value={state.montant_majoration}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`montant_majoration`} />
          </div>
        </div>
      </>)} */}


      <div className=' mb-10 form-check form-switch form-switch-sm form-check-custom form-check-solid'>
        <input
          className='form-check-input'
          type='checkbox'
          onChange={handleChangeCheckbox}

          value={state.diminution_depart}
          name='diminution_depart'
        // defaultChecked={true}
        />
        <label className='form-label fw-bold mx-3'>       si diminution de loyer ou départ anticipé en raison de travaux</label>
      </div>

      {state.diminution_depart && (<>
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'> montant diminution  </span>
          </label>
          <input
            type='number'
            className='form-control form-control-lg form-control-solid'
            name={`montant_diminution`}
            placeholder=''
            onChange={onChange}
            value={state.montant_diminution}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`montant_diminution`} />
          </div>
        </div>
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'> durée diminution</span>
          </label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={`duree_diminution`}
            placeholder=''
            onChange={onChange}
            value={state.duree_diminution}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`duree_diminution`} />
          </div>
        </div>
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'> dédommagement sur justification des dépenses en cas de départ </span>
          </label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={`dedommagement`}
            placeholder=''
            onChange={onChange}
            value={state.dedommagement}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`dedommagement`} />
          </div>
        </div>

      </>)}


    </div>
  )
}

export { Etape10 }
