/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { ErrorMessage } from 'formik'
import { KTSVG } from '../../../../../_metronic/helpers'

const Etape8: React.FC<{ onChange: any, state: any }> = ({ onChange, state }) => {
  return (
    <div className='w-100' key={`etape8`}>

{/* 
<div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>Échéances de paiement*</span>
        </label>

<select
          name='echeance_paiement'
          className='form-select form-select-lg form-select-solid'
          onChange={onChange}
          value={state.echeance_paiement}
        >
          <option value="" disabled>choisir  </option>
          <option value='A échoir'>A échoir</option>
          <option value='A terme échu'>A terme échu</option>

        </select> 
        <div className='text-danger mt-2'>
          <ErrorMessage name={`echeance_paiement`} />
        </div>
      </div> */}
      <label className='d-flex align-items-center form-label mb-5'>
      Pouvez-vous et souhaitez vous ajouter un complément de loyer :      </label>
      <div className='mb-10'>

        <label className='d-flex flex-stack mb-5 cursor-pointer'>
          <span className='d-flex align-items-center me-2'>

            <span className='form-check form-check-custom form-check-solid me-2'>
              <input className='form-check-input' onChange={onChange}
                type='radio' name='add_comp_loyer' value='yes' />
            </span>
            <span className='d-flex flex-column'>
              <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                oui    </span>
              {/* <span className='fs-6 fw-bold text-gray-400'>Use images to your post time</span> */}
            </span>
          </span>


        </label>

        <label className='d-flex flex-stack mb-5 cursor-pointer'>
          <span className='d-flex align-items-center me-2'>
            <span className='form-check form-check-custom form-check-solid me-2'>
              <input className='form-check-input' type='radio' onChange={onChange}
                name='add_comp_loyer' value='no' />
            </span>

            <span className='d-flex flex-column'>
              <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                non
              </span>
            </span>
          </span>

        </label>

      </div>
{state.add_comp_loyer=='yes' && (<>
  <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>complément de loyer</span>
        </label>
        <input
          type='number'
          className='form-control form-control-lg form-control-solid'
          name={`rent_supplement_ammount`}
          placeholder=''
          onChange={onChange}
          value={state.rent_supplement_ammount}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={`rent_supplement_ammount`} />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>Justificatif complément de loyer</span>
        </label>
        <input
          type='file'
          className='form-control form-control-lg form-control-solid'
          name={`rent_supplement_proof`}
          placeholder=''
          onChange={onChange}
          value={state.rent_supplement_proof}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={`rent_supplement_proof`} />
        </div>
      </div></>)}


{/*       
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>Modalités de fixation du loyer</span>
        </label>
        <input
          type='text'
          className='form-control form-control-lg form-control-solid'
          name={`fixation_loyer`}
          placeholder=''
          onChange={onChange}
          value={state.fixation_loyer}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={`fixation_loyer`} />
        </div>
      </div>
    
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>Date de révision</span>
        </label>
        <input
          type='date'
          className='form-control form-control-lg form-control-solid'
          name={`date_revision`}
          placeholder=''
          onChange={onChange}
          value={state.date_revision}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={`date_revision`} />
        </div>
      </div> */}





    </div>
  )
}

export { Etape8 }
