import { EstimationModel } from '../models/EstimationModel'
import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL || 'api'
const ESTIMATE_URL = `${API_URL}/estimation/estimate/`

// Server should return EstimationModel
export async function estimation(
  address: string, 
  detail_address: any,
  classification_dpe: number, 
  classification_gaz: number, 
  nb_pieces: number,
  surface: number,
  type_bien: number,
  valeur_dpe: number,
  valeur_gaz: number,
  date_built: number,
  Type_chauffage: number,
  date_realisation_dpe: number,
  chauffage: number,
  Cheminee: number,
  climatisation: number,
  nb_chambres: number,
  nb_SDB: number,
  nb_SDE: number,
  nb_WC: number,
  dernier_etage: number,
  Num_etage: number,
  Ascenseur: number,
  Orientation: number,
  Etat_bien: number,
  Vue: number,
  parkingExt: number,
  parkingInt: number,
  garage: number,
  balcon: number,
  terrasse: number,
  veranda: number,
  jardin: number,
  piscine: number,
  cave: number,
  localPoubelle: number,
  box: number,
  surface_terrain: number,
  calme: number,
  bruyant: number
  
  ) {
    // console.log('ESTIMATE_URL :::',ESTIMATE_URL)
    // console.log(" ##")
    const allvals = {
      address,
      detail_address,
      classification_dpe,
      classification_gaz,
      nb_pieces,
      surface,
      type_bien,
      valeur_dpe,
      valeur_gaz,
      date_built,
      Type_chauffage,
      date_realisation_dpe,
      chauffage,
      Cheminee,
      climatisation,
      nb_chambres,
      nb_SDB,
      nb_SDE,
      nb_WC,
      dernier_etage,
      Num_etage,
      Ascenseur,
      Orientation,
      Etat_bien,
      Vue,
      parkingExt,
      parkingInt,
      garage,
      balcon,
      terrasse,
      veranda,
      jardin,
      piscine,
      cave,
      localPoubelle,
      box,
      surface_terrain,
      calme,
      bruyant,
    }
    // var normalAxios = axios.create();
  return await axios.post(ESTIMATE_URL, {
    address,
    detail_address,
    classification_dpe,
    classification_gaz,
    nb_pieces,
    surface,
    type_bien,
    valeur_dpe,
    valeur_gaz,
    date_built,
    Type_chauffage,
    date_realisation_dpe,
    chauffage,
    Cheminee,
    climatisation,
    nb_chambres,
    nb_SDB,
    nb_SDE,
    nb_WC,
    dernier_etage,
    Num_etage,
    Ascenseur,
    Orientation,
    Etat_bien,
    Vue,
    parkingExt,
    parkingInt,
    garage,
    balcon,
    terrasse,
    veranda,
    jardin,
    piscine,
    cave,
    localPoubelle,
    box,
    surface_terrain,
    calme,
    bruyant,
  }).then(response => { 
    return response
  })
  .catch(error => {
      return error
  });
}
