import React, { FC, useState } from 'react'
import { ErrorMessage } from 'formik'
import { Adjacence } from './typeadjacence'

const SimulateurStep7: React.FC<{ onChange: any, state: any }> = ({ onChange, state }) => {

    return (
        ///////////////////////
        //    :"", 
        //     several_facade :"",
        //      :"",
        <div className='w-100' key={`simstep7`}>
         
            
            <div className='fv-row mb-10'>
                <label className='form-label required mb-2'>
                Type ventilation
                </label>
                <select
                    name='vitrage_menuisier'
                    className='form-select form-select-lg form-select-solid border-dark'
                    onChange={onChange}
                    value={state.vitrage_menuisier}
                >
                    <option value="" disabled>choisir </option>
                    <option value='1'>Simple vitrage</option>
                    <option value='2'>Double vitrage</option>
                    <option value='3'>Survitrage</option>
                    <option value='4'>Triple vitrage</option>
                </select>
            </div>
            <div className='fv-row mb-10'>

<label className='form-label required mb-2'>facade </label>

<input
    type='number'
    className='form-control form-control-lg form-control-solid border-dark'
    name={`longueur_fenetre`}
    placeholder=''
    onChange={onChange}
    value={state.longueur_fenetre}
/>
<div className='text-danger mt-2'>
    <ErrorMessage name={`longueur_fenetre`} />
</div>
</div>
<div className=' mb-10 form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                        <input
                            className='form-check-input'
                            type='checkbox'
                            //   onChange={handleChangeCheckbox}
                            value={state.joint_menuiserie}
                            name='joint_menuiserie'
                        // defaultChecked={true}
                        />
                        <label className='form-label fw-bold fs-4 mx-3'> Contient  joint menuisier</label>
                    </div>

        </div>
    )
}

export { SimulateurStep7 }
