// import jwt_decode from 'jwt-decode'
// import dayjs from 'dayjs'
// import {AuthModel} from '../../app/modules/auth/models/AuthModel'
// import {UserModel} from '../../app/modules/auth/models/UserModel'
export interface JwtDecoded {
  token_type: string
  exp: number
  jti: string
  user_id: number
}

// const API_URL = process.env.REACT_APP_API_URL || 'api'

export default function setupAxios(axios: any, store: any) {
  
  axios.interceptors.request.use(
    async(config: any) => {
      const {
        auth: {accessToken,
          //  refreshToken
          },
      } = store.getState()

      if (accessToken) {
        
        config.headers.Authorization = `Bearer ${accessToken}`

      }
      return config
    },
    (err: any) => Promise.reject(err)
  )
}
