import React, { FC, useState } from 'react'
import { ErrorMessage } from 'formik'
import { Adjacence ,TypePorte} from './typeadjacence'
import { KTSVG } from '../../../../_metronic/helpers'

const SimulateurStep3: React.FC<{ addPorte:any,editPorte:any, deletePorte:any, onChange: any, state: any }> = ({addPorte,editPorte,deletePorte, onChange, state }) => {

    return (
 <div className='d-flex flex-column flex-lg-row'>
            <div className='flex-column flex-lg-row-auto w-100 w-lg-400px w-xl-550px mb-10 mb-lg-0'>

            <div className='fv-row mb-10'>

                <label className='form-label required mb-2'>Longueur porte</label>

                <input
                    type='number'
                    className='form-control form-control-lg form-control-solid border-dark'
                    name={`longueur_porte`}
                    placeholder=''
                    onChange={onChange}
                    value={state.longueur_porte}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name={`longueur_porte`} />
                </div>
            </div>
            <div className='fv-row mb-10'>

<label className='form-label required mb-2'>Largeur porte</label>

<input
    type='number'
    className='form-control form-control-lg form-control-solid border-dark'
    name={`largeur_porte`}
    placeholder=''
    onChange={onChange}
    value={state.largeur_porte}
/>
<div className='text-danger mt-2'>
    <ErrorMessage name={`largeur_porte`} />
</div>
</div>
            <Adjacence onChange={onChange} statevalue={state.adjacence_porte} name="adjacence_porte" />
            <TypePorte onChange={onChange} statevalue={state.type_materiau_porte} name="type_materiau_porte" />

      <br />
                <button type="button" className='btn btn-lg btn-info w-100 ' onClick={addPorte}>
                    <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                    Sauvegarder
                </button>
            </div>

            <div className='flex-lg-row-fluid ms-lg-7  w-xl-400px ms-xl-10'>
                <div className='card card-xl-stretch mb-5 mb-xl-8'>


                    <div className='card-body pt-0' style={{ overflowY: "scroll", height: "650px" }}>

                        {state.array_portes.length > 0 && state.array_portes.map((porte: any, idx: any) => (<>
                            <div className='d-flex align-items-center bg-light-info rounded p-5'>
                                <span className='svg-icon svg-icon-info me-5'>
                                    <KTSVG path='/media/icons/duotune/general/gen028.svg' className='svg-icon-1' />
                                </span>

                                <div className='flex-grow-1 me-2'>
                                    <a href='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>
                                        porte n° {idx + 1} : {porte.largeur_porte && porte.longueur_porte && parseInt(porte.largeur_porte) * parseInt(porte.longueur_porte) + " m²"}
                                    </a>
                                    {/* <span className=' fw-bold d-block'>isolation : {porte.isolation_porte}</span> */}
                                </div>
                                <button type="button" className='btn btn-sm btn-warning me-5' onClick={e => editPorte(idx)}>
                                    <span className='svg-icon svg-icon-warning'>
                                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-1' />
                                    </span>
                                </button>
                                <button type="button" className='btn btn-sm btn-danger ' onClick={e => deletePorte(idx)}>
                                    <span className='svg-icon svg-icon-danger'>
                                        <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-1' />
                                    </span></button>
                            </div>
                            <br />
                        </>
                        ))}

                    </div>
                </div>
            </div>
        </div>


    )
}

export { SimulateurStep3 }
