import React, {FC, useEffect, useRef, useState} from 'react'
import {SimulateurStep1} from './simulateurSteps/SimulateurStep1'
import {SimulateurStep2} from './simulateurSteps/SimulateurStep2'
import {SimulateurStep3} from './simulateurSteps/SimulateurStep3'
import {SimulateurStep4} from './simulateurSteps/SimulateurStep4'
import {SimulateurStep5} from './simulateurSteps/SimulateurStep5'
import {SimulateurStep6} from './simulateurSteps/SimulateurStep6'
import {SimulateurStep7} from './simulateurSteps/SimulateurStep7'
import {SimulateurStep8} from './simulateurSteps/SimulateurStep8'
import {SimulateurStep9} from './simulateurSteps/SimulateurStep9'
import {SimulateurStep10} from './simulateurSteps/SimulateurStep10'
import SimulateurStep11 from './simulateurSteps/SimulateurStep11'
import {KTSVG} from '../../../_metronic/helpers'
import toast from 'react-hot-toast'
import {StepperComponent} from '../../../_metronic/assets/ts/components'
import {Formik, Form, FormikValues} from 'formik'
import {stepMur} from './simulateurController'
import {
  createAccountSchemas,
  SimulateurDpeModel,
  inits,
  Mur,
  Plafond,
  Plancher,
  Fenetre,
  Porte,
  ECS,
  Ventilation,
} from './simulateurModel'
import {array} from 'yup'
import {stat} from 'fs'

const SimulateurDpe: FC = () => {
  const SimulateurStepperRef = useRef<HTMLDivElement | null>(null)
  const SimulateurStepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [state, setState] = useState<SimulateurDpeModel>(inits)
  const [isSubmitButton, setSubmitButton] = useState(false)
  var sum_dir = 0
  const loadSimulateurStepper = () => {
    SimulateurStepper.current = StepperComponent.createInsance(
      SimulateurStepperRef.current as HTMLDivElement
    )
  }

  const prevSimulateurStep = () => {
    if (!SimulateurStepper.current) {
      return
    }

    setSubmitButton(
      SimulateurStepper.current.currentStepIndex === SimulateurStepper.current.totatStepsNumber! - 1
    )

    SimulateurStepper.current.goPrev()

    setCurrentSchema(createAccountSchemas[SimulateurStepper.current.currentStepIndex - 1])
  }

  const submitSimulateurStep = (values: SimulateurDpeModel, actions: FormikValues) => {
    if (!SimulateurStepper.current) {
      return
    }

    setSubmitButton(
      SimulateurStepper.current.currentStepIndex === SimulateurStepper.current.totatStepsNumber! - 1
    )

    setCurrentSchema(createAccountSchemas[SimulateurStepper.current.currentStepIndex])

    if (SimulateurStepper.current.currentStepIndex !== SimulateurStepper.current.totatStepsNumber) {
      SimulateurStepper.current.goNext()
    } else {
      SimulateurStepper.current.goto(1)
      // actions.resetForm()
    }
    if (SimulateurStepper.current.currentStepIndex <= SimulateurStepper.current.totatStepsNumber) {
      const etape = SimulateurStepper.current.currentStepIndex
      // console.log("currentStep=", etape)
      //    console.log("state=",initValues)

      if (etape == 3) {
        //  document_df_url

        state.array_Murs.forEach((mur) => { 
    

          var data = {
            
              "departement": state.departement ? state.departement : 0,
              "periode_construction": state.periode_construction_logement ? state.periode_construction_logement : 0,
              "hsp": mur.longueur_mur,
              "largeur_mur":  mur.largeur_mur,
              "id_isolation_mur": mur.etat_isolation,
              "materiaux_structure_mur": mur.materiau_mur,
              "epaisseur_structure": mur.epaisseur_mur,
              "id_adjacence": mur.adjacence_mur,
              "id_orientation_mur": mur.orientation_mur,
              "isolation_adjacence": mur.isolation_adjacence_mur,
              "effet_joule": mur.effet_joule
            
          };
         
          console.log(state.array_Murs)
          stepMur(data)
            .then((response) => {
              toast.success('deperdition mur  sauvegardés avec succès !')
              console.log(response.data)
              sum_dir = sum_dir + response.data.depertition_mur
              console.log('sum : ', sum_dir)
              state.sum_deperdition_mur = sum_dir

              console.log('state', state.sum_deperdition_mur)
              SimulateurStepper.current?.goto(3)
            })
            .catch((error) => {
             
              console.log(data)
              toast.error("Une erreur s'est produite ")

              console.log(error)
            })
          })
      }
      if (etape == 4) {
        //  document_df_url
        console.log('state', state.sum_deperdition_mur)
     /*    state.array_plafond.forEach((plafond) => { 
    

          var data = {
            
            
              "departement": 0,
              "periode_construction": 0,
              "largeur_plafond": 0,
              "longeur_plafond": 0,
              "id_isolation_plafond": 0,
              "id_type_plancher_haut": 0,
              "id_adjacence_plafond": 0,
              "id_orientation_plafond": 0,
              "id_isolation_adjacence": 0,
              "id_type_toiture": 0,
              "effet_joule": 0
            
            
          };
         
          console.log(state.array_Murs)
          stepMur(data)
            .then((response) => {
              toast.success('deperdition mur  sauvegardés avec succès !')
              console.log(response.data)
              sum_dir = sum_dir + response.data.depertition_mur
              console.log('sum : ', sum_dir)
              state.sum_deperdition_mur = sum_dir

              console.log('state', state.sum_deperdition_mur)
              SimulateurStepper.current?.goto(3)
            })
            .catch((error) => {
             
              console.log(data)
              toast.error("Une erreur s'est produite ")

              console.log(error)
            })
          }) */
      }
    
    } else {
      SimulateurStepper.current.goNext()
    }
  }

  useEffect(() => {
    if (!SimulateurStepperRef.current) {
      return
    }

    loadSimulateurStepper()
  }, [SimulateurStepperRef])

  const handleChange = (event: any) => {
    setState((prevValues) => ({
      ...prevValues,
      [event.target.name]: event.target.value,
    }))
  }
  ////////////////////////////////////////////////////////////////////////
  const addWall = () => {
    console.log('addowner')
    var new_wall: Mur = {
      longueur_mur: state.longueur_mur ? state.longueur_mur : 0,
      largeur_mur: state.largeur_mur ? state.largeur_mur : 0,
      etat_isolation: state.etat_isolation ? state.etat_isolation : 0,
      effet_joule: state.effet_joule ? state.effet_joule : 0,
      epaisseur_mur: state.epaisseur_mur ? state.epaisseur_mur : 0,
      materiau_mur: state.materiau_mur ? state.materiau_mur : 0,
      adjacence_mur: state.adjacence_mur ? state.adjacence_mur : 0,
      isolation_adjacence_mur: state.isolation_adjacence_mur ? state.isolation_adjacence_mur : 0,
      orientation_mur: state.orientation_mur ? state.orientation_mur : 0,
    }

    var array = state.array_Murs
    array[state.selected_index] = new_wall
    setState((prevValues) => ({
      ...prevValues,
      array_Murs: array,
      largeur_mur: '',
      longueur_mur: '',
      etat_isolation: '',
      effet_joule: '',
      epaisseur_mur: '',
      materiau_mur: '',
      adjacence_mur: '',
      isolation_adjacence_mur: '',
      orientation_mur: '',
      selected_index: prevValues.array_Murs.length,
    }))
  }
  const onChangeWall = (event: any, k: number) => {
    const newState = state.array_Murs.map((obj, i) => {
      if (i === k) {
        return {...obj, [event.target.name]: event.target.value}
      }
      return obj
    })

    setState((prevValues) => ({
      ...prevValues,
      array_Murs: newState,
    }))
  }
  const editWall = (k: number) => {
    console.log(k)
    setState((prevValues) => ({
      ...prevValues,
      selected_index: k,
      largeur_mur: state.array_Murs[k]?.largeur_mur,
      longueur_mur: state.array_Murs[k]?.longueur_mur,
      etat_isolation: state.array_Murs[k]?.etat_isolation,
      effet_joule: state.array_Murs[k]?.effet_joule,
      epaisseur_mur: state.array_Murs[k]?.epaisseur_mur,
      materiau_mur: state.array_Murs[k]?.materiau_mur,
      adjacence_mur: state.array_Murs[k]?.adjacence_mur,
      isolation_adjacence_mur: state.array_Murs[k]?.isolation_adjacence_mur,
      orientation_mur: state.array_Murs[k]?.orientation_mur,
    }))
  }
  const deleteWall = (index: number) => {
    var array = [...state.array_Murs]
    if (index !== -1) {
      array.splice(index, 1)
      setState((prevValues) => ({
        ...prevValues,
        array_Murs: array,
      }))
    }
  }
  ///////////////////////////////////////////////////////////////////////////
  const addplafond = () => {
    console.log('addowner')
    var new_plafon: Plafond = {
      largeur_plafon: state.largeur_plafon,
      longueur_plafon: state.longueur_plafon,
      isolation_plafon: state.isolation_plafon,
      effet_joule_plafon: state.effet_joule_plafon,
      type_toiture_plafon: state.type_toiture_plafon,
      type_materiau_plafon: state.type_materiau_plafon,
      adjacence_plafon: state.adjacence_plafon,
      isolation_adjacence_plafon: state.isolation_adjacence_plafon,
      orientation_plafon: state.orientation_plafon,
    }

    var array = state.array_plafond
    array[state.selected_index_plafond] = new_plafon
    setState((prevValues) => ({
      ...prevValues,
      array_plafond: array,
      largeur_plafon: 0,
      longueur_plafon: 0,
      isolation_plafon: 0,
      effet_joule_plafon: 0,
      type_toiture_plafon: 0,
      type_materiau_plafon: 0,
      adjacence_plafon: 0,
      isolation_adjacence_plafon: 0,
      orientation_plafon: 0,
      selected_index_plafond: prevValues.array_plafond.length,
    }))
  }

  const editplafond = (k: number) => {
    console.log(k)
    setState((prevValues) => ({
      ...prevValues,
      selected_index_plafond: k,
      largeur_plafon: state.array_plafond[k]?.largeur_plafon,
      longueur_plafon: state.array_plafond[k]?.longueur_plafon,
      isolation_plafon: state.array_plafond[k]?.isolation_plafon,
      effet_joule_plafon: state.array_plafond[k]?.effet_joule_plafon,
      type_toiture_plafon: state.array_plafond[k]?.type_toiture_plafon,
      type_materiau_plafon: state.array_plafond[k]?.type_materiau_plafon,
      adjacence_plafon: state.array_plafond[k]?.adjacence_plafon,
      isolation_adjacence_plafon: state.array_plafond[k]?.isolation_adjacence_plafon,
      orientation_plafon: state.array_plafond[k]?.orientation_plafon,
    }))
  }
  const deleteplafond = (index: number) => {
    var array = [...state.array_plafond]
    if (index !== -1) {
      array.splice(index, 1)
      setState((prevValues) => ({
        ...prevValues,
        array_plafond: array,
      }))
    }
  }
  ///////////////////////////////////////////////////////////////////////////
  const addplancher = () => {
    console.log('addowner')
    var new_plafon: Plancher = {
      longueur_plancher: state.longueur_plancher,
      largeur_plancher: state.largeur_plancher,
      isolation_plancher: state.isolation_plancher,
      effet_joule_plancher: state.effet_joule_plancher,
      type_materiau_plancher: state.type_materiau_plancher,
      adjacence_plancher: state.adjacence_plancher,
      // isolation_adjacence_plancher: state.isolation_adjacence_plancher,
      // orientation_plancher: state.orientation_plancher,
      // type_toiture_plancher: state.type_toiture_plancher
    }

    var array = state.array_plancher
    array[state.selected_index_plancher] = new_plafon
    setState((prevValues) => ({
      ...prevValues,
      array_plancher: array,
      longueur_plancher: '',
      largeur_plancher: '',
      isolation_plancher: '',
      effet_joule_plancher: '',
      type_materiau_plancher: '',
      adjacence_plancher: '',
      // isolation_adjacence_plancher: "",
      // orientation_plancher: "",
      // type_toiture_plancher: "",
      selected_index_plancher: prevValues.array_plancher.length,
    }))
  }
  const editplancher = (k: number) => {
    console.log(k)
    setState((prevValues) => ({
      ...prevValues,
      selected_index_plancher: k,
      largeur_plancher: state.array_plancher[k]?.largeur_plancher,
      longueur_plancher: state.array_plancher[k]?.longueur_plancher,
      isolation_plancher: state.array_plancher[k]?.isolation_plancher,
      effet_joule_plancher: state.array_plancher[k]?.effet_joule_plancher,
      type_materiau_plancher: state.array_plancher[k]?.type_materiau_plancher,
      adjacence_plancher: state.array_plancher[k]?.adjacence_plancher,
      // isolation_adjacence_plancher: state.array_plancher[k]?.isolation_adjacence_plancher,
      // orientation_plancher: state.array_plancher[k]?.orientation_plancher,
      // type_toiture_plancher: state.array_plancher[k]?.type_toiture_plancher,
    }))
  }
  const deleteplancher = (index: number) => {
    var array = [...state.array_plancher]
    if (index !== -1) {
      array.splice(index, 1)
      setState((prevValues) => ({
        ...prevValues,
        array_plancher: array,
      }))
    }
  }
  ///////////////////////////////////////////////////////////////////////
  const editFenetre = (k: number) => {
    setState((prevValues) => ({
      ...prevValues,
      selected_index_fenetre: k,
      longueur_fenetre: state.array_fenetres[k]?.longueur_fenetre,
      largeur_fenetre: state.array_fenetres[k]?.largeur_fenetre,
      type_vitrage: state.array_fenetres[k]?.type_vitrage,
      epaisseur_lame: state.array_fenetres[k]?.epaisseur_lame,
      vitrage_vir: state.array_fenetres[k]?.vitrage_vir,
      inclinaison_vitrage: state.array_fenetres[k]?.inclinaison_vitrage,
      type_gaz_lame: state.array_fenetres[k]?.type_gaz_lame,
      type_baie: state.array_fenetres[k]?.type_baie,
      type_materiau_fenetre: state.array_fenetres[k]?.type_materiau_fenetre,
      type_fermeture: state.array_fenetres[k]?.type_fermeture,
      presence_retour_isolation_fenetre: state.array_fenetres[k]?.presence_retour_isolation_fenetre,
      type_pose_fenetre: state.array_fenetres[k]?.type_pose_fenetre,
      largeur_dormant_fenetre: state.array_fenetres[k]?.largeur_dormant_fenetre,
    }))
  }
  const deleteFenetre = (index: number) => {
    var array = [...state.array_fenetres]
    if (index !== -1) {
      array.splice(index, 1)
      setState((prevValues) => ({
        ...prevValues,
        array_fenetres: array,
      }))
    }
  }
  const addFenetre = (index: number) => {
    var new_fenetre: Fenetre = {
      largeur_fenetre: state.largeur_fenetre,
      longueur_fenetre: state.longueur_fenetre,
      type_vitrage: state.type_vitrage,
      epaisseur_lame: state.epaisseur_lame,
      vitrage_vir: state.vitrage_vir,
      inclinaison_vitrage: state.inclinaison_vitrage,
      type_gaz_lame: state.type_gaz_lame,
      type_baie: state.type_baie,
      type_materiau_fenetre: state.type_materiau_fenetre,
      type_fermeture: state.type_fermeture,
      presence_retour_isolation_fenetre: state.presence_retour_isolation_fenetre,
      type_pose_fenetre: state.type_pose_fenetre,
      largeur_dormant_fenetre: state.largeur_dormant_fenetre,
    }

    var array = state.array_fenetres
    array[state.selected_index_fenetre] = new_fenetre
    setState((prevValues) => ({
      ...prevValues,
      array_fenetres: array,
      largeur_fenetre: 0,
      longueur_fenetre: 0,
      type_vitrage: 0,
      epaisseur_lame: 0,
      vitrage_vir: 0,
      inclinaison_vitrage: 0,
      type_gaz_lame: 0,
      type_baie: 0,
      type_materiau_fenetre: 0,
      type_fermeture: 0,
      presence_retour_isolation_fenetre: 0,
      type_pose_fenetre: 0,
      largeur_dormant_fenetre: 0,
      selected_index_fenetre: prevValues.array_fenetres.length,
    }))
  }
  ///////////////////////////////////////////////////////////////////////
  const editPorte = (k: number) => {
    setState((prevValues) => ({
      ...prevValues,
      selected_index_porte: k,
      longueur_porte: state.array_portes[k]?.longueur_porte,
      largeur_porte: state.array_portes[k]?.largeur_porte,
      type_materiau_porte: state.array_portes[k]?.type_materiau_porte,
      presence_retour_isolation_porte: state.array_portes[k]?.presence_retour_isolation_porte,
      type_pose_porte: state.array_portes[k]?.type_pose_porte,
      largeur_dormant_porte: state.array_portes[k]?.largeur_dormant_porte,
    }))
  }
  const deletePorte = (index: number) => {
    var array = [...state.array_portes]
    if (index !== -1) {
      array.splice(index, 1)
      setState((prevValues) => ({
        ...prevValues,
        array_portes: array,
      }))
    }
  }
  const addPorte = (index: number) => {
    var new_porte: Porte = {
      longueur_porte: state.longueur_porte,
      largeur_porte: state.largeur_porte,
      type_materiau_porte: state.type_materiau_porte,
      presence_retour_isolation_porte: state.presence_retour_isolation_porte,
      type_pose_porte: state.type_pose_porte,
      largeur_dormant_porte: state.largeur_dormant_porte,
    }
    var array = state.array_portes
    array[state.selected_index_porte] = new_porte
    setState((prevValues) => ({
      ...prevValues,
      array_portes: array,
      largeur_porte: 0,
      longueur_porte: 0,
      type_materiau_porte: 0,
      presence_retour_isolation_porte: 0,
      type_pose_porte: 0,
      largeur_dormant_porte: 0,
      selected_index_porte: prevValues.array_portes.length,
    }))
  }

  ///////////////////////////////////////////////////////////////////////
  const editEcs = (k: number) => {
    setState((prevValues) => ({
      ...prevValues,
      selected_index_ecs: k,
      type_generateur_ecs: state.array_ecs[k]?.type_generateur_ecs,
      volume_ballon: state.array_ecs[k]?.volume_ballon,
      type_generateur_ch: state.array_ecs[k]?.type_generateur_ch,
      type_reseau_collectif: state.array_ecs[k]?.type_reseau_collectif,
      type_installation: state.array_ecs[k]?.type_installation,
      configuration_logement: state.array_ecs[k]?.configuration_logement,
    }))
  }
  const deleteEcs = (index: number) => {
    var array = [...state.array_ecs]
    if (index !== -1) {
      array.splice(index, 1)
      setState((prevValues) => ({
        ...prevValues,
        array_ecs: array,
      }))
    }
  }
  const addEcs = (index: number) => {
    var new_porte: ECS = {
      type_generateur_ecs: state.type_generateur_ecs,
      volume_ballon: state.volume_ballon,
      type_generateur_ch: state.type_generateur_ch,
      type_reseau_collectif: state.type_reseau_collectif,
      type_installation: state.type_installation,
      configuration_logement: state.configuration_logement,
    }
    var array = state.array_ecs
    array[state.selected_index_ecs] = new_porte
    setState((prevValues) => ({
      ...prevValues,
      array_ecs: array,
      type_generateur_ecs: '',
      volume_ballon: '',
      type_generateur_ch: '',
      type_reseau_collectif: '',
      type_installation: '',
      configuration_logement: '',
      selected_index_ecs: prevValues.array_ecs.length,
    }))
  }
  return (
    <div>
      <div className='card'>
        <div className='card-body'>
          <div
            ref={SimulateurStepperRef}
            className='stepper stepper-links d-flex flex-column pt-15'
            id='kt_simulateur_stepper'
          >
            <div className='stepper-nav mb-5'>
              <a
                id='myLink'
                href='#'
                onClick={() => {
                  SimulateurStepper.current?.goto(1)
                }}
              >
                <div className='stepper-item current' data-kt-stepper-element='nav'>
                  <h3 className='stepper-title'>Logement</h3>
                </div>
              </a>

              <div className='stepper-item' data-kt-stepper-element='nav'>
                <a
                  id='myLink'
                  href='#'
                  onClick={() => {
                    SimulateurStepper.current?.goto(2)
                  }}
                >
                  <h3 className='stepper-title'>Mur</h3>
                </a>
              </div>

              <div className='stepper-item' data-kt-stepper-element='nav'>
                <a
                  id='myLink'
                  href='#'
                  onClick={() => {
                    SimulateurStepper.current?.goto(3)
                  }}
                >
                  <h3 className='stepper-title'>Plafond</h3>{' '}
                </a>
              </div>

              <div className='stepper-item' data-kt-stepper-element='nav'>
                <a
                  id='myLink'
                  href='#'
                  onClick={() => {
                    SimulateurStepper.current?.goto(4)
                  }}
                >
                  <h3 className='stepper-title'>Plancher</h3>
                </a>
              </div>

              <div className='stepper-item' data-kt-stepper-element='nav'>
                <a
                  id='myLink'
                  href='#'
                  onClick={() => {
                    SimulateurStepper.current?.goto(5)
                  }}
                >
                  <h3 className='stepper-title'>Fenetre</h3>
                </a>
              </div>

              <div className='stepper-item' data-kt-stepper-element='nav'>
                <a
                  id='myLink'
                  href='#'
                  onClick={() => {
                    SimulateurStepper.current?.goto(6)
                  }}
                >
                  <h3 className='stepper-title'>Porte</h3>
                </a>
              </div>

              <div className='stepper-item' data-kt-stepper-element='nav'>
                <a
                  id='myLink'
                  href='#'
                  onClick={() => {
                    SimulateurStepper.current?.goto(7)
                  }}
                >
                  <h3 className='stepper-title'>Ventilation</h3>
                </a>
              </div>

              <div className='stepper-item' data-kt-stepper-element='nav'>
                <a
                  id='myLink'
                  href='#'
                  onClick={() => {
                    SimulateurStepper.current?.goto(8)
                  }}
                >
                  <h3 className='stepper-title'>Ecs</h3>
                </a>
              </div>

              <div className='stepper-item' data-kt-stepper-element='nav'>
                <a
                  id='myLink'
                  href='#'
                  onClick={() => {
                    SimulateurStepper.current?.goto(9)
                  }}
                >
                  <h3 className='stepper-title'>Chauffage</h3>
                </a>
              </div>

              <div className='stepper-item' data-kt-stepper-element='nav'>
                <a
                  id='myLink'
                  href='#'
                  onClick={() => {
                    SimulateurStepper.current?.goto(10)
                  }}
                >
                  <h3 className='stepper-title'>Clim.</h3>
                </a>
              </div>
              <div className='stepper-item' data-kt-stepper-element='nav'>
                <a
                  id='myLink'
                  href='#'
                  onClick={() => {
                    SimulateurStepper.current?.goto(11)
                  }}
                >
                  <h3 className='stepper-title'>Result.</h3>
                </a>
              </div>
            </div>

            <Formik
              validationSchema={currentSchema}
              initialValues={state}
              onSubmit={submitSimulateurStep}
            >
              {() => (
                <Form
                  className='mx-auto mw-600px w-100 pt-15 pb-10'
                  id='kt_simulateur_stepper_form'
                >
                  <div className='current' data-kt-stepper-element='content'>
                    <SimulateurStep1 state={state} onChange={handleChange} />
                  </div>

                  <div data-kt-stepper-element='content'>
                    <SimulateurStep2
                      editWall={editWall}
                      deleteWall={deleteWall}
                      addWall={addWall}
                      state={state}
                      onChange={handleChange}
                    />
                  </div>
                  <div data-kt-stepper-element='content'>
                    <SimulateurStep5
                      editPlafond={editplafond}
                      deletePlafond={deleteplafond}
                      addPlafond={addplafond}
                      state={state}
                      onChange={handleChange}
                    />
                  </div>

                  <div data-kt-stepper-element='content'>
                    <SimulateurStep6
                      editPlancher={editplancher}
                      deletePlancher={deleteplancher}
                      addPlancher={addplancher}
                      state={state}
                      onChange={handleChange}
                    />
                  </div>

                  <div data-kt-stepper-element='content'>
                    <SimulateurStep4
                      editFenetre={editFenetre}
                      deleteFenetre={deleteFenetre}
                      addFenetre={addFenetre}
                      state={state}
                      onChange={handleChange}
                    />
                  </div>
                  <div data-kt-stepper-element='content'>
                    <SimulateurStep3
                      addPorte={addPorte}
                      editPorte={editPorte}
                      deletePorte={deletePorte}
                      state={state}
                      onChange={handleChange}
                    />
                  </div>
                  <div data-kt-stepper-element='content'>
                    <SimulateurStep7 state={state} onChange={handleChange} />
                  </div>
                  <div data-kt-stepper-element='content'>
                    <SimulateurStep8
                      addEcs={addEcs}
                      editEcs={editEcs}
                      deleteEcs={deleteEcs}
                      state={state}
                      onChange={handleChange}
                    />
                  </div>
                  <div data-kt-stepper-element='content'>
                    <SimulateurStep9
                      addEcs={addEcs}
                      editEcs={editEcs}
                      deleteEcs={deleteEcs}
                      state={state}
                      onChange={handleChange}
                    />
                  </div>
                  <div data-kt-stepper-element='content'>
                    <SimulateurStep10
                      addEcs={addEcs}
                      editEcs={editEcs}
                      deleteEcs={deleteEcs}
                      state={state}
                      onChange={handleChange}
                    />
                  </div>
                  <div data-kt-stepper-element='content'>
                    <SimulateurStep11 />
                  </div>

                  <div className='d-flex flex-stack pt-15'>
                    <div className='mr-2'>
                      <button
                        onClick={prevSimulateurStep}
                        type='button'
                        className='btn btn-lg btn-light-primary me-3'
                        data-kt-stepper-action='previous'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr063.svg'
                          className='svg-icon-4 me-1'
                        />
                        Back
                      </button>
                    </div>

                    <div>
                      <button type='submit' className='btn btn-lg btn-primary me-3'>
                        <span className='indicator-label'>
                          {!isSubmitButton && 'Continue'}
                          {isSubmitButton && 'Submit'}
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr064.svg'
                            className='svg-icon-3 ms-2 me-0'
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SimulateurDpe
