/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
// import {KTSVG} from '../../../../../_metronic/helpers'
import { Field, ErrorMessage } from 'formik'

const Step5: React.FC<{ onClick: any, onChange: any, message: string, state:any }> = ({ onClick, onChange, message, state }) => {
    return (
        <div className='w-100' key={`step5${message}`}>
                <div className='fv-row mt-10'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-4'>
                        <span className=''>Atouts: </span>

                    </label>
                </div>
                
                <div className='row mb-10' >
                    <div className='col-xs-12 col-md-3 mb-2'>
                        <input
                            onClick={onClick}
                            onChange={()=>{}}
                            type='radio'
                            className='btn-check'
                            name='piscine'
                            // value='1'
                            id='Piscine'
                            //defaultChecked={state.piscine}
                            checked={state.piscine === false?false:true}
                        />
                           
                        <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                            htmlFor='Piscine'
                        >
                            <span className='fw-bolder fs-7'>Piscine</span>
                        </label>
                    </div>
       
                    <div className='col-xs-12 col-md-3 mb-2'>
                        <input
                           onClick={onClick}
                            onChange={()=>{}}
                            type='radio'
                            className='btn-check'
                            name='garage'
                            // value='1'
                            id='Garage'
                            //defaultChecked={state.garage}
                            checked={state.garage === false?false:true}
                        />
                        <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                            htmlFor='Garage'
                        >
                            <span className='fw-bolder fs-7'>Garage</span>
                        </label>
                    </div>
                    <div className='col-xs-12 col-md-3 mb-2'>
                        <input
                           onClick={onClick}
                            onChange={()=>{}}
                            type='radio'
                            className='btn-check'
                            name='parkingExt'
                            // value='1'
                            id='parkingExt'
                            checked={state.parkingExt === false?false:true}
                            //defaultChecked={state.parkingExt}
                        />
                        <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                            htmlFor='parkingExt'
                        >
                            <span className='fw-bolder fs-7'>Parking Extérieur</span>
                        </label>
                    </div>
                    <div className='col-xs-12 col-md-3 mb-2'>
                        <input
                           onClick={onClick}
                            onChange={()=>{}}
                            type='radio'
                            className='btn-check'
                            name='parkingInt'
                            // value='1'
                            id='parkingInt'
                            checked={state.parkingInt === false?false:true}
                            //defaultChecked={state.parkingInt}
                        />
                        <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                            htmlFor='parkingInt'
                        >
                            <span className='fw-bolder fs-7'>Parking Intérieur</span>
                        </label>
                    </div>
                    <div className='col-xs-12 col-md-3 mb-2'>
                        <input
                           onClick={onClick}
                            onChange={()=>{}}
                            type='radio'
                            className='btn-check'
                            name='ascenseur'
                            // value='1'
                            id='Ascenseur'
                            checked={state.ascenseur === false?false:true}
                            //defaultChecked={state.ascenseur}
                        />
                        <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                            htmlFor='Ascenseur'
                        >
                            <span className='fw-bolder fs-7'>Ascenseur</span>
                        </label>
                    </div>
                    <div className='col-xs-12 col-md-3 mb-2'>
                        <input
                           onClick={onClick}
                            onChange={()=>{}}
                            type='radio'
                            className='btn-check'
                            name='cheminee'
                            // value='1'
                            id='Cheminee'
                            checked={state.cheminee === false?false:true}
                            //defaultChecked={state.cheminee}
                        />
                        <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                            htmlFor='Cheminee'
                        >
                            <span className='fw-bolder fs-7'>Cheminée</span>
                        </label>
                    </div>
                    <div className='col-xs-12 col-md-3 mb-2'>
                        <input
                           onClick={onClick}
                            onChange={()=>{}}
                            type='radio'
                            className='btn-check'
                            name='jardin'
                            // value='1'
                            id='Jardin'
                            checked={state.jardin === false?false:true}
                            //defaultChecked={state.jardin}
                        />
                        <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                            htmlFor='Jardin'
                        >
                            <span className='fw-bolder fs-7'>Jardin</span>
                        </label>
                    </div>
                    <div className='col-xs-12 col-md-3 mb-2'>
                        <input
                           onClick={onClick}
                            onChange={()=>{}}
                            type='radio'
                            className='btn-check'
                            name='terrasse'
                            // value='1'
                            id='Terrasse'
                            checked={state.terrasse === false?false:true}
                            //defaultChecked={state.terrasse}
                        />
                        <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                            htmlFor='Terrasse'
                        >
                            <span className='fw-bolder fs-7'>Terrasse</span>
                        </label>
                    </div>
                    <div className='col-xs-12 col-md-3 mb-2'>
                        <input
                           onClick={onClick}
                            onChange={()=>{}}
                            type='radio'
                            className='btn-check'
                            name='balcon'
                            // value='1'
                            id='Balcon'
                            checked={state.balcon === false?false:true}
                            //defaultChecked={state.balcon}
                        />
                        <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                            htmlFor='Balcon'
                        >
                            <span className='fw-bolder fs-7'>Balcon</span>
                        </label>
                    </div>
                    <div className='col-xs-12 col-md-3 mb-2'>
                        <input
                           onClick={onClick}
                            onChange={()=>{}}
                            type='radio'
                            className='btn-check'
                            name='box'
                            // value='1'
                            id='Box'
                            checked={state.box === false?false:true}
                            //defaultChecked={state.box}
                        />
                        <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                            htmlFor='Box'
                        >
                            <span className='fw-bolder fs-7'>Box</span>
                        </label>
                    </div>
                    <div className='col-xs-12 col-md-3 mb-2'>
                        <input
                            onClick={onClick}
                            onChange={()=>{}}
                            type='radio'
                            className='btn-check'
                            name='cave'
                            // value='1'
                            id='Cave'
                            checked={state.cave === false?false:true}
                            //defaultChecked={state.cave}
                        />
                        <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                            htmlFor='Cave'
                        >
                            <span className='fw-bolder fs-7'>Cave</span>
                        </label>
                    </div>
                    <div className='col-xs-12 col-md-3 mb-2'>
                        <input
                            onClick={onClick}
                            onChange={()=>{}}
                            type='radio'
                            className='btn-check'
                            name='localPoubelle'
                            // value='1'
                            id='localPoubelle'
                            checked={state.localPoubelle === false?false:true}
                            //defaultChecked={state.localPoubelle}
                        />
                        <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                            htmlFor='localPoubelle'
                        >
                            <span className='fw-bolder fs-7'>Local  Poubelle</span>
                        </label>
                    </div>
                    <div className='col-xs-12 col-md-3 mb-2'>
                        <input
                           onClick={onClick}
                            onChange={()=>{}}
                            type='radio'
                            className='btn-check'
                            name='calme'
                            // value='1'
                            id='Calme'
                            checked={state.calme === false?false:true}
                            //defaultChecked={state.calme}
                        />
                        <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                            htmlFor='Calme'
                        >
                            <span className='fw-bolder fs-7'>Calme</span>
                        </label>
                    </div>
                    <div className='col-xs-12 col-md-3 mb-2'>
                        <input
                           onClick={onClick}
                            onChange={()=>{}}
                            type='radio'
                            className='btn-check'
                            name='chauffage'
                            // value='1'
                            id='Chauffage'
                            checked={state.chauffage === false?false:true}
                            //defaultChecked={state.chauffage}
                        />
                        <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                            htmlFor='Chauffage'
                        >
                            <span className='fw-bolder fs-7'>Chauffage</span>
                        </label>
                    </div>
                    <div className='col-xs-12 col-md-3 mb-2'>
                        <input
                           onClick={onClick}
                            onChange={()=>{}}
                            type='radio'
                            className='btn-check'
                            name='climatisation'
                            // value='pensions'
                            id='Climatisation'
                            checked={state.climatisation === false?false:true}
                            //defaultChecked={state.climatisation}
                        />
                        <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                            htmlFor='Climatisation'
                        >
                            <span className='fw-bolder fs-7'>Climatisation</span>
                        </label>
                    </div>
                    
                </div>

                <div className='fv-row mt-10'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-4'>
                        <span className=''>Points négatifs: </span>

                    </label>
                </div>

                <div className='row mb-10' >
                    <div className='col-xs-12 col-md-3 mb-2'>
                        <input
                           onClick={onClick}
                            onChange={()=>{}}
                            type='radio'
                            className='btn-check'
                            name='bruyant'
                            // value='pensions'
                            id='bruyant'
                            checked={state.bruyant === false?false:true}
                            //defaultChecked={state.climatisation}
                        />
                        <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                            htmlFor='bruyant'
                        >
                            <span className='fw-bolder fs-7'>Bruyant</span>
                        </label>
                    </div>
                    
                </div>
               {/* <div className='text-danger mt-2 '>
                <ErrorMessage name='Calme' />
               </div> */}

           

        </div>
    )
}

export { Step5 }
