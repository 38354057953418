/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Formik, Form, FormikValues, Field, ErrorMessage} from 'formik'
import {
  StatisticsWidget3,
} from '../../../../_metronic/partials/widgets'
import { MapContainer, Marker, Popup, TileLayer, Pane } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

import iconAvailableHome from '../utils/home-available.png';
import iconAvailableHomeShadow from '../utils/home-available.png';
import iconSelledHome from '../utils/home-selled.png';
import iconSelledHomeShadow from '../utils/home-selled.png';
import iconCurrentHome from '../utils/home.png';
import iconCurrentHomeShadow from '../utils/home-available.png';
import { Tendance } from './Tendance'

let DefaultIcon = L.icon({
    iconUrl: iconAvailableHome,
    shadowUrl: iconAvailableHomeShadow
});
let MarketIcon = L.icon({
    iconUrl: iconSelledHome,
    shadowUrl: iconSelledHomeShadow
});
let iconHome = L.icon({
    iconUrl: iconCurrentHome,
    shadowUrl: iconCurrentHomeShadow
});

L.Marker.prototype.options.icon = DefaultIcon;


// problème minimum, moyen et maximum du prix du marché
const Step7: FC<{onChange: any, state:any, result:any, showResult:boolean}> = ({onChange, result, state, showResult}) => {

  const moyMarket = () => {
    var tmpReturn :any = []
    var average = 0
    if(result && result.marche){
      tmpReturn = result.marche.map((value:any, index:number) => {
        return (value.prixm2)
      })
      const sum = tmpReturn.reduce((a:number, b:number) => a + b, 0)
      average = Math.round((sum / tmpReturn.length)- ((sum / tmpReturn.length)*0.09))
    }
    
    return average
  }
  const minMarket = () => {
    var tmpReturn :any = []
    if(result && result.marche){
      tmpReturn = result.marche.map((value:any, index:number) => {
        return (value.prixm2)
      })
    }
    return Math.round(Math.min(...tmpReturn)- (Math.min(...tmpReturn)*0.09))
  }
  const maxMarket = () => {
    var tmpReturn :any = []
    if(result && result.marche){
      tmpReturn = result.marche.map((value:any, index:number) => {
        return (value.prixm2)
      })
    }
    return Math.round(Math.max(...tmpReturn)- (Math.max(...tmpReturn)*0.09))
  }

  const moyDataGouv = () => {
    var tmpReturn :any = []
    var average = 0
    if(result && result.datagouv){
      tmpReturn = result.datagouv.map((value:any, index:number) => {
        return (value.prixm2)
      })
      const sum = tmpReturn.reduce((a:number, b:number) => a + b, 0)
      average = Math.round((sum / tmpReturn.length)- ((sum / tmpReturn.length)*0.05))
    }
    
    return average
  }
  const minDataGouv = () => {
    var tmpReturn :any = []
    if(result && result.datagouv){
      tmpReturn = result.datagouv.map((value:any, index:number) => {
        return (value.prixm2)
      })
    }
    return Math.round(Math.min(...tmpReturn)- (Math.min(...tmpReturn)*0.05))
  }
  const maxDataGouv = () => {
    var tmpReturn :any = []
    if(result && result.datagouv){
      tmpReturn = result.datagouv.map((value:any, index:number) => {
        return (value.prixm2)
      })
    }
    return Math.round(Math.max(...tmpReturn)- (Math.max(...tmpReturn)*0.05))
  }

  return (
    <div className='w-100'>
      {showResult? 
      <>
      <div className='d-flex flex-row-reverse align-items-center py-4'>
        <a onClick={() => {window.location.href="/services/estimate"}} 
          className="btn btn-icon btn-primary mx-2"
        >
          <span className="svg-icon svg-icon-1">
            <i className="bi bi-arrow-repeat fs-2x"></i>
          </span>
        </a>
        <span>
          Lancer une nouvelle estimation
        </span>
      </div>
      <div>
        <h1 className='py-4'>Résultat de l'estimation</h1>
        <div className='fv-row'>
          <div className='row g-5 g-xl-8'>
            <div className='col-xl-3'>
              <a href='#' className='card border border-2 border-primary hoverable card-xl-stretch mb-xl-8'> 
                <div className='card-body'>
                  <span className='svg-icon svg-icon-primary svg-icon-3x ms-n1'>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      className='mh-50px'
                    >
                      <path
                        opacity='0.3'
                        d='M18 21.6C16.3 21.6 15 20.3 15 18.6V2.50001C15 2.20001 14.6 1.99996 14.3 2.19996L13 3.59999L11.7 2.3C11.3 1.9 10.7 1.9 10.3 2.3L9 3.59999L7.70001 2.3C7.30001 1.9 6.69999 1.9 6.29999 2.3L5 3.59999L3.70001 2.3C3.50001 2.1 3 2.20001 3 3.50001V18.6C3 20.3 4.3 21.6 6 21.6H18Z'
                        fill='black'
                      ></path>
                      <path
                        d='M12 12.6H11C10.4 12.6 10 12.2 10 11.6C10 11 10.4 10.6 11 10.6H12C12.6 10.6 13 11 13 11.6C13 12.2 12.6 12.6 12 12.6ZM9 11.6C9 11 8.6 10.6 8 10.6H6C5.4 10.6 5 11 5 11.6C5 12.2 5.4 12.6 6 12.6H8C8.6 12.6 9 12.2 9 11.6ZM9 7.59998C9 6.99998 8.6 6.59998 8 6.59998H6C5.4 6.59998 5 6.99998 5 7.59998C5 8.19998 5.4 8.59998 6 8.59998H8C8.6 8.59998 9 8.19998 9 7.59998ZM13 7.59998C13 6.99998 12.6 6.59998 12 6.59998H11C10.4 6.59998 10 6.99998 10 7.59998C10 8.19998 10.4 8.59998 11 8.59998H12C12.6 8.59998 13 8.19998 13 7.59998ZM13 15.6C13 15 12.6 14.6 12 14.6H10C9.4 14.6 9 15 9 15.6C9 16.2 9.4 16.6 10 16.6H12C12.6 16.6 13 16.2 13 15.6Z'
                        fill='black'
                      ></path>
                      <path
                        d='M15 18.6C15 20.3 16.3 21.6 18 21.6C19.7 21.6 21 20.3 21 18.6V12.5C21 12.2 20.6 12 20.3 12.2L19 13.6L17.7 12.3C17.3 11.9 16.7 11.9 16.3 12.3L15 13.6V18.6Z'
                        fill='black'
                      ></path>
                    </svg>
                  </span>
                  <div className='fw-bold text-primary fs-7'>Estimation du bien</div>
                  <div className='text-primary fw-bolder fs-2 mb-2 mt-5'>{result && Math.round(result.result)+" €"}</div>
                  <div className='fw-bold text-primary fs-7'>{state && result && Math.round((result.result)/(state.surface))} €/m²</div>
                </div>
              </a>
            </div>
            <div className='col-xl-3'>
              <a href='#' className='card bg-dark hoverable card-xl-stretch mb-xl-8'>
                <div className='card-body'>
                  <span className='svg-icon svg-icon-white svg-icon-3x ms-n1'>
                    <KTSVG path="/media/icons/duotune/arrows/arr004.svg" className="svg-icon-muted svg-icon-2hx" />
                  </span>
                  <div className='fw-bold text-inverse-dark fs-7'>Estimation minimale du marché</div>
                  <div className='text-inverse-dark fw-bolder fs-6 mb-2 mt-5'> {minDataGouv()?minDataGouv().toString()+' €/m²':''}</div>
                  {/* <div className='fw-bold text-inverse-dark fs-7'>12000 €/m² </div> */}
                </div>
              </a>
            </div>
            <div className='col-xl-3'>
              <a href='#' className='card bg-warning hoverable card-xl-stretch mb-xl-8'>
                <div className='card-body'>
                  <span className='svg-icon svg-icon-white svg-icon-3x ms-n1'>
                    <KTSVG path="/media/icons/duotune/arrows/arr001.svg" className="svg-icon-muted svg-icon-2hx" />
                  </span>
                  <div className='fw-bold text-dark fs-7'>Estimation moyenne du marché</div>
                  <div className='text-dark fw-bolder fs-6 mb-2 mt-5'> {moyDataGouv()?moyDataGouv().toString()+' €/m²':''}</div>
                  
                </div>
              </a>
            </div>
            <div className='col-xl-3'>
              <a href='#' className='card bg-info hoverable card-xl-stretch mb-5 mb-xl-8'>
                <div className='card-body'>
                  <span className='svg-icon svg-icon-white svg-icon-3x ms-n1'>
                    <KTSVG path="/media/icons/duotune/arrows/arr003.svg" className="svg-icon-muted svg-icon-2hx" />
                  </span>
                  <div className='fw-bold text-inverse-info fs-7'>Estimation maximale du marché</div>
                  <div className='text-inverse-info fw-bolder fs-6 mb-2 mt-5'> {maxDataGouv()?maxDataGouv().toString()+' €/m²':''}</div>
                  {/* <div className='fw-bold text-inverse-info fs-7'>Milestone Reached</div> */}
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-xl-4'>
          <StatisticsWidget3
            className='card-xl-stretch mb-xl-8'
            color='danger'
            title='Prix minimal'
            description="Prix minimal d'un logement similaire"
            change={minMarket()?minMarket().toString()+' €/m²':''}            
          />
        </div>
        <div className='col-xl-4'>
          <StatisticsWidget3
            className='card-xl-stretch mb-xl-8'
            color='primary'
            title='Prix moyen'
            description="Prix moyen d'un logement similaire"
            change={moyMarket()?moyMarket().toString()+' €/m²':''}
          />
        </div>

        <div className='col-xl-4'>
          <StatisticsWidget3
            className='card-xl-stretch mb-5 mb-xl-8'
            color='success'
            title='Prix maximal'
            description="Prix maximal d'un logement similaire"
            change={maxMarket()?maxMarket().toString()+' €/m²':''}
          />
        </div>
      </div>
      <div className="row">
        <div className='col-xl-6'>
          {result && result.tendance && result.tendance[0] && result.tendance[0].prixm2 &&
          <Tendance className='card-xxl-stretch mb-5 mb-xl-10' tendance={result.tendance}/>
          }
        </div>
        <div className='col-xl-6'>
          <div className='card'>
            <div className='card-header border-0 py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Localisation du bien</span>

                <span className='text-muted fw-bold fs-7'>{state.address}</span>
              </h3>
              {result && result.datagouv && result.marche &&
              <MapContainer 
                center={result.coordinates} 
                zoom={17} className='card-xxl-stretch p-5' 
                style={{zIndex:98, height: '360px', width: '100%' }}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="http://moncarnetlogement.fr">Mon Carnet Logement</a> '
                  url="https://api.mapbox.com/styles/v1/azzamlj/ckzqt250l002w14qbhdmkf9yc/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYXp6YW1saiIsImEiOiJja3QycmJ3eDAwbjc0Mnd1bGRjdGt3N28wIn0.Bd34sm6a_N47Yc8LN1Ingg"
                />
                  
                {result && result.datagouv && result.datagouv.map((value:any, index:number) => {
                    return (<Marker key={index} position={[value.latitude, value.longitude]}>
                        <Popup>
                        <h6>Bien déjà vendu</h6>
                        Logement: {value.nombre_pieces_principales>1 ? value.nombre_pieces_principales+' pièces':value.nombre_pieces_principales+' pièce'} <br />
                        Surface : {value.surface_reelle_bati} m²<br />
                        Date de vente : {value.anne} <br />
                        Prix : {Math.round(value.prixm2)} €/m²<br />
                      </Popup>
                    </Marker>)
                })}

                {result && result.marche && result.marche.map((value:any, index:number) => {
                    return (<Marker icon={MarketIcon} position={[value.latitude, value.longitude]} key={index}>
                      <Popup>
                        <h6>Bien à la vente</h6>
                        Logement: {value.nb_pieces>1 ? value.nb_pieces+' pièces':value.nb_pieces+' pièce'} <br />
                        Nbre de chambres : {value.nb_chambre} <br />
                        Type : {value.type_bien===2?'Appartement':value.type_bien===1?'Maison':'Autre'} <br />
                        Surface : {value.surface} m² <br />
                        Année de construction : {value.date_built} <br />
                        Prix : {Math.round(value.prixm2)} €/m²<br />
                        N°étage : {value.Num_étage===0?'RDC':value.Num_étage} <br />
                        {value.classification_dpe !=='0.0'? "DPE : "+value.classification_dpe+' | ':''} 
                        {value.classification_gaz !=='0.0'? "GES : "+value.classification_gaz:''} <br />
                      </Popup>
                    </Marker>)
                })}
                  <Marker icon={iconHome} position={result.coordinates}>
                    <Popup>
                      <h6>Votre Logement</h6>
                    </Popup>
                  </Marker>
              </MapContainer>
              }
            </div>
          </div>
        </div>
      </div>
    </>
    :
    <>
    Calcul de votre estimation de votre logement en cours
      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
    </>}
    </div>
  )
}

export {Step7}
