/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../_metronic/layout/core'


const TenantFilesPage: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>Gestions des locataires</PageTitle>
      {/* <TenantFilesPage /> */}
    </>
  )
}

export {TenantFilesPage}
