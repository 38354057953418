/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { ErrorMessage } from 'formik'
import { KTSVG } from '../../../../../_metronic/helpers'

const Etape5: React.FC<{ onChange: any, state: any }> = ({ onChange, state }) => {
  return (
    <div className='w-100' key={`etape5`}>


      <div className='mb-10 fv-row'>
        <label className='d-flex align-items-center form-label mb-5'>
          Le chauffage est-il individuel ou collectif ?
        </label>

        <div className='mb-10'>

          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>

              <span className='form-check form-check-custom form-check-solid me-2'>
                <input className='form-check-input' onChange={onChange}
                  type='radio' name='heater_type' value='Individuel' />
              </span>
              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  Individuel                </span>
                {/* <span className='fs-6 fw-bold text-gray-400'>Use images to your post time</span> */}
              </span>
            </span>


          </label>

          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='form-check form-check-custom form-check-solid me-2'>
                <input className='form-check-input' type='radio' onChange={onChange}
                  name='heater_type' value='Collectif' />
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  Collectif
                </span>
              </span>
            </span>

          </label>

        </div>
        {state.heater_type === 'Collectif' && (<>


          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Modalité de répartition</span>
            </label>
            {/* <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`heater_production`}
              placeholder=''
              onChange={onChange}
              value={state.heater_production}
            /> */}

            <select
              name='heater_production'
              className='form-select form-select-lg form-select-solid'
              onChange={onChange}
              value={state.heater_production}
            >
              <option value="" disabled>choisir la modalité de répartition</option>
              <option value='En fonction des tantièmes'>En fonction des tantièmes</option>
              <option value='Selon un compteur individuel'>Selon un compteur individuel</option>
              <option value='Autre'>Autre</option>
            </select>

            <div className='text-danger mt-2'>
              <ErrorMessage name={`firstname`} />
            </div>
          </div>


          {state.heater_production === 'Autre' && (<div className='fv-row mb-10'>

            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`heater_production_model`}
              placeholder='saisir la modalité de répartition de chauffage'
              onChange={onChange}
              value={state.heater_production_model}
            />

            <div className='text-danger mt-2'>
              <ErrorMessage name={`heater_production_model`} />
            </div>
          </div>)}
        </>)}
      </div>


      <div className='mb-10 fv-row'>
        <label className='d-flex align-items-center form-label mb-5'>
          L'eau sanitaire est elle individuelle ou collective ?
        </label>

        <div className='mb-10'>

          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>

              <span className='form-check form-check-custom form-check-solid me-2'>
                <input className='form-check-input' onChange={onChange}
                  type='radio' name='water_type' value='Individuelle' />
              </span>
              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  Individuelle                </span>
                {/* <span className='fs-6 fw-bold text-gray-400'>Use images to your post time</span> */}
              </span>
            </span>


          </label>

          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='form-check form-check-custom form-check-solid me-2'>
                <input className='form-check-input' type='radio' onChange={onChange}
                  name='water_type' value='Collective' />
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  Collective
                </span>
              </span>
            </span>

          </label>

        </div>
        {state.water_type === 'Collective' && (<>

          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Modalité de répartition</span>
            </label>
            {/* <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`water_production`}
              placeholder=''
              onChange={onChange}
              value={state.water_production}
            />  */}
            
            <select
              name='water_production'
              className='form-select form-select-lg form-select-solid'
              onChange={onChange}
              value={state.water_production}
            >
              <option value="" disabled>choisir la modalité de répartition</option>
              <option value='En fonction des tantièmes'>En fonction des tantièmes</option>
              <option value='Selon un compteur individuel'>Selon un compteur individuel</option>
              <option value='Autre'>Autre</option>
            </select>
     
          </div>

          {state.water_production === 'Autre' && (<div className='fv-row mb-10'>

            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`water_production_model`}
              placeholder="saisir la modalité de répartition de l'eau"
              onChange={onChange}
              value={state.water_production_model}
            />

            <div className='text-danger mt-2'>
              <ErrorMessage name={`water_production_model`} />
            </div>
          </div>)} 
        </>
        )}



      </div>


    </div>
  )
}

export { Etape5 }
