/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../_metronic/layout/core'
import WizardEstimation from '../modules/estimator/WizardEstimation'


const EstimatePage: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>Estimations</PageTitle>
      <WizardEstimation />
    </>
  )
}

export {EstimatePage}
