/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { ErrorMessage } from 'formik'
import { KTSVG } from '../../../../../_metronic/helpers'

const Etape4: React.FC<{ handleChangeCheckbox: any, onChange: any, state: any }> = ({ handleChangeCheckbox, onChange, state }) => {
  return (
    <div className='w-100' key={`etape4`}>
      <p className='fw-bold' >Désignation des locaux et équipements accessoires de l'immeuble à usage privatif du locataire  </p>
      <div className=' mb-10 form-check form-switch form-switch-sm form-check-custom form-check-solid'>
        <input
          className='form-check-input'
          type='checkbox'
          onChange={handleChangeCheckbox}

          value=''
          name='cave'
        // defaultChecked={true}
        />
        <label className='form-label fw-bold mx-3'>Il existe une cave</label>
      </div>
      {state.cave  && (
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Numéro</span>
          </label>
          <input
            type='number'
            className='form-control form-control-lg form-control-solid'
            name={`cave_details`}
            placeholder=''
            onChange={onChange}
            value={state.cave_details}
          />
          {/* <div className='text-danger mt-2'>
            <ErrorMessage name={`firstname`} />
          </div> */}
        </div>)}

      <div className=' mb-10 form-check form-switch form-switch-sm form-check-custom form-check-solid'>
        <input
          className='form-check-input'
          type='checkbox'
          onChange={handleChangeCheckbox}

          value=''
          name='garage'
        // defaultChecked={true}
        />
        <label className='form-label fw-bold mx-3'>Il existe un garage (box fermé)</label>
      </div>
      {state.garage  && (
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Numéro et adresse</span>
          </label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={`garage_details`}
            placeholder=''
            onChange={onChange}
            value={state.garage_details}
          />
          {/* <div className='text-danger mt-2'>
            <ErrorMessage name={`firstname`} />
          </div> */}
        </div>)}

      <div className=' mb-10 form-check form-switch form-switch-sm form-check-custom form-check-solid'>
        <input
          className='form-check-input'
          type='checkbox'
          onChange={handleChangeCheckbox}

          value=''
          name='parking'
        // defaultChecked={true}
        />
        <label className='form-label fw-bold mx-3'>Il existe un parking</label>
      </div>
      {state.parking  && (
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Numéro et adresse</span>
          </label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={`parking_details`}
            placeholder=''
            onChange={onChange}
            value={state.parking_details}
          />
          {/* <div className='text-danger mt-2'>
            <ErrorMessage name={`firstname`} />
          </div> */}
        </div>)}


      <div className=' mb-10 form-check form-switch form-switch-sm form-check-custom form-check-solid'>
        <input
          className='form-check-input'
          type='checkbox'
          onChange={handleChangeCheckbox}

          value=''
          name='jardin'
        // defaultChecked={true}
        />
        <label className='form-label fw-bold mx-3'>Il existe un jardin</label>
      </div>

      {state.jardin && (
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Surface jardin</span>
          </label>
          <input
            type='number'
            className='form-control form-control-lg form-control-solid'
            name={`jardin_details`}
            placeholder=''
            onChange={onChange}
            value={state.jardin_details}
          />
          {/* <div className='text-danger mt-2'>
            <ErrorMessage name={`firstname`} />
          </div> */}
        </div>)}

      <div className=' mb-10 form-check form-switch form-switch-sm form-check-custom form-check-solid'>
        <input
          className='form-check-input'
          type='checkbox'
          onChange={handleChangeCheckbox}

          value=''
          name='terasse'
        // defaultChecked={true}
        />
        <label className='form-label fw-bold mx-3'>Il existe une terrasse</label>
      </div>
      {state.terasse && (
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Surface terasse</span>
          </label>
          <input
            type='number'
            className='form-control form-control-lg form-control-solid'
            name={`terasse_details`}
            placeholder=''
            onChange={onChange}
            value={state.terasse_details}
          />
          {/* <div className='text-danger mt-2'>
            <ErrorMessage name={`firstname`} />
          </div> */}
        </div>)}
<p className='fw-bold'>Enumération des locaux, parties, équipements et accessoires de l'immeuble à usage commun</p>
      
      {/* <div className=' mb-10 form-check form-switch form-switch-sm form-check-custom form-check-solid'>
        <input
          className='form-check-input'
          type='checkbox'
          onChange={handleChangeCheckbox}

          value=''
          name='usage_commun'
        />
        <label className='form-label fw-bold mx-3'>Locaux </label>
      </div>

      {state.usage_commun  && (
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Précisez</span>
          </label>
          <input
            type='textarea'
            className='form-control form-control-lg form-control-solid'
            name={`usage_commun_details`}
            placeholder=''
            onChange={onChange}
            value={state.usage_commun_details}
          />
     
        </div>)} */}


      <div className=' mb-10 form-check form-switch form-switch-sm form-check-custom form-check-solid'>
        <input
          className='form-check-input'
          type='checkbox'
          onChange={handleChangeCheckbox}

          value=''
          name='equipements'
        // defaultChecked={true}
        />
        <label className='form-label fw-bold mx-3'>Il existe des éléments  à usage commun</label>
      </div>
      {state.equipements && (
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Précisez</span>
          </label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={`equipements_details`}
            placeholder=''
            onChange={onChange}
            value={state.equipements_details}
          />
          {/* <div className='text-danger mt-2'>
            <ErrorMessage name={`firstname`} />
          </div> */}
        </div>)}
      <div className=' mb-10 form-check form-switch form-switch-sm form-check-custom form-check-solid'>
        <input
          className='form-check-input'
          type='checkbox'
          onChange={handleChangeCheckbox}

          value=''
          name='autre'
        // defaultChecked={true}
        />
        <label className='form-label fw-bold mx-3'>Autres parties et équipements du logement  </label>
      </div>
      {state.autre && (
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Précisez </span>
          </label>
          <input
            type='textarea'
            className='form-control form-control-lg form-control-solid'
            name={`autre_details`}
            placeholder=''
            onChange={onChange}
            value={state.autre_details}
          />
          {/* <div className='text-danger mt-2'>
            <ErrorMessage name={`firstname`} />
          </div> */}
        </div>)}







    </div>
  )
}

export { Etape4 }
