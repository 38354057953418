import axios from 'axios'
import {
    PROPERTIES,
    LIST_CANDIDATES,
    ASSIGN_TENANT,
    DELETE_ASSIGNEMENT,
    GET_TENANT_FILE,
    LIST_PROPERTIES
} from '../../../app/routes'
 export async function deleteAssignement(obj:any) {
    return await axios.delete(DELETE_ASSIGNEMENT,{data:obj})
 }
 export async function deleteProperty(params:any) {
    return await axios.delete(PROPERTIES+params+'/')
 }
export async function addProperty(obj: any) {
      return await axios.post(PROPERTIES, obj)
}
export async function getPropertiesList() {
    // console.log('GET_PROFILE_URL :::',GET_PROFILE_URL)
    return await axios.get(LIST_PROPERTIES)
}
export async function getPropertiesCandidatesList() {
    // console.log('GET_PROFILE_URL :::',GET_PROFILE_URL)
    return await axios.get(LIST_CANDIDATES)
}
export async function assignTenant(obj: any) {
    return await axios.post(ASSIGN_TENANT, obj)
}

export async function getCandidateDfFile(obj: any) {
    return await axios.post(GET_TENANT_FILE, obj)
}