import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../redux/AuthCRUD'
import toast from 'react-hot-toast'
import {forgetPassword} from '../../tenant-file/userController'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Mauvais format d\'e-mail')
    .min(3, 'Au moins 3 symboles')
    .max(50, '50 symboles maximum')
    .required('L\'e-mail est obligatoire'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        const formDataRegisterAccount = new FormData()
        formDataRegisterAccount.append('email', values.email)
        forgetPassword(formDataRegisterAccount)
          .then((response: any) => {
            if (response.status === 200) {
              toast.success('L\'email de réinitialisation du mot de passe a été envoyé')
              setLoading(false)
              setHasErrors(false)
              setLoading(false)
              resetForm()
            } else {
              toast.error("Une erreur s'est produit, veuillez réessayer")
              setStatus("Une erreur s'est produit, veuillez réessayer")
              setLoading(false)
            }
            
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('L\'email de réinitialisation du mot de passe a été envoyé')
          })
      }, 1000)
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Mot de passe oublié ?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>Entrez votre e-mail pour réinitialiser votre mot de passe.</div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Désolé, il semble qu'il y ait des erreurs détectées, veuillez réessayer.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>Réinitialisation du mot de passe envoyé. Merci de consulter vos emails</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>E-mail</label>
          <input
            type='email'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container text-danger'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className=''>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder w-100 my-2'
          >
            <span className='indicator-label'>Valider</span>
            {loading && (
              <span className='indicator-progress'>
                Chargement...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder w-100 my-2'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Annuler
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
