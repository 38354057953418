import React, { FC } from 'react'
import { ErrorMessage } from 'formik'
import { KTSVG } from '../../../../../_metronic/helpers'

const Etape12: React.FC<{ onChange: any, state: any }> = ({  onChange, state }) => {
  return (
    <div className='w-100' key={`etape10
    `}>


      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>	Autres Conditions ajoutés par les parties  </span>
        </label>
        <input
          type='textarea'
          className='form-control form-control-lg form-control-solid'
          name={`autre_condition`}
          placeholder=''
          onChange={onChange}
          value={state.autre_condition}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={`autre_condition`} />
        </div>
      </div>
    </div>
  )
}

export { Etape12 }
