import React, {FC, useState} from 'react'

import {Field, ErrorMessage} from 'formik'

export const VolRoulantsOnClick = () => {
  return (
    <div className='mb-10 fv-row'>
      <label className='form-check form-switch form-check-custom form-check-solid'>
        <label className='form-label required'>Présence de volets roulants : </label>
        <Field className='form-check-input ms-10' type='checkbox' value='1' checked={true} />
      </label>
    </div>
  )
}

const Step2: FC = () => {
  var [isToggledVR, setIsToggledVR] = useState(true)
  var [isToggledRenov, setIsToggledRenov] = useState(true)

  return (
    <div className='w-100'>
      <div className='mb-10 fv-row'>
        <label className='form-label required mb-3'>Nombre de fenêtres :</label>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid border-dark '
          name='Nbfenetres'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='Nbfenetres' />
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label required mb-3'>Nombre de portes-fenêtres :</label>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid border-dark '
          name='Nbpfenetres'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='Nbpfenetres' />
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label required mb-3'>Nombre de portes :</label>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid border-dark '
          name='Nbportes'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='Nbportes' />
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required '>Type de Vitrage des fenêtres : </label>

        <Field as='select' name='TVitrage' className='form-select border-dark '>
          <option value='Simple Vitrage'>Simple Vitrage</option>
          <option value='Double Vitrage'>Double Vitrage</option>
          <option value='Triple Vitrage'>Triple Vitrage</option>
          <option value='Triple Vitrage'>Survitrage</option>
        </Field>

        <div className='text-danger mt-2'>
          <ErrorMessage name='TVitrage' />
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-check form-switch form-check-custom form-check-solid'>
          <label className='form-label required'>Présence de volets roulants : </label>
          <Field
            className='form-check-input ms-10'
            type='checkbox'
            value='1'
            checked={isToggledVR}
            onClick={() => {
              setIsToggledVR(isToggledVR != isToggledVR)
            }}
          />
        </label>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Type d'ouvrant du logement : </label>

        <Field as='select' name='TOuvrant' className='form-select  border-dark'>
          <option value='Simple Vitrage'>Bois</option>
          <option value='Double Vitrage'>Aluminium</option>
          <option value='Triple Vitrage'>PVC</option>
        </Field>

        <div className='text-danger mt-2'>
          <ErrorMessage name='TOuvrant' />
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-check form-switch form-check-custom form-check-solid'>
          <label className='form-label required'>
            Les murs ont-ils déjà été rénovés et isolés?{' '}
          </label>
          <Field
            className='form-check-input ms-10'
            type='checkbox'
            value='1'
            checked={isToggledRenov}
            onClick={() => {
              setIsToggledRenov(isToggledRenov != isToggledRenov)
            }}
          />
        </label>
      </div>
    </div>
  )
}

export {Step2}
