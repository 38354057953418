import {FC, useRef, useEffect, useState} from 'react'
import {shallowEqual, useSelector, connect, useDispatch, ConnectedProps} from 'react-redux'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import * as auth from './AuthRedux'
import {JwtDecoded, refresh, getUserByToken} from './AuthCRUD'
import {RootState} from '../../../../setup'
import jwt_decode from 'jwt-decode'
import dayjs from 'dayjs'
const mapState = (state: RootState) => ({auth: state.auth})
const connector = connect(mapState, auth.actions)
type PropsFromRedux = ConnectedProps<typeof connector>
const AuthInit: FC<PropsFromRedux> = (props) => {
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual)
  const refreshToken = useSelector<RootState>(({auth}) => auth.refreshToken, shallowEqual)
  //
  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const currentAccessToken: string | undefined= props.auth.accessToken
          if(currentAccessToken){
            // console.log('ici oncheck si le token est bon')
            const userinfo: JwtDecoded= jwt_decode(currentAccessToken)
            const isExpired = dayjs.unix(userinfo.exp).diff(dayjs()) < 1
            if(isExpired){
              // console.log('token a expiré')
              const {data: auth} = await refresh(props.auth.accessToken,props.auth.refreshToken)
              dispatch(props.refresh(auth.accessToken,auth.refreshToken))
            } else {
              // console.log('token est valide')
              const {data: user} = await getUserByToken()
              const useer :any = user
              dispatch(props.fulfillUser(useer[0]))
            }
          }
        }
      } catch (error) {
        // console.error(error)
        if (!didRequest.current) {
            dispatch(props.logout())
        }
      } finally {
        setShowSplashScreen(false)
      }
      return () => (didRequest.current = true)
    }
    if (accessToken && refreshToken) {
      requestUser()
    } else {
      dispatch(props.logout())
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])
  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}
export default connector(AuthInit)