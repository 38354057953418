import axios from 'axios'
import {ACTIVATION_URL, REGISTER_URL, REQUEST_PASSWORD_URL, RESET_PASSWORD_URL, DELETE_ACCOUNT_URL, EDIT_ACCOUNT_URL} from '../../../app/routes'

export async function registerAccount(obj:any) {
    // console.log('STEP_FIVE_URL :::',STEP_FIVE_URL)
  return await axios.post(REGISTER_URL,obj)
}
export async function activateAccount(obj:any) {
    // console.log('STEP_FIVE_URL :::',STEP_FIVE_URL)
  return await axios.post(ACTIVATION_URL,obj)
}
export async function forgetPassword(obj:any) {
    // console.log('STEP_FIVE_URL :::',STEP_FIVE_URL)
  return await axios.post(REQUEST_PASSWORD_URL,obj)
}
export async function resetPassword(obj:any,id:string,tok:string) {
    // console.log('STEP_FIVE_URL :::',STEP_FIVE_URL)
  return await axios.post(RESET_PASSWORD_URL+'/'+id+'/'+'tok',obj)
}
export async function editAccount(obj:any) {
  return await axios.patch(EDIT_ACCOUNT_URL,obj)
}
export async function deleteAccount() {
  return await axios.delete(DELETE_ACCOUNT_URL)
}