/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'

import homeB from '../../../../imgs/homeB.png'
import RDC from '../../../../imgs/RDC.png'
import EDE from '../../../../imgs/EDE.png'
import DE from '../../../../imgs/DE.png'
import buildingB from '../../../../imgs/buildingB.png'
import {Field, ErrorMessage} from 'formik'

export const MaisonEtage = () => {
  return (
    <div className='mb-10 fv-row'>
      <label className='form-label mb-3'>Nombre d'étage(s)</label>

      <Field
        type='text'
        className='form-control form-control-lg form-control-solid border-1 border-dark'
        name='NbEtages'
      />
      <div className='text-danger mt-2'>
        <ErrorMessage name='NbEtages' />
      </div>
    </div>
  )
}
export const AppartEtage = () => {
  return (
    <div className='fv-row mb-10'>
      <label className='form-label required'>L'emplacement du logement</label>
      <div className='col text-center'>
        <Field
          type='radio'
          className='btn-check'
          name='NivApp'
          value='NivAppRDC'
          id='emplacement_appart_rdc'
        />
        <label
          className='btn btn-outline btn-outline-dashed btn-outline-default p-3 mx-3 align-items-center'
          htmlFor='emplacement_appart_rdc'
        >
          <img src={RDC} alt='Rez-de-chaussée' width='70' height='105' />
          <span className='d-block fw-bold text-start'>
            <span className='text-dark fw-bolder d-block fs-6 mb-0 ms-0 align-items-center'>
              Rez-de-chaussée
            </span>
          </span>
        </label>
        <Field
          type='radio'
          className='btn-check'
          name='NivApp'
          value='NivAppEDE'
          id='emplacement_appart_ede'
        />
        <label
          className='btn btn-outline btn-outline-dashed btn-outline-default p-3 mx-3 align-items-center'
          htmlFor='emplacement_appart_ede'
        >
          <img src={EDE} alt='Entre deux étages' width='70' height='105' />
          <span className='d-block fw-bold text-start'>
            <span className='text-dark fw-bolder d-block fs-6 mb-0 ms-0 align-items-center'>
              Entre deux étages
            </span>
          </span>
        </label>
        <Field
          type='radio'
          className='btn-check'
          name='NivApp'
          value='NivAppDE'
          id='emplacement_appart_de'
        />
        <label
          className='btn btn-outline btn-outline-dashed btn-outline-default p-3  mx-3 align-items-center'
          htmlFor='emplacement_appart_de'
        >
          <img src={DE} alt='Dernier étage' width='70' height='105' />
          <span className='d-block fw-bold text-start'>
            <span className='text-dark fw-bolder d-block fs-6 mb-0 ms-3 align-items-center'>
              Dernier étage
            </span>
          </span>
        </label>
      </div>
    </div>
  )
}

const Step1: FC = () => {
  var [isToggledM, setIsToggledM] = useState(false)
  var [isToggledA, setIsToggledA] = useState(false)

  return (
    <div className='w-100'>
      <div className='mb-10 fv-row'>
            <label className='form-label required mb-3'>Adresse :</label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid border-dark '
              name='Adresse'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='Adresse' />
            </div>
          </div>
      <div className='row'>
        <div className='row align-items-center '>
          <div className='col'>
            <label className='form-label required'>Type de logement : </label>
          </div>
          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='LogementType'
              value='Maison'
              id='kt_create_account_form_logement_type_maison'
              onClick={() => {
                setIsToggledM((isToggledM = true))
                setIsToggledA((isToggledA = false))
              }}
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default  align-items-center'
              htmlFor='kt_create_account_form_logement_type_maison'
            >
              <img src={homeB} alt='fireSpot' width='90' height='85' />

              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block text-center'> Maison</span>
              </span>
            </label>
          </div>

          <div className='col '>
            <Field
              type='radio'
              className='btn-check'
              name='LogementType'
              value='Appartement'
              id='kt_create_account_form_logement_type_appartement'
              onClick={() => {
                setIsToggledA((isToggledA = true))
                setIsToggledM((isToggledM = false))
              }}
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-3 align-items-center '
              htmlFor='kt_create_account_form_logement_type_appartement'
            >
              <img src={buildingB} alt='fireSpot' width='90' height='85' />

              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block text-center'>Appartement</span>
              </span>
            </label>
          </div>

          <div className='text-danger mt-2'>
            <ErrorMessage name='LogementType' />
          </div>
          <div className='fv-row'>
            {isToggledM && <MaisonEtage />}
            {isToggledA && <AppartEtage />}
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label required'>Date de construction du logement</label>

            <Field as='select' name='Date' className='form-select border-dark '>
              <option value='Après 2012'>Après 2012</option>
              <option value='Entre 2006 et 2012'>Entre 2006 et 2012</option>
              <option value='Entre 2002 et 2005'>Entre 2002 et 2005</option>
              <option value='Entre 1997 et 2001'>Entre 1997 et 2001</option>
              <option value='Entre 1989 et 1996'>Entre 1989 et 1996</option>
              <option value='Entre 1982 et 1988'>Entre 1982 et 1988</option>
              <option value='Entre 1977 et 1981'>Entre 1977 et 1981</option>
              <option value='Entre 1974 et 1976'>Entre 1974 et 1976</option>
              <option value='Avant 1974'>Avant 1974</option>
            </Field>

            <div className='text-danger mt-2'>
              <ErrorMessage name='Date' />
            </div>
          </div>
          <div className='mb-10 fv-row'>
            <label className='form-label required mb-3'>Nombre de personnes habitantes :</label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid border-dark '
              name='NbHabitants'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='NbHabitants' />
            </div>
          </div>

          <div className='mb-10 fv-row'>
            <label className='form-label required mb-3'>Surface habitable :</label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid border-dark '
              name='Surface'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='Surface' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step1}
