/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
// import {KTSVG} from '../../../../../_metronic/helpers'
import { Formik, Form, FormikValues, Field, ErrorMessage } from 'formik'

const Step6: FC<{onChange: any, state:any}> = ({onChange }) => {
  return (
    <div className='w-100'>
    <div>
     <h1>Informations personnelles</h1>

     <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                <span className='required'>Prénom</span>
                {/* <i className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Specify your unique app name'
                ></i> */}
            </label>
            <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='firstname'
                placeholder='Saisir votre prénom'
                onChange={onChange}
                // value={state.firstname}
            />
            {/* <div className='fv-plugins-message-container invalid-feedback'> */}
            <div className='text-danger mt-2'>
                <ErrorMessage name='firstname' />
            </div>
        </div>
        <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                <span className='required'>Nom</span>
                {/* <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Specify your unique app name'
                ></i> */}
            </label>
 
            <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='lastname'
                placeholder='Saisir votre nom'
                onChange={onChange}

            />
          <div className='text-danger mt-2'>
                <ErrorMessage name='lastname' />
            </div>
        </div>

        <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                <span className='required'>Adresse e-mail</span>
                {/* <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Specify your unique app name'
                ></i> */}
            </label>
 
            <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='email'
                placeholder='Saisir votre adresse e-mail'
                onChange={onChange}

            />
          <div className='text-danger mt-2'>
                <ErrorMessage name='email' />
            </div>
        </div>

        <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                <span className='required'>Numéro de téléphone</span>
                {/* <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Specify your unique app name'
                ></i> */}
            </label>
 
            <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='telephone'
                placeholder='Saisir votre numéro de téléphone'
                onChange={onChange}

            />
          <div className='text-danger mt-2'>
                <ErrorMessage name='telephone' />
            </div>
        </div>
       

   </div>


    </div>

  )
}

export {Step6}
