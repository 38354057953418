/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import {Languages} from './Languages'

const HeaderUserMenu: FC = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  // console.log('""""""""""""""""""""""""""',user.account_goal)
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          {/* <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={user.pic} />
          </div> */}

          <div className='d-flex flex-column'>
            {user  && 
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {user.first_name} {/*{user.last_name}*/}
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>
                {user.account_goal===1? "Propriétaire"
                  :user.account_goal===2? "Locataire"
                  :""
                } 
              </span>
            </div>
            }
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {user.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>
      {user.account_goal===1 &&
        <>
          <div className='menu-item px-5'>
            <a href='/properties' className='menu-link px-5'>
              
                <span className='menu-text'>Mes Logements</span>
              
              {/* <span className='menu-badge'>
                <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
              </span> */}
            </a>
          </div>
          <div className='separator my-2'></div>
        </>
      }

      

      {/* <Languages />*/}

      <div className='menu-item px-5 my-1'>
        <Link to='/account/settings' className='menu-link px-5'>
          Modifier mon profil 
        </Link>
      </div> 

      <div className='menu-item px-5'>
        <Link to='/logout' className='menu-link px-5'>
          Se déconnecter
        </Link>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
