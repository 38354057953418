import * as Yup from 'yup'

export interface CreateLogement {
  LogementType: string
  Date: string
  NbHabitants: number 
  Surface: number
  NbEtages: number
  NivApp: string
  TVitrage: string
  VolRoulants: string
  TOuvrant: string
  Renov: string
  TEnCP: string
  TEnCS: string
  TChauffage: string
  MHC: number
  ProgChauffage: string
  TEnEcs: string
  MRenovAir: string
  clim: string

}

const createLogementSchemas = [
  Yup.object({
    LogementType: Yup.string().required().label('Logement Type'),
    Date: Yup.string().required().label('Date'),
    NbEtages: Yup.number().required().label('Nombre d étage(s)'),
    NbHabitants: Yup.number().required().label('Nombre de personnes habitantes'), 
    Surface: Yup.number().required().label('Surface habitable'), 
  }),
 
  Yup.object({
    TVitrage:  Yup.string().required(),
    VolRoulants:  Yup.string(),
    TOuvrant:  Yup.string().required(),
    Renov:  Yup.string().required(),
  }),

  Yup.object({
    TEnCP: Yup.string().required(),
    TEnCS: Yup.string().required(),
    TChauffage: Yup.string().required(),
    MHC: Yup.number().required().label('Moyenne d \'heures d utilisation du Chauffage'), 
    ProgChauffage: Yup.string().required(),
    TEnEcs: Yup.string().required(),
    MRenovAir: Yup.string().required(),
    clim: Yup.string().required(),
  }),

]

const inits: CreateLogement = {
  LogementType: 'Maison',
  Date: 'mmm',
  NbHabitants:0,
  Surface: 0, 
  NbEtages: 0,
  NivApp: 'Entre deux étages',
  TVitrage: 'Simple Vitrage',
  VolRoulants: 'true',
  TOuvrant: 'Bois',
  Renov: 'true',
  TEnCP: '',
  TEnCS: '',
  TChauffage: '1',
  MHC: 0,
  ProgChauffage: 'true',
  TEnEcs: 'Electricité',
  MRenovAir: 'Naturel',
  clim: 'true',
}

export {createLogementSchemas, inits}
