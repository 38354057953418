/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
// import {KTSVG} from '../../../../../_metronic/helpers'
import { Field, ErrorMessage } from 'formik'

const iconHouse = require('../utils/house.png').default;
const iconFlat = require('../utils/building.png').default;

const Step2: React.FC<{ onChange: any, message: string, state: any }> = ({ onChange, message, state }) => {
    
    return (
        <div className='w-100' key={`step2${message}`}>
            <div className='fv-row mb-10'>
                <div className='fv-row'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-4'>
                        <span className='required'>Type de logement</span>

                    </label>
                </div>
                <div className='mb-10 fv-row'>
                    <div className='row mb-2' data-kt-buttons='true'>
                        <div className='col-xs-6 col-md-6 mb-2'>
                            <input
                                onChange={onChange}
                                type='radio'
                                className='btn-check'
                                name='type_bien'
                                value={2}
                                id='type_logement_select_1'
                            />
                            <label
                                className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                                htmlFor='type_logement_select_1'
                            >
                                <span className='fw-bolder fs-7' title="Appartement">
                                    <img src={iconFlat} width="100" height="100" alt="Appartement"/>
                                    <br/>Appartement
                                </span>
                            </label>
                        </div>

                        <div className='col-xs-6 col-md-6 mb-2'>
                            <input
                                onChange={onChange}
                                type='radio'
                                className='btn-check'
                                name='type_bien'
                                value={1}
                                id='type_logement_select_2'
                            />
                            <label
                                className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                                htmlFor='type_logement_select_2'
                            >
                                <span className='fw-bolder fs-7' title="Maison">
                                    <img src={iconHouse} width="100" height="100" alt="Maison"/>
                                    <br/>Maison
                                </span>
                            </label>
                        </div>


                    </div>
                    <div className='text-danger mt-2'>
                        <ErrorMessage name='type_bien' />
                    </div>
                </div>
            </div>
            <div className='fv-row mb-10 row'>
                <div className='col-xs-6 col-md-6 mb-2'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                        <span className='required'>Surface</span> 
                    </label>

                    <input
                        type='text'
                        className='form-control form-control-lg form-control-solid w-md-300px'
                        name='surface'
                        placeholder='Surface en m²'
                        onChange={onChange}

                    />
                    <div className='text-danger mt-2'>
                        <ErrorMessage name='surface' />
                    </div>
                </div>
                <div className='col-xs-6 col-md-6 mb-2'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                        <span>Année de construction</span> 
                    </label>

                    <input
                        type='text'
                        className='form-control form-control-lg form-control-solid w-md-300px'
                        name='date_built'
                        placeholder='Année de construction'
                        onChange={onChange}

                    />
                    <div className='text-danger mt-2'>
                        <ErrorMessage name='date_built' />
                    </div>
                </div>
            </div>

            <div className='row mb-10'>
                <div className='col'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                        <span className='required'>Nombre de pièces</span> 
                    </label>
                    <input 
                        type="text" 
                        className="form-control" 
                        name="nb_pieces"
                        value={state.nb_pieces} 
                        min="1"
                        onChange={onChange}
                    />
                    <div className='text-danger mt-2'>
                        <ErrorMessage name='nb_pieces' />
                    </div>
                </div>
                <div className='col'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                        <span>Nombre de chambres</span> 
                    </label>
                    <input 
                        type="text" 
                        className="form-control" 
                        name="nb_chambres"
                        value={state.nb_chambres} 
                        // min="0"
                        onChange={onChange}
                    />
                    <div className='text-danger mt-2'>
                        <ErrorMessage name='nb_chambres' />
                    </div>
                </div>
            </div>
            <div className='row mb-10'>
                <div className='col'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                        <span>Nbre de SDB</span> 
                    </label>
                    <input 
                        type="text" 
                        className="form-control" 
                        name="nb_sdb"
                        value={state.nb_sdb} 
                        min="0"
                        onChange={onChange}
                    />
                    <div className='text-danger mt-2'>
                        <ErrorMessage name='nb_sdb' />
                    </div>
                </div>
                <div className='col'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                        <span>Nbre de SDE</span> 
                    </label>
                    <input 
                        type="text" 
                        className="form-control" 
                        name="nb_sde"
                        value={state.nb_sde} 
                        min="0"
                        onChange={onChange}
                    />
                    <div className='text-danger mt-2'>
                        <ErrorMessage name='nb_sde' />
                    </div>
                </div>
                <div className='col'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                        <span>Nbre de WC</span> 
                    </label>
                    <input 
                        type="text" 
                        className="form-control" 
                        name="nb_wc"
                        value={state.nb_wc} 
                        min="0"
                        onChange={onChange}
                    />
                    <div className='text-danger mt-2'>
                        <ErrorMessage name='nb_wc' />
                    </div>
                </div>
            </div>
           
            
        </div>

    )
}

export { Step2 }
