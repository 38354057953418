/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useRef, useState} from 'react'
// import {KTSVG} from '../../../../../_metronic/helpers'
import {Field, ErrorMessage} from 'formik'
import { inputUnstyledClasses } from '@mui/base';

const Step1:  React.FC<{    onChange: any, 
                            message: string, 
                            state:any, 
                            loading: boolean, 
                            choicesAddress: any,
                            isSelectedAddress: any,
                            selectAddress: any
                        }> = ({
                                onChange,
                                message,
                                state, 
                                loading, 
                                choicesAddress,
                                isSelectedAddress,
                                selectAddress
                            }) => {
    
    return (
        <div className='w-100' key={`step1${message}`}>
            <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                    <span className='required'>Adresse</span>
                    <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='votre adresse actuelle'
                    ></i>
                </label>
                { loading && 
                    <span style={{position: 'absolute', marginLeft: '19vw'}} className="spinner-border text-primary spinner-border-lg align-middle mt-3"></span>
                }
                <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='address'
                    placeholder='Saisir votre adresse actuelle'
                    onChange={onChange}
                    autoComplete="off"
                    value={state.address}
                />
                <div>

                </div>
                <div className='text-danger mt-2'>
                    {   choicesAddress && 
                        isSelectedAddress && 
                        choicesAddress.length > 0 && 
                        <table className="table table-striped table-row-dashed table-row-gray-300 gy-5 ml-5">
                            {/* <thead>
                                <tr className="fw-bolder fs-6 text-gray-800">
                                    <th>Sélectionnez une adresse</th>
                                </tr>
                            </thead> */}
                            <tbody>
                            {choicesAddress && choicesAddress.map((val:any, index:number) => { 
                                return(
                                <tr key={index}>
                                    <td onClick={ () => selectAddress(val)} style={{cursor: 'pointer'}}>{val.properties.label}</td>
                                </tr>)
                            })}
                            </tbody>
                        </table>
                    }                        
                        
                </div>
                <div className='text-danger mt-2'>
                    <ErrorMessage name='address' />
                </div>
            </div>

        </div>
    )
}

export {Step1}
