/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
// import {KTSVG} from '../../../../../_metronic/helpers'
import {Field, ErrorMessage} from 'formik'

const Step3: React.FC<{onChange: any, message: string, state:any }> = ({onChange ,message }) => {
    return (
 
    <div className='w-100' key={`step3${message}`}>
        <div className='fv-row mb-10'>
                <div className='fv-row'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-4'>
                        <span className='required'>Type de logement</span>

                    </label>
                </div>
                <div className='mb-10 fv-row'>
                    <div className='row mb-2' data-kt-buttons='true'>
                        <div className='col-xs-4 col-md-4 mb-2'>
                            <input
                                onChange={onChange}
                                type='radio'
                                className='btn-check'
                                name='etat_bien'
                                value='1'
                                id='Etat_bien_1'
                            />
                            <label
                                className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                                htmlFor='Etat_bien_1'
                            >
                                <span className='fw-bolder fs-7'>Neuf</span>
                            </label>
                        </div>

                        <div className='col-xs-4 col-md-4 mb-2'>
                            <input
                                onChange={onChange}
                                type='radio'
                                className='btn-check'
                                name='etat_bien'
                                value='2'
                                id='Etat_bien_2'
                            />
                            <label
                                className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                                htmlFor='Etat_bien_2'
                            >
                                <span className='fw-bolder fs-7'>Refait à neuf</span>
                            </label>
                        </div>
                        <div className='col-xs-4 col-md-4 mb-2'>
                            <input
                                onChange={onChange}
                                type='radio'
                                className='btn-check'
                                name='etat_bien'
                                value='3'
                                id='Etat_bien_3'
                            />
                            <label
                                className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                                htmlFor='Etat_bien_3'
                            >
                                <span className='fw-bolder fs-7'>Travaux à prévoir</span>
                            </label>
                        </div>
                        <div className='col-xs-4 col-md-4 mb-2'>
                            <input
                                onChange={onChange}
                                type='radio'
                                className='btn-check'
                                name='etat_bien'
                                value='4'
                                id='Etat_bien_4'
                            />
                            <label
                                className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                                htmlFor='Etat_bien_4'
                            >
                                <span className='fw-bolder fs-7'>A rafraichir</span>
                            </label>
                        </div>

                    </div>
                    <div className='text-danger mt-2'>
                        <ErrorMessage name='etat_bien' />
                    </div>
                </div>
            </div>
            
            <div className="row mb-10">
                <div className="col-6">
                    <div className='fv-row mb-10'>
                        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className=''>Surface du terrain</span> 
                        </label>

                        <input
                            type='text'
                            className='form-control form-control-lg form-control-solid w-md-300px'
                            name='Surface_terrain'
                            placeholder='Surface du terrain m²'
                            onChange={onChange}

                        />
                        <div className='text-danger mt-2'>
                            <ErrorMessage name='Surface_terrain' />
                        </div>
                    </div>
                </div>
            </div>  

            <div className="row mb-10">
                <div className="col-6">
                    <div className='fv-row mb-10'>
                        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className=''>DPE</span> 
                        </label>

                        <select name="classe_dpe" className="form-select" aria-label="Select DPE" onChange={onChange}>
                            <option></option>
                            <option value="1">A</option>
                            <option value="2">B</option>
                            <option value="3">C</option>
                            <option value="4">D</option>
                            <option value="5">E</option>
                            <option value="6">F</option>
                            <option value="7">G</option>
                        </select>
                        <div className='text-danger mt-2'>
                            <ErrorMessage name='classe_dpe' />
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className='fv-row mb-10'>
                        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className=''>GES</span> 
                        </label>

                        <select name="classe_ges" className="form-select" aria-label="Select GES" onChange={onChange}>
                            <option></option>
                            <option value="1">A</option>
                            <option value="2">B</option>
                            <option value="3">C</option>
                            <option value="4">D</option>
                            <option value="5">E</option>
                            <option value="6">F</option>
                            <option value="7">G</option>
                        </select>
                        <div className='text-danger mt-2'>
                            <ErrorMessage name='classe_ges' />
                        </div>
                    </div>
                </div>
            </div>  
    </div>


  )
}

export {Step3}
