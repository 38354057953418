import React, { Suspense } from 'react'
import { Toaster } from 'react-hot-toast'
import { BrowserRouter } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import AuthInit from './modules/auth/redux/AuthInit'
import { Routes } from './routing/Routes'
type Props = {
  basename: string
}

const App: React.FC<Props> = ({ basename }) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <div><Toaster
                position="top-right"
                toastOptions={{
                  success: {
                    style: {
                      background: '#66d699',
                      color:'white',
                      height: '100px',

                      // width: '500px'
                    },
                  },
                  error: {
                    style: {
                      background: '#ea7474',
                      height: '100px',
                      color:'white'
                    },
                  },
                }}

              /></div>

              <Routes />
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export { App }
