/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import * as auth from '../redux/AuthRedux'
import {register} from '../redux/AuthCRUD'
import {Link, useHistory} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {registerAccount} from '../../tenant-file/userController'
import toast from 'react-hot-toast'
const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  changepassword: '',
  acceptTerms: false,
  // zipcode: '',
  // phone: '',
  accountGoal: ''
}

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, 'Au moins 3 symboles')
    .max(50, '50 symboles maximum')
    .required('Le prénom est obligatoire'),
  lastname: Yup.string()
    .min(3, 'Au moins 3 symboles')
    .max(50, '50 symboles maximum')
    .required('Le nom est obligatoire'),
  email: Yup.string()
    .email("Mauvais format d'e-mail")
    .min(3, 'Au moins 3 symboles')
    .max(50, '50 symboles maximum')
    .required("L'e-mail est obligatoire"),
  accountGoal: Yup.string()
    .matches(/^[1-2]$/, "Le type de compte est obligatoire")
    .required("Le type de compte est obligatoire"),
  // zipcode: Yup.number()
  //   .required('Le code postal est obligatoire')
  //   .positive()
  //   .label('Le code postal est invalide')
  //   .min(1),
  // phone: Yup.string()
  //   .min(3, 'Au moins 3 symboles')
  //   .max(50, '50 symboles maximum')
  //   .required('Le Téléphone est obligatoire'),
  password: Yup.string()
    .min(3, 'Au moins 3 symboles')
    .max(50, '50 symboles maximum')
    .required('Le mot de passe est obligatoire'),
  changepassword: Yup.string()
    .required('La confirmation du mot de passe est requise')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        'Le mot de passe et la confirmation du mot de passe ne correspondent pas'
      ),
    }),
  acceptTerms: Yup.bool().required('Vous devez accepter les Termes et Conditions'),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const [statusForm, setStatusForm] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory();
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      setTimeout(() => {
        const formDataRegisterAccount = new FormData()
        formDataRegisterAccount.append('email', values.email)
        formDataRegisterAccount.append('first_name', values.firstname)
        formDataRegisterAccount.append('last_name', values.lastname)
        formDataRegisterAccount.append('password1', values.password)
        formDataRegisterAccount.append('password2', values.changepassword)
        // formDataRegisterAccount.append('zip_code', values.zipcode)
        formDataRegisterAccount.append('account_goal', values.accountGoal)
        // formDataRegisterAccount.append('phone_number', values.phone)
        registerAccount(formDataRegisterAccount)
          .then((response: any) => {
            if (response.status === 201) {
              toast.success('Compte créé avec succès !')
              setLoading(false)
              setStatusForm(true)
              setSubmitting(true)
              resetForm()
              
            } else {
              toast.error("Une erreur s'est produit, veuillez réessayer")
              setStatus("Une erreur s'est produit, veuillez réessayer")
              setLoading(false)
            }
            
          })
          .catch((error: any) => {
            setLoading(false)
            setSubmitting(false)
            setStatus("Une erreur s'est produit, veuillez réessayer")
            toast.error("Une erreur s'est produit, veuillez réessayer")
          })
      }, 1000)
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='mcl_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Créer un compte</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>
          Déjà inscrit ?
          <Link to='/auth/login' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
            Se connecter
          </Link>
        </div>
        {statusForm && 
          <div className='mb-lg-15 alert alert-success'>
            <div className='alert-text font-weight-bold'>Veuillez vérifier votre boite email</div>
          </div>
        }
        {/* end::Link */}
      </div>
      {/* end::Heading */}

      {/* <button type='button' className='btn btn-light-primary fw-bolder w-100 mb-10'>
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
          className='h-20px me-3'
        />
        Se connecter avec Google
      </button>

      <div className='d-flex align-items-center mb-10'>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        <span className='fw-bold text-gray-400 fs-7 mx-2'>OU</span>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
      </div> */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Firstname */}
      <div className='row fv-row'>
        <div className='col-xl-6'>
          <label className='class="form-label fw-bolder text-dark fs-6'>Prénom</label>
          <input
            placeholder=''
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('firstname')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.firstname && formik.errors.firstname,
              },
              {
                'is-valid': formik.touched.firstname && !formik.errors.firstname,
              }
            )}
          />
          {formik.touched.firstname && formik.errors.firstname && (
            <div className='fv-plugins-message-container text-danger'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.firstname}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-xl-6'>
          {/* begin::Form group Lastname */}
          <div className='fv-row mb-5'>
            <label className='class="form-label fw-bolder text-dark fs-6'>Nom</label>
            <input
              placeholder=''
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('lastname')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.lastname && formik.errors.lastname,
                },
                {
                  'is-valid': formik.touched.lastname && !formik.errors.lastname,
                }
              )}
            />
            {formik.touched.lastname && formik.errors.lastname && (
              <div className='fv-plugins-message-container text-danger'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.lastname}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>E-mail</label>
        <input
          placeholder=''
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container text-danger'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Mot de passe</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder=''
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container text-danger'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirmez le mot de passe</label>
        <input
          type='password'
          placeholder=''
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container text-danger'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group type customer */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Type</label>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            type='radio'
            id='flexRadioChecked1'
            name='accountGoal'
            value={1}
            onChange={formik.getFieldProps('accountGoal').onChange}
            className={clsx(
              'form-check-input',
              {
                'is-invalid': formik.touched.accountGoal && formik.errors.accountGoal,
              },
              {
                'is-valid': formik.touched.accountGoal && !formik.errors.accountGoal,
              }
            )}
          />
          <label className='form-check-label mx-5' htmlFor='flexRadioChecked1'>
            Propriétaire
          </label>
          <input
            type='radio'
            id='flexRadioChecked2'
            name='accountGoal'
            value={2}
            onChange={formik.getFieldProps('accountGoal').onChange}
            className={clsx(
              'form-check-input',
              {
                'is-invalid': formik.touched.accountGoal && formik.errors.accountGoal,
              },
              {
                'is-valid': formik.touched.accountGoal && !formik.errors.accountGoal,
              }
            )}
          />
          <label className='form-check-label mx-5' htmlFor='flexRadioChecked2'>
            Locataire
          </label>
          
        </div>
        {formik.touched.accountGoal && formik.errors.accountGoal && (
            <div className='fv-plugins-message-container text-danger'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.accountGoal}</span>
              </div>
            </div>
          )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            id='mcl_login_cgu_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <label
            className='form-check-label fw-bold text-gray-700 fs-6'
            htmlFor='mcl_login_cgu_agree'
          >
            J'accepte les{' '}
            <a
              href='https://moncarnetlogement.fr/cgu/'
              target='_blank'
              className='ms-1 link-primary'
            >
              conditions générales d'utilisation
            </a>
            .
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='text-center'>
        <button
          type='submit'
          id='mcl_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Valider</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Chargement...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='mcl_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Annuler
          </button>
        </Link>
      </div>
    </form>
  )
}
