/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import toast from 'react-hot-toast'
import swal from 'sweetalert';
import { initsProperty, PropertyModel } from '../properties/models/PropertyModel';
// import { assignTenant, deleteAssignement, getPropertiesCandidatesList } from '../properties/propertiesController';
import { PageTitle } from '../../../_metronic/layout/core';
import { KTSVG } from '../../../_metronic/helpers';
import { getTenantAssignements, gererCandidature, deleteAssignement, } from './candidatureController';
import moment from 'moment'

const ListCandidatures: FC = () => {
  const [StateAssignement, setStateAssignement] = useState<PropertyModel>(initsProperty)


  useEffect(() => {
    loader(true)
    getlistcandidatures()


  }, [])

  const getlistcandidatures = () => {
    getTenantAssignements().then(response => {
      loader(false)
      setStateAssignement(prevValues => ({
        ...prevValues,
        candidatures: response.data
      }))
    })

  }
  // ACCEPTED
  // DECLINED
  // STOPPED
  const stopSharing = (id: any) => {
    loader(true)
    const formDataAssign = new FormData();
    formDataAssign.append('assignement_id', id)
    formDataAssign.append('share_status', 'STOPPED')

    gererCandidature(formDataAssign)
      .then(response => {
        getlistcandidatures()
        toast.success('partage de fichier arrété!')
      })
      .catch(error => {
        loader(false)
        toast.error('une erreur s\'est produite!')
      })
  }
  const acceptInvitation = (id: any) => {
    loader(true)
    const formDataAssign = new FormData();
    formDataAssign.append('assignement_id', id)
    formDataAssign.append('share_status', 'ACCEPTED')

    gererCandidature(formDataAssign)
      .then(response => {
        getlistcandidatures()
        toast.success('invitation acceptée avec success!')
      })
      .catch(error => {
        loader(false)
        toast.error('une erreur s\'est produite!')

      })
  }
  const declineInvitation = (id: any) => {
    // loader(true)
    const formRemove = new FormData();
    formRemove.append('share_status', 'DECLINED')
    formRemove.append('assignement_id', id)
    swal({
      title: "êtes-vous sûr de vouloir refuser l'invitation?",
      text: "Une fois supprimé, vous ne pourrez plus récupérer cette invitation !",
      icon: "warning",
      buttons: ["annuler","refuser"],
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          gererCandidature(formRemove)
            .then(response => {
              swal("invitation supprimée", {
                icon: "success",
              });
              getlistcandidatures()
            })
            .catch(error => {
              swal("une erreur s'est produite! ");
            });

        } else {
          // console.log("no")

          // swal("suppression annulée!");
        }
      });

  }
  const deleteCandidature = (id:any) => {
    // loader(true)
    const formRemove = new FormData();
    formRemove.append('assignement_id',id)
    swal({
      title: "êtes-vous sûr de vouloir supprimer l'invitation??",
      text: "Une fois supprimé, vous ne pourrez plus récupérer cette candidature !",
      icon: "warning",
      buttons: ["annuler","supprimer"],
      dangerMode: true,
  })
      .then((willDelete) => {
          if (willDelete) {
              deleteAssignement(formRemove)
              .then(response => {
                      swal("candidature supprimée!", {
                          icon: "success",
                      });
                      getlistcandidatures()
                    })
                  .catch(error => {
                    swal("une erreur s'est produite pendant la suppression!");
                  });

          } else {
              // console.log("no")

              // swal("Your  candidate is safe!");
          }
      });

  }
    // const handleChange = (event: any) => {
    //   console.log("handleChange")

    //   setStateProperty(prevValues => ({
    //     ...prevValues,
    //     [event.target.name]: event.target.value,
    //   }))
    // }
  const loader = (value: boolean) => {
    setStateAssignement(prevValues => ({
      ...prevValues,
      loader: value
    }))
  }


  return (
    <>
      <PageTitle breadcrumbs={[]}>Mes Logements</PageTitle>
      <div className='card mb-5 mb-xl-8'>

        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Liste de mes candidatures</span>
            {/* <span className='text-muted mt-1 fw-bold fs-7'>Liste des candidats par logement</span> */}
          </h3>

        </div>

        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className='min-w-150px'>Type de Bien</th>
                  <th className='min-w-150px'>Adresse</th>
                  <th className='min-w-140px'>Date d'invitation</th>
                  <th className='min-w-120px'>Statut d'invitation</th>
                  <th className='min-w-100px text-end'>Actions</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>

                {/* /////////////// */}
                {StateAssignement.candidatures.map((assignement: any) => (

                  <tr>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                            {assignement.property.typology} {assignement.property.category}
                            {' '}
                          </a>
                          {/* <span className='text-muted fw-bold text-muted d-block fs-7'>
                            {assignement.email}
                          </span> */}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-45px me-5'>
                          <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'> 
                            {assignement.property.address.length > 30? assignement.property.address.slice(0, 30)+'...':assignement.property.address}
                          </a>
                          {/* <span className='text-muted fw-bold text-muted d-block fs-7'>
                            <i className="bi bi-house text-primary"></i>  {assignement.typology}
                          </span> */}
                        </div>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                        {assignement.created_at ? moment(assignement.created_at).format('DD/MM/YYYY, hh:mm') : ''}
                      </a>
                    </td>

                    <td>

                      {assignement.tenant_share_status === "DECLINED" && <span className='badge badge-light-danger fs-7 fw-bold'> Invitation refusée </span>}
                      {assignement.tenant_share_status === "PENDING" && <span className='badge badge-light-warning fs-7 fw-bold'> Invitation en attente </span>}
                      {assignement.tenant_share_status === "ACCEPTED" && <span className='badge badge-light-success fs-7 fw-bold'> Invitation acceptée </span>}
                      {assignement.tenant_share_status === "STOPPED" && <span className='badge badge-light-info fs-7 fw-bold'> Partage arrêté </span>}

                    </td>


                    <td>

                      <div className='d-flex justify-content-end  flex-shrink-0' >

                        {/* {assignement.tenant_share_status === "DECLINED" && ()} */}
                        {assignement.tenant_share_status === "PENDING" && (<><button
                          type='button'
                          className='btn btn-sm bg-light-success  border-success border mx-2'
                          onClick={() => acceptInvitation(assignement.id)}

                        >
                          <KTSVG path="/media/icons/duotune/general/gen037.svg" className="svg-icon-dark svg-icon-2hx" />
                          Accepter
                        </button>

                          <button
                            type='button'
                            className='btn btn-sm bg-light-danger border-danger border mx-1'
                            onClick={() => declineInvitation(assignement.id)}
                          >
                            <KTSVG path="/media/icons/duotune/general/gen040.svg" className="svg-icon-dark svg-icon-2hx" />
                            Refuser
                          </button>
                        </>


                        )}
                        {assignement.tenant_share_status === "ACCEPTED" && (
                          <button
                            type='button'
                            className='btn btn-sm bg-light-info  border-info border mx-2'
                            onClick={() => stopSharing(assignement.id)}
                          >
                            <KTSVG path="/media/icons/duotune/general/gen044.svg" className="svg-icon-info svg-icon-2hx" />
                             Arrêter le partage
                          </button>)}

                        <button
                          type='button'
                          className='btn btn-sm bg-light-dark border-dark border mx-2'
                          onClick={() => deleteCandidature(assignement.id)}
                        >
                          <KTSVG path="/media/icons/duotune/general/gen027.svg" className="svg-icon-dark svg-icon-2hx" />
                          Archiver
                        </button>
                      </div>


                    </td>
                  </tr>



                  // ))

                ))}


                {/* ////////////////// */}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
        </div>   </div>    </>
  )
}

export { ListCandidatures }
