import React, {FC, useState} from 'react'
import {ErrorMessage} from 'formik'
import homeB from '../../../../imgs/homeB.png'
import RDC from '../../../../imgs/RDC.png'
import EDE from '../../../../imgs/EDE.png'
import DE from '../../../../imgs/DE.png'
import buildingB from '../../../../imgs/buildingB.png'
import {Building} from './typeadjacence'

const SimulateurStep1: React.FC<{onChange: any; state: any}> = ({onChange, state}) => {
  var [isToggledM, setIsToggledM] = useState(false)
  var [isToggledA, setIsToggledA] = useState(false)
  return (
    <div className='w-100' key={`simstep1`}>
      <div className='fv-row mb-10'>
        <label className='form-label required mb-2'>Rue :</label>

        <input
          type='text'
          className='form-control form-control-lg form-control-solid border-dark '
          name='{`rue`}'
          placeholder=''
          onChange={onChange}
          value={state.rue}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={`rue`} />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label required mb-2'>Code Postal :</label>

        <input
          type='text'
          className='form-control form-control-lg form-control-solid border-dark '
          name={`code_postal`}
          placeholder=''
          onChange={onChange}
          value={state.code_postal}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={`code_postal`} />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label required mb-2'>Département :</label>

        <input
          type='text'
          className='form-control form-control-lg form-control-solid border-dark '
          name={`departement`}
          placeholder=''
          onChange={onChange}
          value={state.departement}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={`departement`} />
        </div>
      </div>
      <div className='row align-items-center '>
        <div className='col'>
          <label className='form-label required'>Type de logement : </label>
        </div>
        <div className='col'>
          <input
            type='radio'
            className='btn-check'
            name={`type_logement`}
            value='Maison'
            id='kt_create_account_form_logement_type_maison'
            onChange={onChange}
            onClick={() => {
              setIsToggledM((isToggledM = true))
              setIsToggledA((isToggledA = false))
            }}
          />
          <label
            className='btn btn-outline btn-outline-dashed btn-outline-default  align-items-center'
            htmlFor='kt_create_account_form_logement_type_maison'
          >
            <img src={homeB} alt='fireSpot' width='45' height='40' />

            <span className='d-block fw-bold text-start'>
              <span className='text-dark fw-bolder d-block text-center'> Maison</span>
            </span>
          </label>
        </div>

        <div className='col '>
          <input
            type='radio'
            className='btn-check'
            name={`type_logement`}
            value='Appartement'
            id='kt_create_account_form_logement_type_appartement'
            onChange={onChange}
            onClick={() => {
              setIsToggledA((isToggledA = true))
              setIsToggledM((isToggledM = false))
            }}
          />
          <label
            className='btn btn-outline btn-outline-dashed btn-outline-default p-3 align-items-center '
            htmlFor='kt_create_account_form_logement_type_appartement'
          >
            <img src={buildingB} alt='fireSpot' width='45' height='40' />

            <span className='d-block fw-bold text-start'>
              <span className='text-dark fw-bolder d-block text-center'>Appartement</span>
            </span>
          </label>
        </div>
      </div>
      <div className='fv-row'>
        {isToggledM && (
          <>
            {' '}
            <div className='mb-10 fv-row'>
              <label className='form-label mb-3'>Nombre d'étage(s)</label>

              <input
                type='text'
                className='form-control form-control-lg form-control-solid border-1 border-dark'
                name='NbEtages'
                onChange={onChange}
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='NbEtages' />
              </div>
            </div>
          </>
        )}
        {isToggledA && (
          <>
            {' '}
            <div className='fv-row mb-10'>
              <label className='form-label required'>L'emplacement du logement</label>
              <div className='col text-center'>
                <input
                  type='radio'
                  className='btn-check'
                  name='emplacement_logement'
                  value='emplacement_logement'
                  id='emplacement_aemplacement_logementppart_rdc'
                  onChange={onChange}
                />
                <label
                  className='btn btn-outline btn-outline-dashed btn-outline-default p-3 mx-3 align-items-center'
                  htmlFor='emplacement_appart_rdc'
                >
                  <img src={RDC} alt='Rez-de-chaussée' width='35' height='50' />
                  <span className='d-block fw-bold text-start'>
                    <span className='text-dark fw-bolder d-block fs-6 mb-0 ms-0 align-items-center'>
                      Rez-de-chaussée
                    </span>
                  </span>
                </label>
                <input
                  type='radio'
                  className='btn-check'
                  name='NivApp'
                  value='NivAppEDE'
                  id='emplacement_appart_ede'
                  onChange={onChange}
                />
                <label
                  className='btn btn-outline btn-outline-dashed btn-outline-default p-3 mx-3 align-items-center'
                  htmlFor='emplacement_appart_ede'
                >
                  <img src={EDE} alt='Entre deux étages' width='35' height='50' />
                  <span className='d-block fw-bold text-start'>
                    <span className='text-dark fw-bolder d-block fs-6 mb-0 ms-0 align-items-center'>
                      Entre deux étages
                    </span>
                  </span>
                </label>
                <input
                  type='radio'
                  className='btn-check'
                  name='NivApp'
                  value='NivAppDE'
                  id='emplacement_appart_de'
                  onChange={onChange}
                />
                <label
                  className='btn btn-outline btn-outline-dashed btn-outline-default p-3  mx-3 align-items-center'
                  htmlFor='emplacement_appart_de'
                >
                  <img src={DE} alt='Dernier étage' width='35' height='50' />
                  <span className='d-block fw-bold text-start'>
                    <span className='text-dark fw-bolder d-block fs-6 mb-0 ms-3 align-items-center'>
                      Dernier étage
                    </span>
                  </span>
                </label>
              </div>
            </div>
          </>
        )}
      </div>
      <div className='row mb-10 align-items-center '>
        <div className='col'>
          <label className='form-label required'>Forme de bien </label>
        </div>
        <div className='col'>
          <input
            type='radio'
            className='btn-check'
            name='forme_bien'
            value='carre'
            id='kt_carre'
            onChange={onChange}
          />
          <label
            className='btn btn-outline btn-outline-dashed btn-outline-default  align-items-center'
            htmlFor='kt_carre'
          >
            {/* <img src={homeB} alt='fireSpot' width='45' height='40' /> */}

            <span className='d-block fw-bold text-start'>
              <span className='text-dark fw-bolder d-block text-center'> Carré</span>
            </span>
          </label>
        </div>

        <div className='col '>
          <input
            type='radio'
            className='btn-check'
            name='forme_bien'
            value='rectangle'
            id='kt_rectangle'
            onChange={onChange}
          />
          <label
            className='btn btn-outline btn-outline-dashed btn-outline-default p-3 align-items-center '
            htmlFor='kt_rectangle'
          >
            {/* <img src={buildingB} alt='fireSpot' width='45' height='40' /> */}

            <span className='d-block fw-bold text-start'>
              <span className='text-dark fw-bolder d-block text-center'>Rectangle</span>
            </span>
          </label>
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label required mb-2'>Surface habitable</label>

        <input
          type='number'
          className='form-control form-control-lg form-control-solid border-dark'
          name={`living_space`}
          placeholder=''
          onChange={onChange}
          value={state.living_space}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={`living_space`} />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label required mb-2'>Hauteur sous plafond (en mètre)</label>

        <input
          type='number'
          className='form-control form-control-lg form-control-solid border-dark'
          name={`hauteur_sous_plafon`}
          placeholder=''
          onChange={onChange}
          value={state.hauteur_sous_plafon}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={`hauteur_sous_plafon`} />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label required mb-2'>Nombre de pièces</label>

        <input
          type='number'
          className='form-control form-control-lg form-control-solid border-dark'
          name={`rooms_number`}
          placeholder=''
          onChange={onChange}
          value={state.rooms_number}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={`rooms_number`} />
        </div>
      </div>
      <Building
            onChange={onChange}
            statevalue={state.periode_construction_logement}
            name={`periode_construction_logement`}
          />
    </div>
  )
}

export {SimulateurStep1}
