import React, { FC, useState } from 'react'
import { ErrorMessage } from 'formik'
import { KTSVG } from '../../../../_metronic/helpers'

import { Adjacence, TypeMenuisier, TypeFermeture, TypeBaie } from './typeadjacence'

const SimulateurStep4: React.FC<{ editFenetre: any, deleteFenetre: any, addFenetre: any, onChange: any, state: any }> = ({ editFenetre, deleteFenetre, addFenetre, onChange, state }) => {

    return (
        <div className='d-flex flex-column flex-lg-row'>
            <div className='flex-column flex-lg-row-auto w-100 w-lg-400px w-xl-550px mb-10 mb-lg-0'>

                <TypeMenuisier onChange={onChange} statevalue={state.type_materiau_fenetre} name="type_materiau_fenetre" />
                <div className='fv-row mb-10'>

                    <label className='form-label required mb-2'>Longueur </label>

                    <input
                        type='number'
                        className='form-control form-control-lg form-control-solid border-dark'
                        name={`longueur_fenetre`}
                        placeholder=''
                        onChange={onChange}
                        value={state.longueur_fenetre}
                    />
                    <div className='text-danger mt-2'>
                        <ErrorMessage name={`longueur_fenetre`} />
                    </div>
                </div> 
                <div className='fv-row mb-10'>

                    <label className='form-label required mb-2'>Largeur </label>

                    <input
                        type='number'
                        className='form-control form-control-lg form-control-solid border-dark'
                        name={`largeur_fenetre`}
                        placeholder=''
                        onChange={onChange}
                        value={state.largeur_fenetre}
                    />
                    <div className='text-danger mt-2'>
                        <ErrorMessage name={`largeur_fenetre`} />
                    </div>
                </div>
                <TypeFermeture onChange={onChange} statevalue={state.type_fermeture} name="type_fermeture" />
                <TypeBaie onChange={onChange} statevalue={state.type_baie} name="type_baie" />
                <Adjacence onChange={onChange} statevalue={state.adjacence_fenetre} name="adjacence_fenetre" />

                {/* {['4','7|8','6','7','8'].includes(state.type_materiau_fenetre) && (<> */}
                <div className='fv-row mb-10'>
                    <label className='form-label required mb-2'>
                        Type de vitrage
                    </label>
                    <select
                        name='vitrage_menuisier'
                        className='form-select form-select-lg form-select-solid border-dark'
                        onChange={onChange}
                        value={state.vitrage_menuisier}
                    >
                        <option value="" disabled>choisir </option>
                        <option value='1'>Simple vitrage</option>
                        <option value='2'>Double vitrage</option>
                        <option value='3'>Survitrage</option>
                        <option value='4'>Triple vitrage</option>
                    </select>
                </div>
                <div className='fv-row mb-10'>
                    <label className='form-label required mb-2'>
                        Type de paroi
                    </label>
                    <select
                        name='paroi_menuisier'
                        className='form-select form-select-lg form-select-solid border-dark'
                        onChange={onChange}
                        value={state.paroi_menuisier}
                    >
                        <option value="" disabled>choisir </option>
                        <option value='1'>horizontal</option>
                        <option value='2'>vertical</option>

                    </select>
                </div>
                {/* </>)} */}

                <br />
                <button type="button" className='btn btn-lg btn-info w-100 ' onClick={addFenetre}>
                    <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                    Sauvegarder
                </button>
            </div>

            <div className='flex-lg-row-fluid ms-lg-7  w-xl-400px ms-xl-10'>
                <div className='card card-xl-stretch mb-5 mb-xl-8'>


                    <div className='card-body pt-0' style={{ overflowY: "scroll", height: "650px" }}>

                        {state.array_fenetres.length > 0 && state.array_fenetres.map((fenetre: any, idx: any) => (<>
                            <div className='d-flex align-items-center bg-light-info rounded p-5'>
                                <span className='svg-icon svg-icon-info me-5'>
                                    <KTSVG path='/media/icons/duotune/general/gen028.svg' className='svg-icon-1' />
                                </span>

                                <div className='flex-grow-1 me-2'>
                                    <a href='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>
                                        fenetre n° {idx + 1} : {fenetre.largeur_fenetre && fenetre.longueur_fenetre && parseInt(fenetre.largeur_fenetre) * parseInt(fenetre.longueur_fenetre) + " m²"}
                                    </a>
                                    {/* <span className=' fw-bold d-block'> {fenetre.largeur_fenetre && fenetre.longueur_fenetre && parseInt(fenetre.largeur_fenetre) * parseInt(fenetre.longueur_fenetre) + " m²"}</span> */}
                                </div>
                                <button type="button" className='btn btn-sm btn-warning me-5' onClick={e => editFenetre(idx)}>
                                    <span className='svg-icon svg-icon-warning'>
                                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-1' />
                                    </span>
                                </button>
                                <button type="button" className='btn btn-sm btn-danger ' onClick={e => deleteFenetre(idx)}>
                                    <span className='svg-icon svg-icon-danger'>
                                        <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-1' />
                                    </span></button>
                            </div>
                            <br />
                        </>
                        ))}

                    </div>
                </div>
            </div>
        </div>

    )
}

export { SimulateurStep4 }
