/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { ErrorMessage } from 'formik'
import { KTSVG } from '../../../../../_metronic/helpers'

const Etape1: React.FC<{onChangeOwner:any, onChange: any, state: any ,addOwner:any}> = ({ onChangeOwner,onChange, state,addOwner }) => {
  return (
    <div className='w-100' key={`etape1`}>
      <div className='mb-10 fv-row'>
        <label className='d-flex align-items-center form-label mb-5'>
          Sélectionner le type de bailleur
        </label>

        <div className='mb-10'>
          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='form-check form-check-custom form-check-solid me-2'>
                <input className='form-check-input' onChange={onChange}
                  type='radio' name='owner_profile' value='natural_person' />
              </span>
              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  Personne physique                </span>
                {/* <span className='fs-6 fw-bold text-gray-400'>Use images to your post time</span> */}
              </span>
            </span>


          </label>

          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='form-check form-check-custom form-check-solid me-2'>
                <input className='form-check-input' type='radio' onChange={onChange}
                  name='owner_profile' value='legal_person' />
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  Personne morale
                </span>
              </span>
            </span>
          </label>


          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='form-check form-check-custom form-check-solid me-2'>
                <input className='form-check-input' onChange={onChange} type='radio' name='owner_profile' value='mandatory' />
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  Représenté par un mandataire
                </span>
              </span>
            </span>

          </label>
        </div>

        <div className='text-danger mt-2'>
          <ErrorMessage name={`owner_profile`} />
        </div>

      </div>
      {state.owner_profile === "natural_person" && (
  
        state.owners_array.map((owner:any,idx:number) => (   
        <>     <div className='fv-row mb-10'>
            
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Nom</span>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`owner_lastname`}
              placeholder='Saisir le nom'
              onChange={(e) => onChangeOwner(e,idx)}
              value={owner.owner_lastname}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`owner_lastname`} />
            </div>
          </div>
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Prénom </span>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`owner_firstname`}
              placeholder='Saisir le prénom'
              onChange={(e) => onChangeOwner(e,idx)}
              value={owner.owner_firstname}
            />

            <div className='text-danger mt-2'>
              <ErrorMessage name={`owner_firstname`} />
            </div>
          </div>
     
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Adresse </span>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`owner_address`}
              placeholder="Saisir l'adresse"
              onChange={(e) => onChangeOwner(e,idx)}
              value={owner.owner_address}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`owner_address`} />
            </div>
          </div>
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Téléphone</span>
            </label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              name={`owner_phone`}
              placeholder="Saisir le numero de telephone"
              onChange={(e) => onChangeOwner(e,idx)}
              value={owner.owner_phone}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`owner_phone`} />
            </div>
          </div>
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Email</span>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`owner_email`}
              placeholder="Saisir l'email"
              onChange={(e) => onChangeOwner(e,idx)}
              value={owner.owner_email}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`owner_email`} />
            </div>
          </div>

          <button type="button" className='btn btn-sm btn-light-primary' onClick={addOwner}>
            <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
           Ajouter un bailleur
          </button>
        
        </>


        ))
     
      )}
      {state.owner_profile === "legal_person" && (<>


        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Dénomination sociale</span>
          </label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={`denomination`}
            placeholder='Saisir le prénom'
            onChange={onChange}
            value={state.denomination}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`denomination`} />
          </div>
        </div>
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Siège social</span>
          </label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={`siege_social`}
            placeholder='Siege social'
            onChange={onChange}
            value={state.siege_social}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`siege_social`} />
          </div>
        </div>
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>RCS</span>
          </label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={`num_rcs`}
            placeholder="Saisir RCS"
            onChange={onChange}
            value={state.num_rcs}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`num_rcs`} />
          </div>
        </div>
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Nom et prénom du représentant </span>
          </label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={`representant_name`}
            placeholder="Saisir le nom de représentant"
            onChange={onChange}
            value={state.representant_name}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`representant_name`} />
          </div>
        </div>

        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Email du représentant</span>
          </label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={`email_representant`}
            placeholder="Saisir l'email de représentant"
            onChange={onChange}
            value={state.email_representant}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`email_representant`} />
          </div>
        </div>

        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Téléphone du représentant </span>
          </label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={`tel_representant`}
            placeholder="Saisir le numéro de représentant"
            onChange={onChange}
            value={state.tel_representant}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`tel_representant`} />
          </div>
        </div>



      </>)}
      {state.owner_profile === "mandatory" && (
        <>
       
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Nom </span>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`owner_lastname`}
              placeholder='Saisir le nom'
              onChange={onChange}
              value={state.owner_lastname}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`owner_lastname`} />
            </div>
          </div>
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Prénom </span>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`owner_firstname`}
              placeholder='Saisir le prénom'
              onChange={onChange}
              value={state.owner_firstname}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`owner_firstname`} />
            </div>
          </div>
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Adresse </span>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`owner_address`}
              placeholder="Saisir l'adresse"
              onChange={onChange}
              value={state.owner_address}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`owner_address`} />
            </div>
          </div>
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Téléphone</span>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`owner_phone`}
              placeholder="Saisir le numero de telephone"
              onChange={onChange}
              value={state.owner_phone}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`owner_phone`} />
            </div>
          </div>
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Email </span>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`owner_email`}
              placeholder="Saisir l'email"
              onChange={onChange}
              value={state.owner_email}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`owner_email`} />
            </div>
          </div>

        </>
      )}

    </div>
  )
}

export { Etape1 }
