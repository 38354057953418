export enum PropertyStatus {
    Neuf = 1,
    Refait_a_neuf = 2,
    Travaux_a_prevoir = 3,
    A_raffraichir = 4,
    
}

export enum PropertyType {
    Maison = 1,
    Appartement = 2,
}
export enum Classification {
    A = 1,
    B = 2,
    C = 3,
    D = 4,
    E = 5,
    F = 6,
    G = 7,
}

export enum TypeChauffage {
    Individuel = 1,
    Collectif = 2
}

export enum TypeOrientation {
    Nord = 1,
    Sud = 2,
    Ouest = 3,
    Est = 4,
    SudEst = 5,
    SudOuest = 6
}

export enum TypeVue {
    Degage = 1,
    Vis_a_vis =  2,
    Exceptionnelle = 3
}

export interface EstimationModel {
    address: string
    detail_address: any
    
    type_bien: PropertyType
    surface: number
    date_built: number
    nb_pieces: number
    nb_chambres: number
    nb_sdb: number
    nb_sde: number
    nb_wc: number
    
    etat_bien: PropertyStatus
    surface_terrain: number
    date_realisation_dpe: number
    valeur_dpe: number
    valeur_ges: number
    classe_dpe: Classification
    classe_ges: Classification
    
    climatisation: boolean
    chauffage: boolean
    type_chauffage: TypeChauffage

    dernier_etage: boolean
    num_etage: number
    
    orientation: TypeOrientation
    vue: TypeVue

    ascenseur: boolean
    piscine: boolean
    garage: boolean
    veranda: boolean
    jardin: boolean
    terrasse: boolean
    cave: boolean
    box: boolean
    cheminee: boolean
    balcon: boolean
    parkingExt: boolean 
    parkingInt: boolean
    // parking: boolean
    calme: boolean
    bruyant: boolean
    localPoubelle: boolean
    
    firstname: string
    lastname: string
    email: string
    telephone: string
}

export const inits: EstimationModel = {

    address: '',
    detail_address: [],
    
    type_bien: 0,
    surface: 0,
    date_built: 0,
    nb_pieces: 1,
    nb_chambres: 0,
    nb_sdb: 0,
    nb_sde: 0,
    nb_wc: 0,
    
    etat_bien: 0,
    surface_terrain: 0,
    date_realisation_dpe: 0,
    valeur_dpe: 0,
    valeur_ges: 0,
    classe_dpe: 0,
    classe_ges: 0,
    
    climatisation: false,
    chauffage: false,
    type_chauffage: 0,

    dernier_etage: false,
    num_etage: 0,
    ascenseur: false,
    orientation: 0,
    vue: 0,
    calme: false,

    
    piscine: false,
    garage: false,
    veranda: false,
    jardin: false,
    terrasse: false,
    cave: false,
    box: false,
    bruyant: false,
    localPoubelle: false,

    cheminee: false,
    balcon: false,
    parkingExt: false,    
    parkingInt: false,    
    
    firstname: '',
    lastname: '',
    email: '',
    telephone: '',
}