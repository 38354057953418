/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { ErrorMessage } from 'formik'
import { KTSVG } from '../../../../../_metronic/helpers'

const Etape2: React.FC<{ onChangeTenant:any,  addTenant:any , onChange: any, state: any }> = ({onChangeTenant,  addTenant ,onChange, state }) => {
  return (
    <div className='w-100' key={`etape2`}>
       <div className='mb-10 fv-row'>
        <label className='d-flex align-items-center form-label mb-5'>
          Sélectionner le type de Locataire
        </label>

        <div className='mb-10'>

          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>

              <span className='form-check form-check-custom form-check-solid me-2'>
                <input className='form-check-input' onChange={onChange}
                  type='radio' name='tenant_profile' value='natural_person' />
              </span>
              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  Personne physique                </span>
                {/* <span className='fs-6 fw-bold text-gray-400'>Use images to your post time</span> */}
              </span>
            </span>


          </label>

          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='form-check form-check-custom form-check-solid me-2'>
                <input className='form-check-input' type='radio' onChange={onChange}
                  name='tenant_profile' value='legal_person' />
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  Personne morale
                </span>
              </span>
            </span>

          </label>
{/* 

          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='form-check form-check-custom form-check-solid me-2'>
                <input className='form-check-input' onChange={onChange} type='radio' name='tenant_profile' value='mandatory' />
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  Représenté par un mandataire
                </span>
              </span>
            </span>

          </label> */}
        </div>
      </div>



      {state.tenant_profile === "natural_person" && (
           state.tenants_array.map((tenant:any,idx:number) => (   

   <>    <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Nom </span>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`tenant_lastname`}
              placeholder='Saisir le nom'
              onChange={e=> onChangeTenant(e,idx)}
              value={tenant.tenant_lastname}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`tenant_lastname`} />
            </div>
          </div>
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Prénom </span>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`tenant_firstname`}
              placeholder='Saisir le prénom'
              onChange={e=> onChangeTenant(e,idx)}
              value={tenant.tenant_firstname}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`tenant_firstname`} />
            </div>
          </div>
      
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Adresse</span>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`tenant_address`}
              placeholder="Saisir l'adresse"
              onChange={e=> onChangeTenant(e,idx)}
              value={tenant.tenant_address}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`tenant_address`} />
            </div>
          </div>
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Téléphone</span>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`tenant_phone`}
              placeholder="Saisir le numero de telephone"
              onChange={e=> onChangeTenant(e,idx)}
              value={tenant.tenant_phone}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`tenant_phone`} />
            </div>
          </div>
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Email </span>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`tenant_email`}
              placeholder="Saisir l'email"
              onChange={e=> onChangeTenant(e,idx)}
              value={tenant.tenant_email}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`tenant_email`} />
            </div>
          </div>

          <button type="button" className='btn btn-sm btn-light-primary' onClick={addTenant}>
            <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
           Ajouter un locataire
          </button>
        </>
    ))
   
   )}
      {state.tenant_profile === "legal_person" && (<>


        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Dénomination sociale</span>
          </label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={`denomination_tenant`}
            placeholder='Saisir le prénom'
            onChange={onChange}
            value={state.denomination_tenant}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`denomination_tenant`} />
          </div>
        </div>
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Siège social</span>
          </label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={`siege_social_tenant`}
            placeholder='Siege social'
            onChange={onChange}
            value={state.siege_social_tenant}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`siege_social_tenant`} />
          </div>
        </div>
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>RCS</span>
          </label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={`num_rcs_tenant`}
            placeholder="Saisir RCS"
            onChange={onChange}
            value={state.num_rcs_tenant}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`num_rcs_tenant`} />
          </div>
        </div>
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Nom et prénom du  représentant </span>
          </label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={`representant_name_tenant`}
            placeholder="Saisir le nom de représentant"
            onChange={onChange}
            value={state.representant_name_tenant}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`representant_name_tenant`} />
          </div>
        </div>

        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Email du représentant</span>
          </label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={`email_representant_tenant`}
            placeholder="Saisir l'email de représentant"
            onChange={onChange}
            value={state.email_representant_tenant}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`email_representant_tenant`} />
          </div>
        </div>

        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Numéro du représentant </span>
          </label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={`tel_representant_tenant`}
            placeholder="Saisir le numéro de représentant"
            onChange={onChange}
            value={state.tel_representant_tenant}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`tel_representant_tenant`} />
          </div>
        </div>



      </>)}
      {/* {state.tenant_profile === "mandatory" && (
        <>
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Prénom Mandataire</span>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`tenant_firstname`}
              placeholder='Saisir le prénom'
              onChange={onChange}
              value={state.tenant_firstname}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`tenant_firstname`} />
            </div>
          </div>
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Nom de Mandataire</span>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`tenant_lastname`}
              placeholder='Saisir le nom'
              onChange={onChange}
              value={state.tenant_lastname}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`tenant_lastname`} />
            </div>
          </div>
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Adresse de Mandataire</span>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`tenant_address`}
              placeholder="Saisir l'adresse"
              onChange={onChange}
              value={state.tenant_address}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`tenant_address`} />
            </div>
          </div>
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Numéro de Mandataire</span>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`tenant_phone`}
              placeholder="Saisir le numero de telephone"
              onChange={onChange}
              value={state.tenant_phone}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`tenant_phone`} />
            </div>
          </div>
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Email de mandataire</span>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name={`tenant_email`}
              placeholder="Saisir l'email"
              onChange={onChange}
              value={state.tenant_email}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`tenant_email`} />
            </div>
          </div>

        </>
      )} */}



{/* 
     <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>Prénom Locataire</span>
        </label>
        <input
          type='text'
          className='form-control form-control-lg form-control-solid'
          name={`tenant_firstname`}
          placeholder='Saisir le prénom'
          onChange={onChange}
          value={state.tenant_firstname}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={`tenant_firstname`} />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>Nom de Locataire</span>
        </label>
        <input
          type='text'
          className='form-control form-control-lg form-control-solid'
          name={`tenant_lastname`}
          placeholder='Saisir le nom'
          onChange={onChange}
          value={state.tenant_lastname}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={`tenant_lastname`} />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>Adresse de Locataire</span>
        </label>
        <input
          type='text'
          className='form-control form-control-lg form-control-solid'
          name={`tenant_address`}
          placeholder="Saisir l'adresse"
          onChange={onChange}
          value={state.tenant_address}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={`tenant_address`} />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>Email de Locataire</span>
        </label>
        <input
          type='text'
          className='form-control form-control-lg form-control-solid'
          name={`tenant_email`}
          placeholder="Saisir l'email"
          onChange={onChange}
          value={state.tenant_email}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={`tenant_email`} />
        </div>
      </div>
 */}

    </div>
  )
}

export { Etape2 }
