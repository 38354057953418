import React, { FC, useState } from 'react'
import { ErrorMessage } from 'formik'
import { Adjacence, TypePlancheBas } from './typeadjacence'
import { KTSVG } from '../../../../_metronic/helpers'

const SimulateurStep6: React.FC<{ editPlancher: any, deletePlancher: any, addPlancher: any, onChange: any, state: any }> = ({ editPlancher, deletePlancher, addPlancher, onChange, state }) => {

    return (
        <div className='d-flex flex-column flex-lg-row'>
            <div className='flex-column flex-lg-row-auto w-100 w-lg-400px w-xl-550px mb-10 mb-lg-0'>

                <div className='fv-row mb-10'>

                    <label className='form-label required mb-2'>Longueur plancher</label>

                    <input
                        type='number'
                        className='form-control form-control-lg form-control-solid border-dark'
                        name={`longueur_plancher`}
                        placeholder=''
                        onChange={onChange}
                        value={state.longueur_plancher}
                    />
                    <div className='text-danger mt-2'>
                        <ErrorMessage name={`longueur_plancher`} />
                    </div>
                </div>
                <div className='fv-row mb-10'>

                    <label className='form-label required mb-2'>Largeur plancher</label>

                    <input
                        type='number'
                        className='form-control form-control-lg form-control-solid border-dark'
                        name={`largeur_plancher`}
                        placeholder=''
                        onChange={onChange}
                        value={state.largeur_plancher}
                    />
                    <div className='text-danger mt-2'>
                        <ErrorMessage name={`largeur_plancher`} />
                    </div>
                </div>
                <Adjacence onChange={onChange} statevalue={state.adjacence_plancher} name="adjacence_plancher" />

                <div className='row mb-10 align-items-center '>
                    <div className='col'>
                        <label className='form-label required'>Etat d'isolation </label>
                    </div>
                    <div className='col'>
                        <input
                            type='radio'
                            className='btn-check'
                            name='isolation_plancher'
                            value='isole'
                            id='kt_etat_pb_isole'
                            onChange={onChange}

                        />
                        <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default  align-items-center'
                            htmlFor='kt_etat_pb_isole'
                        >
                            {/* <img src={homeB} alt='fireSpot' width='45' height='40' /> */}

                            <span className='d-block fw-bold text-start'>
                                <span className='text-dark fw-bolder d-block text-center'> Isolé</span>
                            </span>
                        </label>
                    </div>
                    <div className='col '>
                        <input
                            type='radio'
                            className='btn-check'
                            name='isolation_plancher'
                            value='non_isole'
                            id='kt_etat_pb_non_isole'
                            onChange={onChange}


                        />
                        <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default p-3 align-items-center '
                            htmlFor='kt_etat_pb_non_isole'
                        >
                            {/* <img src={buildingB} alt='fireSpot' width='45' height='40' /> */}

                            <span className='d-block fw-bold text-start'>
                                <span className='text-dark fw-bolder d-block text-center'>Non isolé</span>
                            </span>
                        </label>
                    </div>
                    <div className='col '>
                        <input
                            type='radio'
                            className='btn-check'
                            name='isolation_plancher'
                            value='inconnu'
                            id='kt_etat_pb_inconnu'
                            onChange={onChange}


                        />
                        <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default p-3 align-items-center '
                            htmlFor='kt_etat_pb_inconnu'
                        >
                            {/* <img src={buildingB} alt='fireSpot' width='45' height='40' /> */}

                            <span className='d-block fw-bold text-start'>
                                <span className='text-dark fw-bolder d-block text-center'>Inconnu</span>
                            </span>
                        </label>
                    </div>
                </div>
                {state.isolation_plancher == "isole" && (<>
                    <div className=' mb-10 form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                        <input
                            className='form-check-input'
                            type='checkbox'
                            //   onChange={handleChangeCheckbox}
                            value={state.effet_joule_plancher}
                            name='effet_joule_plancher'
                        // defaultChecked={true}
                        />
                        <label className='form-label fw-bold fs-4 mx-3'> Contient des cables</label>
                    </div>
                </>)}

                {state.isolation_plancher == "non_isole" && (<>

                    <TypePlancheBas onChange={onChange} statevalue={state.type_materiau_plancher} name="type_materiau_plancher" />

                </>)}

                <br />
                <button type="button" className='btn btn-lg btn-info w-100 ' onClick={addPlancher}>
                    <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                    Sauvegarder
                </button>
            </div>

            <div className='flex-lg-row-fluid ms-lg-7  w-xl-400px ms-xl-10'>
                <div className='card card-xl-stretch mb-5 mb-xl-8'>


                    <div className='card-body pt-0' style={state?.isolation_plafon == "non_isole" ? { overflowY: "scroll", height: "650px" } : { overflowY: "scroll", height: "500px" }}>

                        {state.array_plancher.length > 0 && state.array_plancher.map((plancher: any, idx: any) => (<>
                            <div className='d-flex align-items-center bg-light-info rounded p-5'>
                                <span className='svg-icon svg-icon-info me-5'>
                                    <KTSVG path='/media/icons/duotune/general/gen028.svg' className='svg-icon-1' />
                                </span>

                                <div className='flex-grow-1 me-2'>
                                    <a href='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>
                                        plancher n° {idx + 1} : {plancher.largeur_plancher && plancher.longueur_plancher && parseInt(plancher.largeur_plancher) * parseInt(plancher.longueur_plancher) + " m²"}
                                    </a>
                                    <span className=' fw-bold d-block'>isolation : {plancher.isolation_plancher}</span>
                                </div>
                                <button type="button" className='btn btn-sm btn-warning me-5' onClick={e => editPlancher(idx)}>
                                    <span className='svg-icon svg-icon-warning'>
                                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-1' />
                                    </span>
                                </button>
                                <button type="button" className='btn btn-sm btn-danger ' onClick={e => deletePlancher(idx)}>
                                    <span className='svg-icon svg-icon-danger'>
                                        <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-1' />
                                    </span></button>
                            </div>
                            <br />
                        </>
                        ))}

                    </div>
                </div>
            </div>
        </div>



    )
}

export { SimulateurStep6 }
