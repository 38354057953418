import { stringify } from "querystring"
import * as Yup from 'yup'

export const createAppSchema = [
  Yup.object().shape({

    owner_profile: Yup.string().required('ce champs est obligatoire'),
    owner_firstname: Yup.string().required('ce champs est obligatoire'),
    // revenus_value: Yup.string().required('ce champs est obligatoire'),
    // revenus_file: Yup.string().required('ce champs est obligatoire'),
  }),
  Yup.object().shape({
    tenant_profile: Yup.string().required('ce champs est obligatoire'),
    // revenus_value: Yup.string().required('ce champs est obligatoire'),
    // revenus_file: Yup.string().required('ce champs est obligatoire'),
  }),
  Yup.object().shape({
    // revenus: Yup.string().required('ce champs est obligatoire'),
    // revenus_value: Yup.string().required('ce champs est obligatoire'),
    // revenus_file: Yup.string().required('ce champs est obligatoire'),
  }),
  Yup.object().shape({
    // revenus: Yup.string().required('ce champs est obligatoire'),
    // revenus_value: Yup.string().required('ce champs est obligatoire'),
    // revenus_file: Yup.string().required('ce champs est obligatoire'),
  }),
  Yup.object().shape({
    // revenus: Yup.string().required('ce champs est obligatoire'),
    // revenus_value: Yup.string().required('ce champs est obligatoire'),
    // revenus_file: Yup.string().required('ce champs est obligatoire'),
  }),
  Yup.object().shape({
    // revenus: Yup.string().required('ce champs est obligatoire'),
    // revenus_value: Yup.string().required('ce champs est obligatoire'),
    // revenus_file: Yup.string().required('ce champs est obligatoire'),
  })
  ,
  Yup.object().shape({
    // revenus: Yup.string().required('ce champs est obligatoire'),
    // revenus_value: Yup.string().required('ce champs est obligatoire'),
    // revenus_file: Yup.string().required('ce champs est obligatoire'),
  }),
  Yup.object().shape({
    // revenus: Yup.string().required('ce champs est obligatoire'),
    // revenus_value: Yup.string().required('ce champs est obligatoire'),
    // revenus_file: Yup.string().required('ce champs est obligatoire'),
  })
  ,
  Yup.object().shape({
    // revenus: Yup.string().required('ce champs est obligatoire'),
    // revenus_value: Yup.string().required('ce champs est obligatoire'),
    // revenus_file: Yup.string().required('ce champs est obligatoire'),
  }),
  Yup.object().shape({
    // revenus: Yup.string().required('ce champs est obligatoire'),
    // revenus_value: Yup.string().required('ce champs est obligatoire'),
    // revenus_file: Yup.string().required('ce champs est obligatoire'),
  })
  ,
  Yup.object().shape({
    // revenus: Yup.string().required('ce champs est obligatoire'),
    // revenus_value: Yup.string().required('ce champs est obligatoire'),
    // revenus_file: Yup.string().required('ce champs est obligatoire'),
  })
  ,
  Yup.object().shape({
    // revenus: Yup.string().required('ce champs est obligatoire'),
    // revenus_value: Yup.string().required('ce champs est obligatoire'),
    // revenus_file: Yup.string().required('ce champs est obligatoire'),
  })
]
export interface ContractModel {
  generated_text: string
  description: string
  owner_firstname: string
  owner_lastname: string
  owner_address: string
  owner_email: string
  owner_profile: string
  owner_phone: string
  owners_array: Array<Object>

  denomination: string
  siege_social: string
  num_rcs: string
  email_representant: string
  tel_representant: string
  representant_name: string

  mandatory: boolean
  mandatory_firstname: string
  mandatory_lastname: string
  mandatory_address: string
  mandatory_profile: string
  mandatory_occupation: string
  mandatory_pro_card_number: string //if agent
  mandatory_pro_card_issue_place: string
  guarantor_name: string
  company_name: string //raison sociale

  tenant_firstname: string
  tenant_lastname: string
  tenant_email: string
  tenants_array: Array<Object>

  property_address: string
  complement_adresse: string
  batch_number: string
  property_type: string
  legal_system: string // regime juridique

  cellar: string
  cellar_batch_number: string
  living_space: string
  building_period: string
  rooms_number: string

  equipment_list: object

  cave: any
  garage: any
  parking: any
  jardin: any
  terasse: any
  usage_commun: any
  equipements: any
  autre: any

  cave_details: any
  garage_details: any
  parking_details: any
  jardin_details: any
  terasse_details: any
  usage_commun_details: any
  equipements_details: any
  autre_details: any

  heater_type: string
  heater_production_model: string
  heater_production: string
  water_type: string
  water_production: string
  water_production_model: string

  property_use: string
  equipment_it_access: string
  effective_date: string
  bail_duration: string

  tense_area: string
  monthly_amount: string
  maximum_evolution: string
  fixation_loyer: string
  increased_reference_rent: string
  reference_rent: string
  rent_supplement: boolean
  add_comp_loyer: boolean
  rent_supplement_ammount: string
  rent_supplement_proof: string

  review_date: string
  reference_date: string
  payment_method: string
  charges_amount: string
  charges_review_method: string

  owner_support: boolean
  annual_amount: string
  monthly_amount_bytenant: string
  periodicity: string
  payment_date: string
  deadline: string
  payment_place: string
  first_period_amount: string

  renewal: boolean
  amount_inc_dec: string
  app_method_inc_dec: string

  montant_charges: string
  modalite_paiement: string
  periodicite_paiement: string
  paiement_terme_echu: string
  periode_paiement: string
  location_description: string
  indice_ref_loyer: string
  date_revision_irl: string
  modalite_reglement: string
  assurance_colocataire: string
  garantie: string
  travaux_conformite: string
  tenant_profile: string
  diminution_depart: string
  annexe_acte_caution: boolean
  annexe_attestation_assurance: boolean
  annexe_autorisation_prealable: boolean
  annexe_charge_recuperable: boolean
  annexe_etat_lieux: boolean
  annexe_reparation_locative: boolean
  annnexe_etat_descriptif: boolean
  annexe_extrait_reglement: boolean
  annexe_notice_infos: boolean
  annexe_reference_loyer: boolean
  annexe_dossier_diagnostique: boolean
  economie_charges:string
  montant_economie_charges:string
  duree_participation:string
  elements_propres:string
  autre_condition:string
  depenses_energetiques:string
  annee_reference_estimation:string
  list_address:Array<Object>
}

export const inits: ContractModel = {
  list_address:[],
  depenses_energetiques:"",
  annee_reference_estimation:"",
  autre_condition:"",
  economie_charges:"",
  montant_economie_charges:"",
  duree_participation:"",
  elements_propres:"",
  annexe_acte_caution:false,
  annexe_attestation_assurance: false,
  annexe_autorisation_prealable : false,
  annexe_charge_recuperable: false,
  annexe_etat_lieux: false,
  annexe_reparation_locative: false,
  annnexe_etat_descriptif: false,
  annexe_extrait_reglement:false,
  annexe_notice_infos :false,
  annexe_reference_loyer:false,
  annexe_dossier_diagnostique:false,
  assurance_colocataire: "",
  garantie: "",
  travaux_conformite: "",
  diminution_depart: "",
  modalite_reglement: "",
  date_revision_irl: "",
  indice_ref_loyer: "",
  location_description: "",
  add_comp_loyer: false,
  description: "",
  montant_charges: "",
  modalite_paiement: "",
  periodicite_paiement: "",
  paiement_terme_echu: "",
  periode_paiement: "",
  generated_text: "",
  owner_firstname: "",
  owner_lastname: "",
  owner_address: "",
  owner_email: "",
  owner_profile: "",
  owner_phone: "",
  tenant_profile: "",
  denomination: '',
  siege_social: '',
  num_rcs: '',
  email_representant: '',
  tel_representant: '',
  representant_name: '',

  owners_array: [{
    owner_firstname: '',
    owner_lastname: '',
    owner_address: '',
    owner_email: '',
    owner_profile: '',
    owner_phone: ''
  }],
  mandatory: false,
  mandatory_firstname: "",
  mandatory_lastname: "",
  mandatory_address: "",
  mandatory_profile: "",
  mandatory_occupation: "",
  mandatory_pro_card_number: "",
  mandatory_pro_card_issue_place: "",
  guarantor_name: "",
  company_name: "",
  tenant_firstname: "",
  tenant_lastname: "",
  tenant_email: "",
  tenants_array: [{
    tenant_firstname: "",
    tenant_lastname: "",
    tenant_email: "",
    tenant_address: '',
    tenant_profile: '',
    tenant_phone: ''
  }],
  property_address: "",
  complement_adresse: "",
  batch_number: "",
  property_type: "",
  legal_system: "",
  cellar: "",
  cellar_batch_number: "",
  living_space: "",
  building_period: "",
  rooms_number: "",
  equipment_list: [],
  cave: '',
  garage: '',
  parking: '',
  jardin: '',
  terasse: '',
  usage_commun: '',
  equipements: '',
  autre: '',

  cave_details: '',
  garage_details: '',
  parking_details: '',
  jardin_details: '',
  terasse_details: '',
  usage_commun_details: '',
  equipements_details: '',
  autre_details: '',

  heater_type: "",
  heater_production: "",
  heater_production_model: "",
  water_type: "",
  water_production: "",
  water_production_model: "",

  property_use: "",
  equipment_it_access: "",

  effective_date: "",
  bail_duration: "",

  monthly_amount: "",
  maximum_evolution: "",
  tense_area: "",
  fixation_loyer: "",
  increased_reference_rent: "",
  reference_rent: "",
  rent_supplement: false,
  rent_supplement_ammount: "",
  rent_supplement_proof: "",
  review_date: "",
  reference_date: "",
  payment_method: "",
  charges_amount: "",
  charges_review_method: "",
  owner_support: false,
  annual_amount: "",
  monthly_amount_bytenant: "",
  periodicity: "",
  payment_date: "",
  deadline: "",
  payment_place: "",
  first_period_amount: "",
  renewal: false,
  amount_inc_dec: "",
  app_method_inc_dec: "",


}


