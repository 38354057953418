/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {
  MixedWidget2,
  MixedWidget10,
  MixedWidget11,
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
} from '../../../_metronic/partials/widgets'
import { Toaster } from 'react-hot-toast'
import {UserModel} from '../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../setup'

const DashboardPage: FC = (props) => (
  <>

    {/* begin::Row */}
    
    {/* end::Row */}
  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <div className='row gy-5 g-xl-8'>
      {user && user.account_goal === 2 ?
        <div className='col-xxl-12'>
            {/* <MixedWidget2
              className='card-xl-stretch mb-xl-8'
              chartColor='danger'
              chartHeight='200px'
              strokeColor='#cb1e46'
            /> */}
          <div className="alert alert-primary d-flex align-items-center p-5 mb-10">
            <span className="svg-icon svg-icon-2hx svg-icon-primary me-3">
              <img
                alt='Logo'
                className='h-55px logo'
                src={toAbsoluteUrl('/media/logos/logo-1.svg')}
              />
            </span>
            
            <div className="d-flex flex-column w-400px">
              <h5 className="mb-1">Bienvenue {user.first_name+' '+user.last_name}</h5>
              <span className='py-5'>Afin de candidater pour une location vérifiez gratuitement votre dossier par mon carnet LOGEMENT et en partenariat avec le service de l'État</span>
              <p>
                Afin de faciliter la constitution de votre dossier, préparez les pièces suivantes :
              </p>
              <ul>
                <li>Une pièce d’identité</li>
                <li>Un justificatif de domicile</li>
                <li>Un justificatif de situation professionnelle</li>
                <li>Des justificatifs de ressources</li>
                <li>Votre dernier avis d’imposition </li>
              </ul>
              <p>
                Pour vos garants ces mêmes pièces vous seront demandées. 
              </p>
              
              
            </div>
          </div>
          <div className='bg-white'>
            en partenariat avec l'État <img src='https://upload.wikimedia.org/wikipedia/fr/thumb/2/22/Republique-francaise-logo.svg/330px-Republique-francaise-logo.svg.png' width='100px' height='100px'></img>
            <a href='/tenant-file/create' className='mx-5 btn btn-primary'>Gérer mon Dossier Locataire</a>
          </div>
          
            
        </div>
        : 
          <div className='col-xxl-12'>
              {/* <MixedWidget2
                className='card-xl-stretch mb-xl-8'
                chartColor='danger'
                chartHeight='200px'
                strokeColor='#cb1e46'
              /> */}
            <div className="alert alert-primary d-flex align-items-center p-5 mb-10">
              <span className="svg-icon svg-icon-2hx svg-icon-primary me-3">
                <img
                  alt='Logo'
                  className='h-55px logo'
                  src={toAbsoluteUrl('/media/logos/logo-1.svg')}
                />
              </span>
              
              <div className="d-flex flex-column w-400px">
                <h5 className="mb-1">Bienvenue {user.first_name}</h5>
                <p>
                  Créer votre logement, invitez des candidats locataires, vérifiez leurs solvabilité et faites le bon choix avant de vous engager.
                </p>
                
                
              </div>
            </div>
            <div className='bg-white'>
              {/* en partenariat avec l'État <img src='https://upload.wikimedia.org/wikipedia/fr/thumb/2/22/Republique-francaise-logo.svg/330px-Republique-francaise-logo.svg.png' width='100px' height='100px'></img> */}
              <a href='/properties' className='mx-5 btn btn-primary'>Gérer mes logements</a>
            </div>  
          </div>
          
        }
      </div>
    </>
  )
}

export {DashboardWrapper}
