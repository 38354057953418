/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { ErrorMessage } from 'formik'
import { KTSVG } from '../../../../../_metronic/helpers'

const Etape9: React.FC<{ handleChangeCheckbox: any, onChange: any, state: any }> = ({ handleChangeCheckbox, onChange, state }) => {
  return (
    <div className='w-100' key={`etape9`}>


      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'> Modalité de règlement des charges</span>
        </label>

        <select
          name='modalite_reglement'
          className='form-select form-select-lg form-select-solid'
          onChange={onChange}
          value={state.modalite_reglement}
        >
          <option value="" disabled>choisir la modalité de règlement </option>
          <option value='reg_annuelle'>Provisions sur charges avec régularisation annuelle   </option>
          <option value='periodique_sans_provision'>Paiement périodique des charges sans provision ( Idem cas de colocation)</option>

        </select>



        <div className='text-danger mt-2'>
          <ErrorMessage name={`modalite_reglement`} />
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>Montant des charges </span>
        </label>
        <input
          type='number'
          className='form-control form-control-lg form-control-solid'
          name={`montant_charges`}
          placeholder=''
          onChange={onChange}
          value={state.montant_charges}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={`montant_charges`} />
        </div>
      </div>

      {/* <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>Modalité de révision des charges </span>
        </label>
        <input
          type='text'
          className='form-control form-control-lg form-control-solid'
          name={`modalite_revision_charges`}
          placeholder=''
          onChange={onChange}
          value={state.modalite_revision_charges}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={`modalite_revision_charges`} />
        </div>
      </div> */}






      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>Dépot de garantie (correspondant en général à 2 mois de loyer hors charges) </span>
        </label>
        <input
          type='number'
          className='form-control form-control-lg form-control-solid'
          name={`garantie`}
          placeholder=''
          onChange={onChange}
          value={state.garantie}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={`garantie`} />
        </div>
      </div>



      
<div className=' mb-10 form-check form-switch form-switch-sm form-check-custom form-check-solid'>
        <input
          className='form-check-input'
          type='checkbox'
          onChange={handleChangeCheckbox}

          value={state.economie_charges}
          name='economie_charges'
        // defaultChecked={true}
        />
        <label className='form-label fw-bold fs-4 mx-3'> Contribution pour le partage des économies de charges </label>
      </div>

      {state.economie_charges && (<>
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Montant</span>
          </label>

          <input
            type='number'
            className='form-control form-control-lg form-control-solid'
            name={`montant_economie_charges`}
            placeholder=''
            onChange={onChange}
            value={state.montant_economie_charges}
          />

          <div className='text-danger mt-2'>
            <ErrorMessage name={`montant_economie_charges`} />
          </div>
        </div>
        <div className='fv-row mb-10'>
          
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Durée de la participation du locataire restant à courir au jour de la signature du contrat</span>
          </label>

          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={`duree_participation`}
            placeholder=''
            onChange={onChange}
            value={state.duree_participation}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`duree_participation`} />
          </div>

        </div>
        <div className='fv-row mb-10'>
          
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Eléments propres à justifier les travaux réalisés donnant lieu à cette contribution</span>
          </label>

          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={`elements_propres`}
            placeholder=''
            onChange={onChange}
            value={state.elements_propres}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`elements_propres`} />
          </div>

        </div>

      </>)}

      
      
      
      
      <div className=' mb-10 form-check form-switch form-switch-sm form-check-custom form-check-solid'>
        <input
          className='form-check-input'
          type='checkbox'
          onChange={handleChangeCheckbox}

          value={state.assurance_colocataire}
          name='assurance_colocataire'
        // defaultChecked={true}
        />
        <label className='form-label fw-bold fs-4 mx-3'> Cas de colocation souscription par le bailleur d'une assurance pour le compte des colocataires </label>
      </div>

      {state.assurance_colocataire && (<>
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Montant total annuel récupérable au titre de l'assurance pour compte des colocataires 		</span>
          </label>

          <input
            type='number'
            className='form-control form-control-lg form-control-solid'
            name={`montant_total_recuperable`}
            placeholder=''
            onChange={onChange}
            value={state.montant_total_recuperable}
          />

          <div className='text-danger mt-2'>
            <ErrorMessage name={`montant_total_recuperable`} />
          </div>
        </div>
        <div className='fv-row mb-10'>
          
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Montant récupérable par douzième	</span>
          </label>

          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={`montant_recuperable_douzieme`}
            placeholder=''
            onChange={onChange}
            value={state.montant_recuperable_douzieme}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`montant_recuperable_douzieme`} />
          </div>

        </div>
      </>)}

    </div>
  )
}

export { Etape9 }
