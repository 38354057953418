/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { ErrorMessage } from 'formik'
import { KTSVG } from '../../../../../_metronic/helpers'

const Etape6: React.FC<{ onChange: any, state: any }> = ({ onChange, state }) => {
  return (
    <div className='w-100' key={`etape5`}>
      {/* <p>Durée du bail</p> */}
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>Date de prise d'effet</span>
        </label>
        <input
          type='date'
          className='form-control form-control-lg form-control-solid'
          name={`effective_date`}
          placeholder=''
          onChange={onChange}
          value={state.effective_date}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={`effective_date`} />
        </div>
      </div>



      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>Durée du contrat</span>
        </label>
        <select
          name='bail_duration'
          className='form-select form-select-lg form-select-solid'
          onChange={onChange}
          value={state.bail_duration}
        >
          <option value="" disabled>choisir  </option>
          <option value='9 mois (Etudiant)'>9 mois (Etudiant)</option>
          <option value='1 ans (meublé)'>1 ans (meublé)</option>
          <option value='3 ans (non meublé)'>3 ans (non meublé)</option>
          <option value='6 ans (Personne morale)'>6 ans (Personne morale)</option>

        </select>
        <div className='text-danger mt-2'>
          <ErrorMessage name={`bail_duration`} />
        </div>
      </div>
  
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>Periodicité de paiement</span>
        </label>
        <select
          name='periodicite_paiement'
          className='form-select form-select-lg form-select-solid'
          onChange={onChange}
          value={state.periodicite_paiement} >
          <option value="" disabled>choisir  </option>
          <option value='Mensuelle'>Mensuelle</option>
          <option value='Annuelle'>Annuelle</option> 

        </select>
        <div className='text-danger mt-2'>
          <ErrorMessage name={`periodicite_paiement`} />
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>Paiement </span>
        </label>

        <select
          name='paiement_terme_echu'
          className='form-select form-select-lg form-select-solid'
          onChange={onChange}
          value={state.paiement_terme_echu}
        >
          <option value="" disabled>choisir  </option>
          <option value='En avance'>En avance</option>
          <option value='En fin de mois'>En fin de mois</option>

        </select>
  
        <div className='text-danger mt-2'>
          <ErrorMessage name={`paiement_terme_echu`} />
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>Jour du mois ou le loyer est du*</span>
        </label>
        <input
          type='number'
          className='form-control form-control-lg form-control-solid'
          name={`periode_paiement`}
          placeholder=''
          onChange={onChange}
          value={state.periode_paiement}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={`periode_paiement`} />
        </div>
      </div>


    </div>
  )
}

export { Etape6 }
