/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
// import {KTSVG} from '../../../../../_metronic/helpers'
import { Field, ErrorMessage } from 'formik'

const Step4: React.FC<{ onChange: any, message: string, state:any }> = ({ onChange, message }) => {
    return (
        <div className='' key={`step4${message}`}>
            <div className='mb-10 fv-row'>
                <div className='fv-row'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-4'>
                        <span className=''>Vue: </span>

                    </label>
                </div>
                
                <div className='row mb-10' data-kt-buttons='true'>
                    <div className='col-xs-12 col-md-4 mb-2'>
                        <input
                            onChange={onChange}
                            type='radio'
                            className='btn-check'
                            name='vue'
                            value='1'
                            id='Vue_degagee'
                        />
                        <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                            htmlFor='Vue_degagee'
                        >
                            <span className='fw-bolder fs-7'>Dégagé</span>
                        </label>
                    </div>
       
                    <div className='col-xs-12 col-md-4 mb-2'>
                        <input
                            onChange={onChange}
                            type='radio'
                            className='btn-check'
                            name='vue'
                            value='2'
                            id='Vue_visavis'
                        />
                        <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                            htmlFor='Vue_visavis'
                        >
                            <span className='fw-bolder fs-7'>Vis à vis</span>
                        </label>
                    </div>
                    <div className='col-xs-12 col-md-4 mb-2'>
                        <input
                            onChange={onChange}
                            type='radio'
                            className='btn-check'
                            name='vue'
                            value='3'
                            id='Vue_exceptionnelle'
                        />
                        <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default p-4'
                            htmlFor='Vue_exceptionnelle'
                        >
                            <span className='fw-bolder fs-7'>Exceptionnelle</span>
                        </label>
                    </div>
                    
                </div>
                <div className='fv-row'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-4'>
                        <span className=''>Orientation: </span>

                    </label>
                </div>
                
                <div className='row mb-10' data-kt-buttons='true'>
                    <div className='col-xs-12 col-md-3 mb-2'>
                        <input
                            onChange={onChange}
                            type='radio'
                            className='btn-check'
                            name='orientation'
                            value='1'
                            id='Orientation_nord'
                        />
                        <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                            htmlFor='Orientation_nord'
                        >
                            <span className='fw-bolder fs-7'>Nord</span>
                        </label>
                    </div>
       
                    <div className='col-xs-12 col-md-3 mb-2'>
                        <input
                            onChange={onChange}
                            type='radio'
                            className='btn-check'
                            name='orientation'
                            value='2'
                            id='Orientation_sud'
                        />
                        <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                            htmlFor='Orientation_sud'
                        >
                            <span className='fw-bolder fs-7'>Sud</span>
                        </label>
                    </div>
                    <div className='col-xs-12 col-md-3 mb-2'>
                        <input
                            onChange={onChange}
                            type='radio'
                            className='btn-check'
                            name='orientation'
                            value='3'
                            id='Orientation_ouest'
                        />
                        <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                            htmlFor='Orientation_ouest'
                        >
                            <span className='fw-bolder fs-7'>Ouest</span>
                        </label>
                    </div>
                    <div className='col-xs-12 col-md-3 mb-2'>
                        <input
                            onChange={onChange}
                            type='radio'
                            className='btn-check'
                            name='orientation'
                            value='4'
                            id='Orientation_est'
                        />
                        <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                            htmlFor='Orientation_est'
                        >
                            <span className='fw-bolder fs-7'>Est</span>
                        </label>
                    </div>
                    <div className='col-xs-12 col-md-3 mb-2'>
                        <input
                            onChange={onChange}
                            type='radio'
                            className='btn-check'
                            name='orientation'
                            value='5'
                            id='Orientation_sud_est'
                        />
                        <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                            htmlFor='Orientation_sud_est'
                        >
                            <span className='fw-bolder fs-7'>Sud-Est</span>
                        </label>
                    </div>
                    <div className='col-xs-12 col-md-3 mb-2'>
                        <input
                            onChange={onChange}
                            type='radio'
                            className='btn-check'
                            name='orientation'
                            value='6'
                            id='Orientation_sud_ouest'
                        />
                        <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                            htmlFor='Orientation_sud_ouest'
                        >
                            <span className='fw-bolder fs-7'>Sud-Ouest</span>
                        </label>
                    </div>
                    
                    
                </div>
               

            </div>

            <div className='mb-10 row'>
            <div className='col mt-10'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                        <span className='required'>Numéro d'étage</span> 
                    </label>
                    <select name="num_etage" className="form-select" aria-label="Select Floor" onChange={onChange}>
                        <option></option>
                        <option value="1">RDC</option>
                        <option value="2">1</option>
                        <option value="3">2</option>
                        <option value="4">3</option>
                        <option value="5">4</option>
                        <option value="6">5</option>
                        <option value="7">6</option>
                        <option value="8">7+</option>
                    </select>
                    <div className='text-danger mt-2'>
                        <ErrorMessage name='num_etage' />
                    </div>
                </div>
                <div className='col mt-10'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                        <span className='required'>Dernier étage?</span> 
                    </label>
                    <div className="row">
                        <div className='col-xs-12 col-md-3 mb-2'>
                            <input
                                onChange={onChange}
                                type='radio'
                                className='btn-check'
                                name='dernier_etage'
                                value='1'
                                id='Dernier_etage_oui'
                            />
                            <label
                                className='btn btn-outline btn-outline-dashed btn-outline-default p-4'
                                htmlFor='Dernier_etage_oui'
                            >
                                <span className='fw-bolder fs-7'>OUI</span>
                            </label>
                        </div>
                        <div className='col-xs-12 col-md-3 mb-2 mx-5'>
                            <input
                                onChange={onChange}
                                type='radio'
                                className='btn-check'
                                name='dernier_etage'
                                value='2'
                                id='Dernier_etage_non'
                            />
                            <label
                                className='btn btn-outline btn-outline-dashed btn-outline-default  p-4'
                                htmlFor='Dernier_etage_non'
                            >
                                <span className='fw-bolder fs-7'>NON</span>
                            </label>
                        </div>
                    </div>
                    <div className='text-danger mt-2'>
                        <ErrorMessage name='dernier_etage' />
                    </div>
                </div>                

            </div>

        </div>
    )
}

export { Step4 }
