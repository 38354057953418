import { UserModel } from '../../auth/models/UserModel'


export interface TenantModel {
  identification_doc_status: any
  loader:boolean
  firstname: string
  lastname: string
  address: string
  email:string
  piece_identite: string
  piece_file: any
  document_df_url: string

  type_location: string
  cotenant_emails: any
  one_email:any
  conjoint_email: string
  // conjoint_emails_df:any

  hebergement: string
  hebergement_file: any
  hebergement_doc_url: any

  situation_pro: string
  situation_pro_file: any
  situation_pro_doc_url: any

  tax_type: any
  tax_file: any
  tax_doc_url: any
  other_tax:any

  revenus: string
  revenus_value: any
  revenus_file: any
  revenus_array_all:any
  revenus_doc_url:any
  revenus_array_all_df:any
  // garants: any
  guarantors:any
  guarantor_type:any
  guarantors_id:any
  type: any
  add_income:any
  tenants:any

  identification_files:any
  hebergement_files:any
  situation_pro_files:any
  tax_doc_files:any
  message:any
  clarification:string
  validate: boolean
  infos:string
  sg_changed:number
  revenus_doc_files:any
  hebergement_doc_status:any
  tax_doc_status:any
  situation_pro_doc_status:any
  lastUpdateDate:any
  status:any
}

export const inits: TenantModel = {
  other_tax:'',
  status:"",
  lastUpdateDate:"",
  identification_doc_status:"",
  situation_pro_doc_status:'',
  tax_doc_status:'',
  sg_changed:0,
  hebergement_doc_status:"",
  infos:"",
  validate:false,
  clarification:'',
  loader:false,
  message:'',

  identification_files:[],
  hebergement_files:[],
  situation_pro_files:[],
  tax_doc_files:[],

  guarantors:[],
  guarantor_type:'',
  guarantors_id:'',
  firstname: '',
  lastname: '',
  address: '',
  email:"",
  one_email:"",
  piece_identite: '',
  document_df_url: '',
  type_location: '',
  cotenant_emails: [],
  tenants: [],
  conjoint_email: '',
  hebergement: '',
  situation_pro: '',
  revenus: '',
  revenus_value: '',
  revenus_array_all: [] ,
  // garants: [],
  piece_file: [],
  hebergement_file: '',
  situation_pro_file: '',
  revenus_file: '',
  type: 'personne physique',
  tax_type: '',
  tax_file: '',
  add_income:true,
  
hebergement_doc_url:'',
situation_pro_doc_url:'',
tax_doc_url:'',
revenus_doc_url:'',
revenus_array_all_df:[],
revenus_doc_files:[],
}

export interface TenantFileModel {
  tenant: UserModel
  location_type: String
}
