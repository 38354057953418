import React from "react";
import StyledCard from "./Card";
import { Grid, Typography, Divider, Box } from "@material-ui/core";
import SubwayIcon from "@material-ui/icons/Subway";
import ExploreIcon from "@material-ui/icons/Explore";
import DonutSmallIcon from "@material-ui/icons/DonutSmall";
import AssessmentIcon from "@material-ui/icons/Assessment";
import WindowIcon from '@mui/icons-material/Window';
import HomeIcon from '@mui/icons-material/Home';
import img1 from "../assets/dpe.png"
import img2 from "../assets/gaz.png"

export default function SimulateurStep11() {
  return (
    <Box sx={{display: "flex", flexDirection:"column", justifyContent:"flex-start"}}>
      <p>logement extrêmement performant</p>
      <Box sx={{display: "flex", flexDirection:"row", justifyContent:"flex-start"}}>
       <Box sx={{mr:4}}> 
       <Box sx={{display: "flex", flexDirection:"row", justifyContent:"flex-start"}}>
        <Box sx={{border:2, mt:25}}>570 kWh/m²/an</Box>
        <img src={img1}/>
        </Box> 
       <p>logement extrêmement peu performant</p>

        </Box>
      
       <Box sx={{mt:3.2}}>  
       <p>* Dont émissions de gaz
à effet de serrepeu d'émissions de CO₂</p>
       <img src={img2}/> </Box>
      </Box> 

    <Grid
      align="center"
      border="1px solid #000"
      alignItems="center"
    
    >
      
     
      <Divider style={{ margin: 10 }} />
      <Grid container item xs={12} alignItems="center" justify="center">
      
      
        <Grid container item sm={12}>
     
          <Grid container item lg={12} style={{ marginBottom: 25 }}>
            <StyledCard
              primary="Isolation fenêtres "
              secondary="Remplacement des fenêtres existantes par des .fenêtres en double-vitrage peu émissif.
              "
              perc={"Uw<=1,7 W/m²°K et Sw = 0,36"}
              color="linear-gradient(60deg, rgba(94,53,177,1) 0%, rgba(3,155,229,1) 100%)"
              iconPerformance={WindowIcon}
            />
          </Grid>
          <Grid container style={{ marginBottom: 25 }} item lg={12}>
            <StyledCard
              primary="Isolation murs "
              secondary=" Mise en place d'une Isolation des murs extérieurs par l'intérieur , en construction,  ne jamais isoler un mur humide , Ne jamais ventiler la lame d'air entre l'isolant et le mur extérieur par 
              des orifices dans l'isolant donnant sur l'intérieur."
              perc={"R= 3,7 m².K/W"}
              color="linear-gradient(60deg, rgba(245,0,87,1) 0%, rgba(255,138,128,1) 100%)"
              iconPerformance={HomeIcon}
            />
          </Grid>
        </Grid>
        <Grid container item sm={12}>
          <Grid container item lg={12} style={{ marginBottom: 25 }}>
            <StyledCard
              primary="Isolation de la toiture"
              secondary="en veillant à ce que l'isolation soit continue sur toute la surface du plafond. Pour les bâtis anciens, utiliser des isolants perméables
               à la vapeur d’eau (ou capillaires).
             "
             perc={"R= 7 m².K/W"}
              color="linear-gradient(60deg, rgba(251,140,0,1) 0%, rgba(255,202,41,1) 100%)"
              iconPerformance={ExploreIcon}
            />
          </Grid>

          <Grid container item lg={12} style={{ marginBottom: 25 }}>
            <StyledCard
              primary="Isolation plancher bas "
               secondary="solation plancher en sous face Uniquement en cas de travaux de réhabilitation importants avec rénovation des sols et si la hauteur sous plafond le permet, Pour les bâtis anciens, il est nécessaire que les isolants ou matériaux isolants choisis soient au moins perméables à la vapeur,. Avant de mettre en place un nouvel isolant, supprimer l’isolant en mauvais état ou mal posé. Veiller
                à ce que l'isolation soit continue sur toute la surface du plancher."
              perc={"R= 3 m².K/W"}
              color="linear-gradient(60deg, rgba(67,160,71,1) 0%, rgba(255,235,59,1) 100%)"
              iconPerformance={SubwayIcon}
            />
          </Grid>
          <Grid container item lg={12} style={{ marginBottom: 25 }}>
            <StyledCard
              primary="Ventilation  "
               secondary="Remplacement de la chaudière par une PAC air/eau L'installation d'une pompe à chaleur nécessite un bon niveau d'isolation du bâtiment. Adapter les radiateurs (chaleur douce) pour que le coefficient de performance soit optimum. Mettre en place et entretenir l'installation à l'aide d'un professionnel qualifié
              
              "
              percentage={78.8}
              color="linear-gradient(60deg, rgba(67,160,71,1) 0%, rgba(255,235,59,1) 100%)"
              iconPerformance={SubwayIcon}
            />
          </Grid>
          <Grid container item lg={12} style={{ marginBottom: 25 }}>
            <StyledCard
              primary="Chauffage et ECS "
               secondary="Remplacement de la chaudière par une PAC 
               air/eau L'installation d'une pompe à chaleur 
              "
              percentage={78.8}
              color="linear-gradient(60deg, rgba(67,160,71,1) 0%, rgba(255,235,59,1) 100%)"
              iconPerformance={SubwayIcon}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    </Box>
  )
}
