import axios from 'axios'
import {TENANT_ASSIGNEMENTS,MANAGE_ASSIGNEMENTS,TENANT_DELETE_ASSIGNEMENT
} from '../../../app/routes'
export async function gererCandidature(obj: any) {
   return await axios.post(MANAGE_ASSIGNEMENTS, obj)
}
 export async function deleteAssignement(obj:any) {
    return await axios.delete(TENANT_DELETE_ASSIGNEMENT,{data:obj})
 }

export async function getTenantAssignements() {
    // console.log('GET_PROFILE_URL :::',GET_PROFILE_URL)
    return await axios.get(TENANT_ASSIGNEMENTS)
}
