import React, {FC} from 'react'
import EtiquetteEnA from '../../../../imgs/EtiquetteEnA.png'
import EtiquetteEnF from '../../../../imgs/EtiquetteEnF.png'
import {Link} from 'react-router-dom'

const Step4: FC = () => {
  return (
    <div className='w-100'>
        <h1 className='fw-bolder text-dark text-center mb-20'>Estimation des étiquettes énergie et climat de votre Logement :</h1>
        <div className='row '>
      
        <div className='col'>
          <h6 className='fw-bolder text-dark  mb-8 '> ETIQUETTE ENERGIE (kWhep/m²/an)</h6>
         <p className='fw-bolder text-dark  mb-4'> L'estimation de la consommation énergetique de votre logement est égale:  </p>
        </div>
        <div className='col '>
          <h6 className='fw-bolder text-dark mb-8  '> ETIQUETTE CLIMAT (kgeqCO₂/m²/an)</h6>
          <p className='fw-bolder text-dark  mb-4'> L'estimation de la consommation climat de votre logement est égale:  </p>
          
        </div>
       
        </div>

    </div>
  )
}

export {Step4}
