/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState, useEffect } from 'react'
import { PageTitle } from '../../_metronic/layout/core'
import { ListProperties } from '../modules/properties/ListProperties'
// import { PropertyModel, initsProperty } from '../modules/properties/models/PropertyModel'
// import { getPropertiesList } from '../modules/properties/propertiesController'

const PropertiesPage: FC = () => {
  // const [StateProperty, setStateProperty] = useState<PropertyModel>(initsProperty)


  // useEffect(() => {

  //   getPropertiesList().then(response => {
  //     console.log(response.data)
  //     setStateProperty(prevValues => ({
  //       ...prevValues,
  //       properties_list: response.data
  //     }))

  //   })



  // }, [])

  // const handleChange = (event: any) => {
  //   console.log("handleChange")

  //   setStateProperty(prevValues => ({
  //     ...prevValues,
  //     [event.target.name]: event.target.value,
  //   }))
  // }


  return (
    <>
      <PageTitle breadcrumbs={[]}>Mes Logements</PageTitle>
      <ListProperties className='mb-5 mb-xl-8' />
        {/* properties={StateProperty.properties_list} state={StateProperty} onchange={handleChange} setStateProperty={setStateProperty} */}
    </>
  )
}

export { PropertiesPage }
