import * as Yup from 'yup'

export const createAccountSchemas = [
  Yup.object({
    // accountType: Yup.string().required().label('Account Type'),
  }),
  Yup.object({
    // accountName: Yup.string().required().label('Account Name'),
  }),
  Yup.object({
    // accountType: Yup.string().required().label('Account Type'),
  }),
  Yup.object({
    // accountName: Yup.string().required().label('Account Name'),
  }),
  Yup.object({
    // accountType: Yup.string().required().label('Account Type'),
  }),
  Yup.object({
    // accountName: Yup.string().required().label('Account Name'),
  }),
  Yup.object({
    // accountType: Yup.string().required().label('Account Type'),
  }),
  Yup.object({
    // accountName: Yup.string().required().label('Account Name'),
  }),
]
export interface Mur {
  largeur_mur: any
  longueur_mur: any
  etat_isolation: any
  effet_joule: any
  epaisseur_mur: any
  materiau_mur: any
  adjacence_mur: any
  isolation_adjacence_mur: any
  orientation_mur: any
}
 export interface DeperditionMur {
  departement: any
  periode_construction: any
  longeur_mur: any
  largeur_mur: any
  isolation_mur: any
  materiaux_structure_mur: any
  epaisseur_structure: any
  id_adjacence: any
  id_orientation: any
  isolation_adjacence: any
  effet_joule: any
}
export const initialMurState: DeperditionMur = {
  departement: 0,
  periode_construction: 0,
  longeur_mur: 0,
  largeur_mur: 0,
  isolation_mur: 0,
  materiaux_structure_mur: 0,
  epaisseur_structure: 0,
  id_adjacence: 0,
  id_orientation: 0,
  isolation_adjacence: 0,
  effet_joule: 0,
} 
export interface Plafond {
  largeur_plafon: any
  longueur_plafon: any
  isolation_plafon: any
  effet_joule_plafon: any
  type_toiture_plafon: any
  type_materiau_plafon: any
  adjacence_plafon: any
  isolation_adjacence_plafon: any
  orientation_plafon: any
}
export interface Plancher {
  largeur_plancher: any
  longueur_plancher: any
  isolation_plancher: any
  effet_joule_plancher: any
  type_materiau_plancher: any
  adjacence_plancher: any
 
}
export interface Fenetre {
  largeur_fenetre: any
  longueur_fenetre: any
  type_vitrage: any
  epaisseur_lame: any
  vitrage_vir: any
  inclinaison_vitrage: any
  type_gaz_lame: any
  type_baie: any
  type_materiau_fenetre: any
  type_fermeture: any
  presence_retour_isolation_fenetre: any
  type_pose_fenetre: any
  largeur_dormant_fenetre: any
}

export interface Porte {
  largeur_porte: any
  longueur_porte: any
  type_materiau_porte: any
  presence_retour_isolation_porte: any
  type_pose_porte: any
  largeur_dormant_porte: any
}
export interface ECS {
  type_generateur_ecs: any
  volume_ballon: any
  type_installation: any
  configuration_logement: any
  type_reseau_collectif: any
  type_generateur_ch: any
}
export interface Ventilation {
  type_ventilation: any
  several_facade: any
  joint_menuiserie: any
}

export interface SimulateurDpeModel {
  sum_deperdition_mur : Number
  rue: any
  code_postal: any
  departement: any
  type_logement: any
  nbre_etage: any,
  emplacement_logement: any
  forme_de_bien: any
  surface_habitable: any
  hauteur_sous_plafond: any
  nbre_piece: any
  periode_construction_logement: any
  ////////////////////////////////////
  selected_index: any
  largeur_mur: any
  longueur_mur: any
  etat_isolation: any
  effet_joule: any
  epaisseur_mur: any
  materiau_mur: any
  adjacence_mur: any
  isolation_adjacence_mur: any
  orientation_mur: any
  ////////////////////////////////////

  selected_index_plafond: any
  largeur_plafon: any
  longueur_plafon: any
  isolation_plafon: any
  effet_joule_plafon: any
  type_toiture_plafon: any
  type_materiau_plafon: any
  adjacence_plafon: any
  isolation_adjacence_plafon: any
  orientation_plafon: any
  ////////////////////////////////////
  selected_index_plancher: any
  largeur_plancher: any
  longueur_plancher: any
  isolation_plancher: any
  effet_joule_plancher: any
  type_materiau_plancher: any
  adjacence_plancher: any
  ////////////////////////////////////
  selected_index_fenetre: any
  largeur_fenetre: any
  longueur_fenetre: any
  type_vitrage: any
  epaisseur_lame: any
  vitrage_vir: any
  inclinaison_vitrage: any
  type_gaz_lame: any
  type_baie: any
  type_materiau_fenetre: any
  type_fermeture: any
  presence_retour_isolation_fenetre: any
  type_pose_fenetre: any
  largeur_dormant_fenetre: any
  ////////////////////////////////////
    selected_index_porte: any
    largeur_porte: any
    longueur_porte: any
    type_materiau_porte: any
    presence_retour_isolation_porte: any
    type_pose_porte: any
    largeur_dormant_porte: any
  ////////////////////////////////////
  selected_index_ecs: any
  type_generateur_ecs: any
  volume_ballon: any
  type_installation: any
  configuration_logement: any
  type_reseau_collectif: any
  type_generateur_ch: any
  ////////////////
  type_ventilation: any
  several_facade: any
  joint_menuiserie: any



  // ====================
  array_Murs: Array<Mur>
  array_plafond: Array<Plafond>
  array_plancher: Array<Plancher>
  array_portes: Array<Porte>
  array_fenetres: Array<Fenetre>
  array_ecs: Array<ECS>
}

export const inits: SimulateurDpeModel = {
  sum_deperdition_mur : 0,
  rue:null,
  code_postal: null,
  departement: null,
  type_logement: null,
  nbre_etage: null,
  emplacement_logement: null,
  forme_de_bien: null,
  surface_habitable: null,
  hauteur_sous_plafond: null,
  nbre_piece: null,
  periode_construction_logement: null,

  ////////////////////////////////////
  selected_index: 0,
  largeur_mur: null,
  longueur_mur: null,
  etat_isolation: null,
  effet_joule: null,
  epaisseur_mur: null,
  materiau_mur: null,
  adjacence_mur: null,
  isolation_adjacence_mur: null,
  orientation_mur: null,
  ////////////////////////////////////

  selected_index_plafond: 0,
  largeur_plafon: null,
  longueur_plafon: null,
  isolation_plafon: null,
  effet_joule_plafon: null,
  type_toiture_plafon: null,
  type_materiau_plafon: null,
  adjacence_plafon: null,
  isolation_adjacence_plafon: null,
  orientation_plafon: null,
  ////////////////////////////////////
  selected_index_plancher: 0,
  largeur_plancher: null,
  longueur_plancher: null,
  isolation_plancher: null,
  effet_joule_plancher: null,
  type_materiau_plancher: null,
  adjacence_plancher: null,
  ////////////////////////////////////
  selected_index_fenetre: 0,
  largeur_fenetre: null,
  longueur_fenetre: null,
  type_vitrage: null,
  epaisseur_lame: null,
  vitrage_vir: null,
  inclinaison_vitrage: null,
  type_gaz_lame: null,
  type_baie: null,
  type_materiau_fenetre: null,
  type_fermeture: null,
  presence_retour_isolation_fenetre: null,
  type_pose_fenetre: null,
  largeur_dormant_fenetre: null,
  ////////////////////////////////////
    selected_index_porte: 0,
    largeur_porte: null,
    longueur_porte: null,
    type_materiau_porte: null,
    presence_retour_isolation_porte: null,
    type_pose_porte: null,
    largeur_dormant_porte: null,
  ////////////////////////////////////
  selected_index_ecs: 0,
  type_generateur_ecs: null,
  volume_ballon: null,
  type_installation: null,
  configuration_logement: null,
  type_reseau_collectif: null,
  type_generateur_ch: null,
  ////////////////
  type_ventilation: null,
  several_facade: null,
  joint_menuiserie: null,
  ////////////////////////////////////
  array_Murs: [],
  array_plafond: [],
  array_plancher: [],
  array_portes: [],
  array_fenetres: [],
  array_ecs: [],
}
