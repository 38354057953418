/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useRef, useState, useCallback } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Formik, Form, FormikValues, useFormik, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { StepperComponent } from '../../../_metronic/assets/ts/components'
import { Step1 } from './components/Step1'
import { Step2 } from './components/Step2'
import { Step3 } from './components/Step3'
import { Step4 } from './components/Step4'
import { Step5 } from './components/Step5'
import { Step6 } from './components/Step6'
import { Step7 } from './components/Step7'
import { EstimationModel, inits  } from './models/EstimationModel'
import { estimation } from './controller/ControllerEstimation'

async function getAddressInfo (address : string){
    let response = await fetch('https://api-adresse.data.gouv.fr/search/?q='+address+'&limit=5')
    
    let data = await response.json()
    return data.features;
    // return await axios.get('https://api-adresse.data.gouv.fr/search/?q='+address+'&limit=15',{})
}

const WizardEstimation: FC = () => {
    const createAppSchema = [
    Yup.object().shape({
        address: Yup.string().required('ce champs est obligatoire')
    }),
    Yup.object().shape({
        type_bien: Yup.string()
            .test(
                "PositiveNumber",
                "Spécifiez le type du logement",
                (val: any) => {
                    if (val === "1" || val === "2" ) {
                        return true
                    } else {
                        return false;
                    }
                })
            .required('ce champs est obligatoire'),
        surface: Yup.string()
            .test(
                "PositiveNumber",
                "Vérifiez le format de la surface",
                (val: any) => {
                    if (val > 8) {
                        return true
                    } else {
                        return false;
                    }
                })
            .required('ce champs est obligatoire'),
        date_built:  Yup.string().matches(/^[0-9]+$/, 'Vérifiez le format')
        // integer().positive('Vérifiez le format').nullable(true)
        //     .test(
        //         "PositiveNumber",
        //         "Vérifiez le format",
        //         (val: any) => {
        //             if (val > 1800) {
        //                 return true
        //             } return false
        //         })
        
        ,
        nb_pieces: Yup.string()
            .matches(/^[0-9]+$/, 'Vérifiez le format')
            .required('ce champs est obligatoire'),
        nb_chambres: Yup.string()
            .matches(/^[0-9]+$/, 'Vérifiez le format'),
        nb_sdb: Yup.string()
            .matches(/^[0-9]+$/, 'Vérifiez le format'),
        nb_sde: Yup.string()
            .matches(/^[0-9]+$/, 'Vérifiez le format')   
    }),
    Yup.object().shape({
        etat_bien: Yup.string()
        .matches(/^[1-3]+$/, 'Sélectionner l\'état du logement')
        // situation_pro: Yup.string().required('ce champs est obligatoire'),
        // situation_pro_file: Yup.string().required('ce champs est obligatoire'),
    }),
    Yup.object().shape({
        num_etage: Yup.string()
            .matches(/^[1-8]+$/, 'sélectionner le numéro d\'étage')
            .required('ce champs est obligatoire'),
        dernier_etage: Yup.string()
            .matches(/^[1-2]+$/, 'ce champs est obligatoire ')
            .required('ce champs est obligatoire'),
        // revenus_value: Yup.string().required('ce champs est obligatoire'),
        // revenus_file: Yup.string().required('ce champs est obligatoire'),
    }),
    Yup.object().shape({
        // revenus: Yup.string().required('ce champs est obligatoire'),
        // revenus_value: Yup.string().required('ce champs est obligatoire'),
        // revenus_file: Yup.string().required('ce champs est obligatoire'),
    }),
    Yup.object().shape({
        firstname: Yup.string()
            .matches(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/, 'Vérifiez le format')
            .required('ce champs est obligatoire'),
        lastname: Yup.string()
            .matches(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/, 'Vérifiez le format')
            .required('ce champs est obligatoire'),
        email: Yup.string()
            .email('Mauvais format d\'e-mail')
            .min(3, 'Au moins 3 symboles')
            .max(50, '50 symboles maximum')
            .required('ce champs est obligatoire'),
        telephone: Yup.string()
            .matches(/^\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){0,13}\d$/, 'Vérifiez le format')
            .required('ce champs est obligatoire'),
        // revenus: Yup.string().required('ce champs est obligatoire'),
        // revenus_value: Yup.string().required('ce champs est obligatoire'),
        // revenus_file: Yup.string().required('ce champs est obligatoire'),
    }),
    Yup.object().shape({
        // revenus: Yup.string().required('ce champs est obligatoire'),
        // revenus_value: Yup.string().required('ce champs est obligatoire'),
        // revenus_file: Yup.string().required('ce champs est obligatoire'),
    })
]
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)
    const [currentSchema, setCurrentSchema] = useState(createAppSchema[0])
    const [initValues, setinitValues] = useState<EstimationModel>(inits)
    const [showResult, setShowResult] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [estimationResult, setEstimationResult] = useState(null)
    const [isLoadingAddress, setIsLoadingAddress] = useState(false)
    const [choicesAddress, setChoicesAddress] = useState([])
    const [isSelectedAddress, setIsSelectedAddress] = useState(false)
    const [selectedAdr, setSelectedAdr] = useState({})
    const selectAddress = (selection:any) => {
        
        // console.log('appel fct selectAddress après click', selection.label)
        // console.log('isSelectedAddress', isSelectedAddress)
        setSelectedAdr(selection.properties.label)
        // sample of fields
        //     "label": "14 Rue Lecourbe 75015 Paris",
        //     "score": 0.8956636363636363,
        //     "housenumber": "14",
        //     "id": "75115_5456_00014",
        //     "name": "14 Rue Lecourbe",
        //     "postcode": "75015",
        //     "citycode": "75115",
        //     "x": 649344.5,
        //     "y": 6860750.37,
        //     "city": "Paris",
        //     "district": "Paris 15e Arrondissement",
        //     "context": "75, Paris, Île-de-France",
        //     "type": "housenumber",
        //     "importance": 0.8523,
        //     "street": "Rue Lecourbe"
        
        if(selection && selectedAdr!== selection.properties.label){
            
            
            // console.log(selection.label)
            setTimeout(() => {
                // setTimeout(() => {
                //     setIsSelectedAddress(false)
                // }, 50)
                setinitValues(prevValues => ({
                    ...prevValues,
                    // we use the name to tell Formik which key of `values` to update
                    ['address']: selection.properties.label,
                    ['detail_address']: selection,
                }))
            }, 100)
        }  
        setTimeout(() => {
            setIsSelectedAddress(false)
        }, 50)
    }
    
    const onClickAtout = (event: any) => {
        setinitValues(prevValues => ({
            ...prevValues,
            // we use the name to tell Formik which key of `values` to update
            [event.target.name]: event.target.value
        }))
    }

    const loadStepper = () => {
        stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
    }

    const prevStep = () => {  
        window.scrollTo(0, 0)    
        if (!stepper.current) {
            return
        }
        stepper.current.goPrev()
        setCurrentSchema(createAppSchema[stepper.current.currentStepIndex -1])        
    }


    const submitStep = (values: EstimationModel, actions: FormikValues) => {
        window.scrollTo(0, 0)
        if (!stepper.current) {
            return
        }
        
        // setCurrentSchema(createAppSchema[stepper.current.currentStepIndex +1])
        
        // if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
        if (stepper.current.currentStepIndex <6) {
            stepper.current.goNext()
            setCurrentSchema(createAppSchema[stepper.current.currentStepIndex-1])
        } else {
            stepper.current.goNext()
            setIsLoading(true)
            estimation(
                initValues.address,
                initValues.detail_address,
                initValues.classe_dpe,
                initValues.classe_ges,
                initValues.nb_pieces,
                initValues.surface,
                initValues.type_bien,
                initValues.valeur_dpe,
                initValues.valeur_ges,
                initValues.date_built,
                initValues.type_chauffage,
                initValues.date_realisation_dpe,
                initValues.chauffage?1:0,
                initValues.cheminee?1:0,
                initValues.climatisation?1:0,
                initValues.nb_chambres,
                initValues.nb_sdb,
                initValues.nb_sde,
                initValues.nb_wc,
                initValues.dernier_etage?1:0,
                initValues.num_etage,
                initValues.ascenseur?1:0,
                initValues.orientation,
                initValues.etat_bien,
                initValues.vue,
                initValues.parkingExt?1:0,
                initValues.parkingInt?1:0,
                initValues.garage?1:0,
                initValues.balcon?1:0,
                initValues.terrasse?1:0,
                initValues.veranda?1:0,
                initValues.jardin?1:0,
                initValues.piscine?1:0,
                initValues.cave?1:0,
                initValues.localPoubelle?1:0,
                initValues.box?1:0,
                initValues.surface_terrain,
                initValues.calme?1:0,
                initValues.bruyant?1:0,
            )
            .then(response => {
                if(response.status ===200){
                    setTimeout(() => {
                        setEstimationResult(response.data)
                        setShowResult(true)
                    }, 50)
                    
                } else {
                    console.log('error d\'estimation')
                    setShowResult(true)
                }
            })
            .catch(error =>{
                setShowResult(true)
                console.log(error)
            })
        
        }
    }

    useEffect(() => {
        if (!stepperRef.current) {
            return
        }

        loadStepper()
    }, [stepperRef])

    // 
    const handleChange = (event: any, values: EstimationModel) => {
        if(event.target.name === 'type_bien' && event.target.value === 1 && event.target.value === 3){
            setinitValues(prevValues => ({
                ...prevValues,
                // we use the name to tell Formik which key of `values` to update
                [event.target.name]: parseInt(event.target.value)
            }))
        } else {
            setinitValues(prevValues => ({
                ...prevValues,
                // we use the name to tell Formik which key of `values` to update
                [event.target.name]: event.target.value
            }))
        }
        
        

        if(event.target.name === 'address' && event.target.value.length >3){
            setIsLoadingAddress(true)
            getAddressInfo(event.target.value).then(res => {
                // console.log(res)
                setChoicesAddress(res)
            })
        }
        setTimeout(() => {
            setIsLoadingAddress(false)
            setIsSelectedAddress(true)
        }, 70)
        


    }
    const handleClick = (event: any, values: EstimationModel) => {
        if(event.target.name === 'piscine')
            setinitValues(prevValues => ({
                ...prevValues,
                // we use the name to tell Formik which key of `values` to update
                [event.target.name]: !initValues.piscine
            }))
        if(event.target.name === 'garage')
            setinitValues(prevValues => ({
                ...prevValues,
                // we use the name to tell Formik which key of `values` to update
                [event.target.name]: !initValues.garage
            }))
        if(event.target.name === 'parkingExt')
            setinitValues(prevValues => ({
                ...prevValues,
                // we use the name to tell Formik which key of `values` to update
                [event.target.name]: !initValues.parkingExt
            }))
        if(event.target.name === 'parkingInt')
            setinitValues(prevValues => ({
                ...prevValues,
                // we use the name to tell Formik which key of `values` to update
                [event.target.name]: !initValues.parkingInt
            }))
        if(event.target.name === 'ascenseur')
            setinitValues(prevValues => ({
                ...prevValues,
                // we use the name to tell Formik which key of `values` to update
                [event.target.name]: !initValues.ascenseur
            }))
        if(event.target.name === 'cheminee')
            setinitValues(prevValues => ({
                ...prevValues,
                // we use the name to tell Formik which key of `values` to update
                [event.target.name]: !initValues.cheminee
            }))
        if(event.target.name === 'jardin')
            setinitValues(prevValues => ({
                ...prevValues,
                // we use the name to tell Formik which key of `values` to update
                [event.target.name]: !initValues.jardin
            }))
        if(event.target.name === 'terrasse')
            setinitValues(prevValues => ({
                ...prevValues,
                // we use the name to tell Formik which key of `values` to update
                [event.target.name]: !initValues.terrasse
            }))
        if(event.target.name === 'balcon')
            setinitValues(prevValues => ({
                ...prevValues,
                // we use the name to tell Formik which key of `values` to update
                [event.target.name]: !initValues.balcon
            }))
        if(event.target.name === 'box')
            setinitValues(prevValues => ({
                ...prevValues,
                // we use the name to tell Formik which key of `values` to update
                [event.target.name]: !initValues.box
            }))
        if(event.target.name === 'cave')
            setinitValues(prevValues => ({
                ...prevValues,
                // we use the name to tell Formik which key of `values` to update
                [event.target.name]: !initValues.cave
            }))
        if(event.target.name === 'localPoubelle')
            setinitValues(prevValues => ({
                ...prevValues,
                // we use the name to tell Formik which key of `values` to update
                [event.target.name]: !initValues.localPoubelle
            }))
        if(event.target.name === 'calme')
            setinitValues(prevValues => ({
                ...prevValues,
                // we use the name to tell Formik which key of `values` to update
                [event.target.name]: !initValues.calme
            }))
        if(event.target.name === 'bruyant')
            setinitValues(prevValues => ({
                ...prevValues,
                // we use the name to tell Formik which key of `values` to update
                [event.target.name]: !initValues.bruyant
            }))
        if(event.target.name === 'climatisation')
            setinitValues(prevValues => ({
                ...prevValues,
                // we use the name to tell Formik which key of `values` to update
                [event.target.name]: !initValues.climatisation
            }))
        if(event.target.name === 'chauffage')
            setinitValues(prevValues => ({
                ...prevValues,
                // we use the name to tell Formik which key of `values` to update
                [event.target.name]: !initValues.chauffage
            }))
        setTimeout(() => {
            setIsLoadingAddress(false)
            setIsSelectedAddress(true)
        }, 70)
        


    }

    return (
        <div
            ref={stepperRef}
            className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
            id='kt_create_tenantfile_stepper'
        >
            <div className='d-flex flex-row-fluid flex-center bg-white rounded  mr-10'>
                <Formik enableReinitialize validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
                    {() => (
                        <Form 
                            className= {!showResult? 'py-20 px-9':'py-5 px-5'} 
                            noValidate id='kt_modal_create_app_form'>
                            {/* step1 */}
                            <div className='current' data-kt-stepper-element='content'>

                                <Step1  
                                    message='' 
                                    onChange={handleChange} 
                                    state={initValues} 
                                    loading={isLoadingAddress} 
                                    choicesAddress={choicesAddress} 
                                    isSelectedAddress={isSelectedAddress}
                                    selectAddress={selectAddress}
                                />
                            </div>
                            {/* step2 */}
                            <div data-kt-stepper-element='content'>

                                <Step2 
                                    message='' 
                                    onChange={handleChange} 
                                    state={initValues}
                                />
                            </div>

                            {/* step3 */}
                            <div data-kt-stepper-element='content'>

                                <Step3 message='' onChange={handleChange} state={initValues}/>

                            </div>

                            {/* step4 */}
                            <div data-kt-stepper-element='content'>

                                <Step4 message='' onChange={handleChange} state={initValues}/>
                            </div>

                            {/* step5 */}
                            <div data-kt-stepper-element='content'>

                                <Step5 message='' onChange={handleChange} onClick={handleClick} state={initValues} />
                            </div>

                            {/* step6 */}
                            <div data-kt-stepper-element='content'>
                                <Step6 onChange={handleChange} state={initValues}/>
                            </div>
                            
                            {/* step7  résultat*/}
                            <div data-kt-stepper-element='content'>
                                <Step7 onChange={handleChange} state={initValues} result={estimationResult} showResult={showResult}/>
                            </div>

                            {/* btn */}

                            {!showResult && !isLoading && stepper && stepper.current && stepper.current.currentStepIndex <7 && (
                            <div className='d-flex flex-stack pt-10'>
                                <div className='mr-2'>
                                    <button
                                        onClick={prevStep}
                                        type='button'
                                        className='btn btn-lg btn-light-primary me-3'
                                        data-kt-stepper-action='previous'
                                    >
                                        <KTSVG
                                            path='/media/icons/duotune/arrows/arr063.svg'
                                            className='svg-icon-4 me-1'
                                        />
                                        Retour
                                    </button>
                                </div>

                                <div>
                                    {stepper.current?.currentStepIndex -1 !==
                                                stepper.current?.totatStepsNumber! - 1 &&
                                    <button 
                                        type='submit'
                                         className='btn btn-lg btn-primary me-3'>
                                            
                                        <span className='indicator-label'>
                                        {/* {stepper.current?.currentStepIndex-1 !==
                                                stepper.current?.totatStepsNumber! - 1 && 'Continue'} */}
                                            {stepper.current?.currentStepIndex >= 6 ? 'Voir le résultat': 'Continue'}
                                            <KTSVG
                                                path='/media/icons/duotune/arrows/arr064.svg'
                                                className='svg-icon-3 ms-2 me-0'
                                            />
                                        </span>
                                    </button>
                                    }
                                    
                                </div>
                            </div>
                            )}
                        </Form>
                    )}
                </Formik>
            </div>
            
            {(!showResult && !isLoading) && stepper?.current?.currentStepIndex ! -1 !== 5  && 

            <div className='d-flex justify-content-center bg-white rounded justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px'>
                <div className="px-6 px-lg-10 px-xxl-15 py-10">
                    <div className='stepper-nav'>
                        <div className='stepper-item current' data-kt-stepper-element='nav'>
                            <div className='stepper-line w-40px'></div>

                            <div className='stepper-icon w-40px h-40px'>
                                <i className='stepper-check fas fa-check'></i>
                                <span className='stepper-number'>1</span>
                            </div>

                            <div className='stepper-label'>
                                <h3 className='stepper-title'>Adresse du logement</h3>

                                {/* <div className='stepper-desc'>Name your App</div> */}
                            </div>
                        </div>

                        <div className='stepper-item' data-kt-stepper-element='nav'>
                            <div className='stepper-line w-40px'></div>

                            <div className='stepper-icon w-40px h-40px'>
                                <i className='stepper-check fas fa-check'></i>
                                <span className='stepper-number'>2</span>
                            </div>

                            <div className='stepper-label'>
                                <h3 className='stepper-title'>Type, Surface...</h3>

                                {/* <div className='stepper-desc'>Define your app framework</div> */}
                            </div>
                        </div>

                        <div className='stepper-item' data-kt-stepper-element='nav'>
                            <div className='stepper-line w-40px'></div>

                            <div className='stepper-icon w-40px h-40px'>
                                <i className='stepper-check fas fa-check'></i>
                                <span className='stepper-number'>3</span>
                            </div>

                            <div className='stepper-label'>
                                <h3 className='stepper-title'>Etat, Détail énergétique</h3>

                            </div>
                        </div>
                        <div className='stepper-item' data-kt-stepper-element='nav'>
                            <div className='stepper-line w-40px'></div>

                            <div className='stepper-icon w-40px h-40px'>
                                <i className='stepper-check fas fa-check'></i>
                                <span className='stepper-number'>4</span>
                            </div>

                            <div className='stepper-label'>
                                <h3 className='stepper-title'>Vue, Etage...</h3>

                            </div>
                        </div>
                        <div className='stepper-item' data-kt-stepper-element='nav'>
                            <div className='stepper-line w-40px'></div>

                            <div className='stepper-icon w-40px h-40px'>
                                <i className='stepper-check fas fa-check'></i>
                                <span className='stepper-number'>5</span>
                            </div>

                            <div className='stepper-label'>
                                <h3 className='stepper-title'>Atouts</h3>

                                {/* <div className='stepper-desc'></div> */}
                            </div>
                        </div>

                        <div className='stepper-item' data-kt-stepper-element='nav'>
                            <div className='stepper-line w-40px'></div>

                            <div className='stepper-icon w-40px h-40px'>
                                <i className='stepper-check fas fa-check'></i>
                                <span className='stepper-number'>6</span>
                            </div>

                            <div className='stepper-label'>
                                <h3 className='stepper-title'>Vos Coordonnées</h3>

                                <div className='stepper-desc'></div>
                            </div>
                        </div>
                        <div className='stepper-item' data-kt-stepper-element='nav'>
                            <div className='stepper-line w-40px'></div>

                            <div className='stepper-icon w-40px h-40px'>
                                <i className='stepper-check fas fa-check'></i>
                                <span className='stepper-number'>7</span>
                            </div>

                            <div className='stepper-label'>
                                <h3 className='stepper-title'>Votre estimation</h3>

                                <div className='stepper-desc'>Rapport d'estimation et d'anlayse</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}

export default WizardEstimation
