import React, {FC, useState} from 'react'
import {ErrorMessage} from 'formik'
import {KTSVG} from '../../../../_metronic/helpers'

import {Adjacence, Orientation} from './typeadjacence'

const SimulateurStep2: React.FC<{
  editWall: any
  deleteWall: any
  addWall: any
  onChange: any
  state: any
}> = ({editWall, deleteWall, addWall, onChange, state}) => {
  return (
    <>
      <div className='d-flex flex-column flex-lg-row'>
        <div className='flex-column flex-lg-row-auto w-100 w-lg-400px w-xl-550px mb-10 mb-lg-0'>
          <div className='fv-row mb-10'>
            <label className='form-label required mb-2'>Longueur mur</label>

            <input
              type='number'
              className='form-control form-control-lg form-control-solid border-dark'
              name={`longueur_mur`}
              placeholder=''
              onChange={onChange}
              value={state.longueur_mur}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`longueur_mur`} />
            </div>
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label required mb-2'>Largeur mur</label>

            <input
              type='number'
              className='form-control form-control-lg form-control-solid border-dark'
              name={`largeur_mur`}
              placeholder=''
              onChange={onChange}
              value={state.largeur_mur}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`largeur_mur`} />
            </div>
          </div>

          <Orientation
            onChange={onChange}
            statevalue={state.orientation_mur}
            name={`orientation_mur`}
          />

          <div className='row mb-10 align-items-center '>
            <div className='col'>
              <label className='form-label required'>Etat d'isolation </label>
            </div>
            <div className='col'>
              <input
                type='radio'
                className='btn-check'
                name='etat_isolation'
                value='1'
                id='kt_etat_isole'
                onChange={onChange}
              />
              <label
                className='btn btn-outline btn-outline-dashed btn-outline-default  align-items-center'
                htmlFor='kt_etat_isole'
              >
                <span className='d-block fw-bold text-start'>
                  <span className='text-dark fw-bolder d-block text-center'> Isolé</span>
                </span>
              </label>
            </div>

            <div className='col '>
              <input
                type='radio'
                className='btn-check'
                name='etat_isolation'
                value='0'
                id='kt_etat_non_isole'
                onChange={onChange}
              />
              <label
                className='btn btn-outline btn-outline-dashed btn-outline-default p-3 align-items-center '
                htmlFor='kt_etat_non_isole'
              >
                <span className='d-block fw-bold text-start'>
                  <span className='text-dark fw-bolder d-block text-center'>Non isolé</span>
                </span>
              </label>
            </div>
            <div className='col '>
              <input
                type='radio'
                className='btn-check'
                name='etat_isolation'
                value='3'
                id='kt_etat_inconnu'
                onChange={onChange}
              />
              <label
                className='btn btn-outline btn-outline-dashed btn-outline-default p-3 align-items-center '
                htmlFor='kt_etat_inconnu'
              >
                {/* <img src={buildingB} alt='fireSpot' width='45' height='40' /> */}

                <span className='d-block fw-bold text-start'>
                  <span className='text-dark fw-bolder d-block text-center'>Inconnu</span>
                </span>
              </label>
            </div>
          </div>

          {state.etat_isolation == '1' && (
            <>
              <div className=' mb-10 form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  //   onChange={handleChangeCheckbox}

                  value={state.effet_joule}
                  name='effet_joule'
                  // defaultChecked={true}
                />
                <label className='form-label fw-bold fs-4 mx-3'>
                  {' '}
                  Contient des cables / une prise{' '}
                </label>
              </div>
            </>
          )}

          {state.etat_isolation == '0' && (
            <>
              <div className='fv-row mb-10'>
                <label className='form-label required mb-2'>epaisseur mur</label>

                <input
                  type='number'
                  className='form-control form-control-lg form-control-solid border-dark'
                  name={`epaisseur_mur`}
                  placeholder=''
                  onChange={onChange}
                  value={state.epaisseur_mur}
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name={`epaisseur_mur`} />
                </div>
              </div>

              <div className='fv-row mb-10'>
                <label className='form-label required mb-2'>Type de matériau</label>

                <select
                  name='materiau_mur'
                  className='form-select form-select-lg form-select-solid border-dark'
                  onChange={onChange}
                  value={state.materiau_mur}
                >
                  <option value='' disabled>
                    choisir{' '}
                  </option>
                  <option value='1'>Inconnu</option>
                  <option value='2'>
                    Murs en pierre de taille et moellons constitué d'un seul matériaux
                  </option>
                  <option value='3'>
                    Murs en pierre de taille et moellons avec remplissage tout venant
                  </option>
                  <option value='4'>
                    Murs en pisé ou béton de terre stabilisé (à partir d'argile crue)
                  </option>
                  <option value='5'>Murs en pan de bois sans remplissage tout venant</option>
                  <option value='6'>Murs en pan de bois avec remplissage tout venant</option>
                  <option value='7'>Murs bois (rondin)</option>
                  <option value='8'>Murs en briques pleines simples</option>
                  <option value='9'>Murs en briques pleines doubles avec lame d'air</option>
                  <option value='10'>Murs en briques creuses</option>
                  <option value='11'>Murs en blocs de béton pleins </option>
                  <option value='12'>Murs en blocs de béton creux</option>
                  <option value='13'>Murs en béton banché</option>
                  <option value='14'>Murs en béton de mâchefer</option>
                  <option value='15'>Brique terre cuite alvéolaire</option>
                  <option value='16'>Béton cellulaire avant 2013</option>
                  <option value='17'>Béton cellulaire à partir de 2013</option>
                  <option value='18'>
                    Murs en ossature bois avec isolant en remplissage ≥ 2006
                  </option>
                  <option value='19'>
                    Murs sandwich béton/isolant/béton (sans isolation rapportée)
                  </option>
                  <option value='20'>Cloison de plâtre</option>
                  <option value='24'>
                    Murs en ossature bois avec isolant en remplissage 2001-2005
                  </option>
                  <option value='25'>Murs en ossature bois sans remplissage</option>
                  <option value='26'>
                    Murs en ossature bois avec isolant en remplissage ${'<'} 2001
                  </option>
                  <option value='27'>Murs en ossature bois avec remplissage tout venant</option>
                </select>
              </div>
            </>
          )}

          <Adjacence onChange={onChange} statevalue={state.adjacence_mur} name='adjacence_mur' />

          {state.adjacence_mur === '10' && (
            <div className='row mb-10 align-items-center '>
              <div className='col'>
                <label className='form-label required'>Isolation d'adjacence </label>
              </div>
              <div className='col'>
                <input
                  type='radio'
                  className='btn-check'
                  name='isolation_adjacence_mur'
                  value='isole'
                  id='adj_kt_etat_isole'
                  onChange={onChange}
                />
                <label
                  className='btn btn-outline btn-outline-dashed btn-outline-default  align-items-center'
                  htmlFor='adj_kt_etat_isole'
                >
                  <span className='d-block fw-bold text-start'>
                    <span className='text-dark fw-bolder d-block text-center'> Isolé</span>
                  </span>
                </label>
              </div>

              <div className='col '>
                <input
                  type='radio'
                  className='btn-check'
                  name='isolation_adjacence_mur'
                  value='non_isole'
                  id='adj_kt_etat_non_isole'
                  onChange={onChange}
                />
                <label
                  className='btn btn-outline btn-outline-dashed btn-outline-default p-3 align-items-center '
                  htmlFor='adj_kt_etat_non_isole'
                >
                  <span className='d-block fw-bold text-start'>
                    <span className='text-dark fw-bolder d-block text-center'>Non isolé</span>
                  </span>
                </label>
              </div>
              <div className='col '>
                <input
                  type='radio'
                  className='btn-check'
                  name='isolation_adjacence_mur'
                  value='inconnu'
                  id='adj_kt_etat_inconnu'
                  onChange={onChange}
                />
                <label
                  className='btn btn-outline btn-outline-dashed btn-outline-default p-3 align-items-center '
                  htmlFor='adj_kt_etat_inconnu'
                >
                  {/* <img src={buildingB} alt='fireSpot' width='45' height='40' /> */}

                  <span className='d-block fw-bold text-start'>
                    <span className='text-dark fw-bolder d-block text-center'>Inconnu</span>
                  </span>
                </label>
              </div>
            </div>
          )}

          <br />
          <button type='button' className='btn btn-lg btn-info w-100 ' onClick={addWall}>
            <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            Sauvegarder
          </button>
        </div>

        <div className='flex-lg-row-fluid ms-lg-7  w-xl-400px ms-xl-10'>
          <div className='card card-xl-stretch mb-5 mb-xl-8'>
            {/* 
                    <div className='card-header border-0'>
                        <h3 className='card-title fw-bolder  text-dark'>Liste des murs</h3>

                    </div> */}

            <div
              className='card-body pt-0'
              style={
                state?.etat_isolation == 'non_isole'
                  ? {overflowY: 'scroll', height: '650px'}
                  : {overflowY: 'scroll', height: '500px'}
              }
            >
              {state.array_Murs.length > 0 &&
                state.array_Murs.map((mur: any, idx: any) => (
                  <>
                    <div className='d-flex align-items-center bg-light-info rounded p-5'>
                      <span className='svg-icon svg-icon-info me-5'>
                        <KTSVG
                          path='/media/icons/duotune/general/gen028.svg'
                          className='svg-icon-1'
                        />
                      </span>

                      <div className='flex-grow-1 me-2'>
                        <a href='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>
                          Mur n° {idx + 1} : {mur.surface_mur} m²
                        </a>
                        <span className=' fw-bold d-block'>isolation : {mur.etat_isolation}</span>
                      </div>
                      <button
                        type='button'
                        className='btn btn-sm btn-warning me-5'
                        onClick={(e) => editWall(idx)}
                      >
                        <span className='svg-icon svg-icon-warning'>
                          <KTSVG
                            path='/media/icons/duotune/art/art005.svg'
                            className='svg-icon-1'
                          />
                        </span>
                      </button>
                      <button
                        type='button'
                        className='btn btn-sm btn-danger '
                        onClick={(e) => deleteWall(idx)}
                      >
                        <span className='svg-icon svg-icon-danger'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon-1'
                          />
                        </span>
                      </button>
                    </div>
                    <br />
                  </>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {SimulateurStep2}
