import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {AddressBookPage} from '../pages/AddressBookPage'
import {PropertiesPage} from '../pages/PropertiesPage'
import {TenantFilesPage} from '../pages/TenantFilesPage'
// import {InsuranceGliPage} from '../pages/InsuranceGliPage'
import {EstimatePage} from '../pages/EstimatePage'
// import {Step7} from '../modules/estimator/components/Step7'
import {HomeStagingPage} from '../pages/HomeStagingPage'
import {EnergyOptPage} from '../pages/EnergyOptPage'

import {MenuTestPage} from '../pages/MenuTestPage'
import { CandidatePage } from '../pages/CandidatePage'
import { TenantPage } from '../pages/TenantPage'
import { ListCandidatures } from '../modules/candidature/candidature'
import { SimulEnergy } from '../modules/simule-energie/SimulEnergy'

export function PrivateRoutes() {
  // const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const CreateTenantFile = lazy(() => import('../modules/tenant-file/CreateTenantFile'))
  // const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>

        <Route path='/dashboard' component={DashboardWrapper} />
        {/* <Route path='/addressbook' component={AddressBookPage} /> */}
        <Route path='/tenant-details/:df_id' component={TenantPage} />
        <Route path='/properties' component={PropertiesPage} />
        <Route path='/candidats' component={CandidatePage} />
        <Route path='/tenants' component={TenantFilesPage} />
        <Route path='/tenant-file/create' component={CreateTenantFile} />
        <Route path='/candidature' component={ListCandidatures} />
        {/* <Route path='/insurances/gli' component={InsuranceGliPage} /> */}
        <Route path='/services/estimate' component={EstimatePage} />
        {/* <Route path='/services/homestaging' component={HomeStagingPage} />
        <Route path='/services/energyopt' component={EnergyOptPage} /> */}

        {/* ici oldroutes */}
        {/* <Route path='/builder' component={BuilderPageWrapper} />
        <Route path='/crafted/pages/profile' component={ProfilePage} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />*/}
        <Route path='/account' component={AccountPage} /> 
        <Route path='/simuler-mon-dpe' component={SimulEnergy} /> 
        
        <Route path='/googleplay' component={() => { 
            window.location.href = 'https://play.google.com/store/apps/details?id=fr.digilogement.mcl'; 
            return null;
        }}/>
        <Route path='/applestore' component={() => { 
            window.location.href = 'https://apps.apple.com/fr/app/mon-carnet-logement/id1632900315?platform=iphone'; 
            return null;
        }}/>

        {/* <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} /> */}
        {/* ici system routes */}
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
