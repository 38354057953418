import React, { FC, useEffect, useRef, useState } from 'react'
import { Etape1 } from './contractSteps/etape1'
import { Etape2 } from './contractSteps/etape2'
import { Etape3 } from './contractSteps/etape3'
import { Etape4 } from './contractSteps/etape4'
import { Etape5 } from './contractSteps/etape5'
import { Etape6 } from './contractSteps/etape6'
import { Etape7 } from './contractSteps/etape7'
import { Etape8 } from './contractSteps/etape8'
import { Etape9 } from './contractSteps/etape9'
import { Etape10 } from './contractSteps/etape10'
import { Etape11 } from './contractSteps/etape11'
import { Etape12 } from './contractSteps/etape12'
import { KTSVG } from '../../../../_metronic/helpers'
import { StepperComponent } from '../../../../_metronic/assets/ts/components'
import { Formik, Form, FormikValues } from 'formik'
import { ContractModel, inits, createAppSchema } from '../ContractModel'
import { generatePDF } from '../ContractController'

import state from 'sweetalert/typings/modules/state'
// import { shallowEqual, useSelector, connect, useDispatch, ConnectedProps } from 'react-redux'

const APP_URL = process.env.REACT_APP_URL
async function getAddressInfo (address : string){
  let response = await fetch('https://api-adresse.data.gouv.fr/search/?q='+address+'&limit=15')
  
  let data = await response.json()
  return data.features;
}

const CreateContract: FC = () => {

  // const user = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  // const refreshToken = useSelector<RootState>(({ auth }) => auth.refreshToken, shallowEqual)
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const index = 1
  const [stateContract, setStateContract] = useState<ContractModel>(inits)
  const [startDate, setStartDate] = useState(new Date());
  const [currentSchema, setCurrentSchema] = useState(createAppSchema[0])
  const prevStep = () => {
    window.scrollTo(0, 0)
    if (!stepper.current) {
      // console.log('re')
      return
    }
    // console.log(stepper.current.currentStepIndex)


    stepper.current.goPrev()
    // console.log(stepper.current.currentStepIndex)
    setCurrentSchema(createAppSchema[stepper.current.currentStepIndex - 1])
  }

  const submitStep = () => {
    window.scrollTo(0, 0)

    if (!stepper.current) {
      // console.log('yyyy')
      return
    }
    setCurrentSchema(createAppSchema[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex === stepper.current.totatStepsNumber) {
      const etape = stepper.current.currentStepIndex
      console.log("currentStep=", etape)
      console.log("state=", stateContract)
      const formData = new FormData()

      formData.append('text', JSON.stringify(stateContract))
      generatePDF(formData).then(response => {
        console.log(response)

        window.open(APP_URL + '/media/contrat/contr.pdf')

      })
        .catch(error => {
          // toast.error('une erreur s\'est produite : vérifier vos documents !')
          console.log(error.response)
          // stepper.current?.goto(1)
        });

      stepper.current.goNext()

    } else {


      stepper.current.goNext()
      // actions.resetForm()
    }
  }

  const loadStepper = () => {
    // console.log('loadstepper')
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }
  useEffect(() => {

    // getProfileFromDF()
    if (!stepperRef.current) {
      return
    }
    loadStepper()
  }, [stepperRef])


  const handleChange = (event: any) => {

    setStateContract(prevValues => ({
      ...prevValues,
      [event.target.name]: event.target.value,

    }))
  }
  const handleChangeSelect =(selected:any)=>{
    console.log("zzzzzzzzzzzzz",selected)

    setStateContract(prevValues => ({
      ...prevValues,
      property_address: selected.properties.label,

    }))  }
  const handleInputChange=(inputValue:string)=>{
    console.log("kkkk",inputValue)
      if (inputValue.length >=3)
        getAddressInfo(inputValue).then(res => {
      console.log(res)
      setStateContract(prevValues => ({
        ...prevValues,
        list_address: res,
  
      }))  })
  }


  const addOwner = () => {
    console.log('addowner')
    var blank_owner = {
      owner_firstname: '',
      owner_lastname: '',
      owner_address: '',
      owner_email: '',
      owner_profile: '',
      owner_phone: ''
    }
    var array = stateContract.owners_array
    setStateContract(prevValues => ({
      ...prevValues,
      owners_array: [...prevValues.owners_array, blank_owner]
    }))

  }

  const onChangeOwner = (event: any, k: number) => {
    // console.log('onChangeOwner',k)
    const newState = stateContract.owners_array.map((obj, i) => {
      if (i === k) {
        return { ...obj, [event.target.name]: event.target.value };
      }
      return obj;
    });

    setStateContract(prevValues => ({
      ...prevValues,
      owners_array: newState
    }))


  }

  const addTenant = () => {
    console.log('addTenant')
    var blank_tenant = {
      tenant_firstname: '',
      tenant_lastname: '',
      tenant_address: '',
      tenant_email: '',
      tenant_profile: '',
      tenant_phone: ''
    }
    var array = stateContract.tenants_array
    setStateContract(prevValues => ({
      ...prevValues,
      tenants_array: [...prevValues.tenants_array, blank_tenant]
    }))

  }

  const onChangeTenant = (event: any, k: number) => {
    // console.log('onChangeTenant',k)
    const newState = stateContract.tenants_array.map((obj, i) => {
      if (i === k) {
        return { ...obj, [event.target.name]: event.target.value };
      }
      return obj;
    });

    setStateContract(prevValues => ({
      ...prevValues,
      tenants_array: newState
    }))


  }

  const handleChangeCheckbox = (event: any) => {
    type StatusKey = keyof typeof stateContract
    var field: StatusKey = event.target.name
    setStateContract(prevValues => ({
      ...prevValues,
      [event.target.name]: !prevValues[field],
    }))
  }



  return (
    <div
      ref={stepperRef}
      className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
      id='kt_create_tenantfile_stepper'
    >
      <div className='d-flex justify-content-center bg-white rounded justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px mx-9'>
        <div className="px-6 px-lg-10 px-xxl-15 py-10">
          <div className='stepper-nav'>
            <a id="myLink" href="#" onClick={() => {
              // viderInfo()
              stepper.current?.goto(1)
            }}>

              <div className='stepper-item' data-kt-stepper-element='nav'>
                <div className='stepper-line w-40px'></div>

                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>1</span>
                </div>

                <div className='stepper-label'>
                  <h3 className={stepper.current?.currentStepIndex === 1 ? 'text-primary' : 'stepper-title'}> Informations bailleur</h3>

                  {/* <div className='stepper-desc'>Name your App</div> */}
                </div>
              </div>
            </a>

            <a id="myLink" href="#"
              onClick={() => {
                // viderInfo()
                stepper.current?.goto(2)
              }
              }>

              <div className='stepper-item' data-kt-stepper-element='nav'>
                <div className='stepper-line w-40px'></div>

                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>2</span>
                </div>

                <div className='stepper-label'>
                  <h3 className={stepper.current?.currentStepIndex === 2 ? 'text-primary' : 'stepper-title'}>Informations locataire</h3>

                </div>
              </div>
            </a>
            <a id="myLink" href="#" onClick={() => {
              // viderInfo()
              stepper.current?.goto(3)
            }}>

              <div className='stepper-item' data-kt-stepper-element='nav'>
                <div className='stepper-line w-40px'></div>

                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>3</span>
                </div>

                <div className='stepper-label'>
                  <h3 className={stepper.current?.currentStepIndex === 3 ? 'text-primary' : 'stepper-title'}>Le logement loué</h3>

                </div>
              </div>
            </a>

            <a id="myLink" href="#" onClick={() => {
              // viderInfo()
              stepper.current?.goto(4)
            }}>

              <div className='stepper-item' data-kt-stepper-element='nav'>
                <div className='stepper-line w-40px'></div>

                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>4</span>
                </div>


                <div className='stepper-label'>
                  <h3 className={stepper.current?.currentStepIndex === 4 ? 'text-primary' : 'stepper-title'}> Locaux accessoires au logement

                  </h3>

                </div>
              </div> </a>

            <a id="myLink" href="#" onClick={() => {
              // viderInfo()
              stepper.current?.goto(5)
            }}>

              <div className='stepper-item' data-kt-stepper-element='nav'>
                <div className='stepper-line w-40px'></div>

                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>5</span>
                </div>

                <div className='stepper-label'>
                  <h3 className={stepper.current?.currentStepIndex === 5 ? 'text-primary' : 'stepper-title'}>Chauffage & Eau chaude sanitaire
                  </h3>

                </div>
              </div>
            </a>

            <a id="myLink" href="#" onClick={() => {
              // viderInfo()
              stepper.current?.goto(6)
            }}>
              <div className='stepper-item' data-kt-stepper-element='nav'   >
                <div className='stepper-line w-40px'></div>
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>6</span>
                </div>
                <div className='stepper-label' >
                  <h3 className={stepper.current?.currentStepIndex === 6 ? 'text-primary' : 'stepper-title'}>Durées et dates de prise d'effet</h3>

                  {/* <div className='stepper-desc'>Review and Submit</div> */}
                </div>
              </div> </a>

            <a id="myLink" href="#" onClick={() => {
              // viderInfo()
              stepper.current?.goto(7)
            }}>

              <div className='stepper-item' data-kt-stepper-element='nav'>
                <div className='stepper-line w-40px'></div>

                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>7</span>
                </div>

                <div className='stepper-label'>
                  <h3 className={stepper.current?.currentStepIndex === 7 ? 'text-primary' : 'stepper-title'}>Conditions financières</h3>



                  {/* <div className='stepper-desc'>Review and Submit</div> */}
                </div>
              </div>
            </a>

            <a id="myLink" href="#" onClick={() => {
              // viderInfo()
              stepper.current?.goto(8)
            }}>

              <div className='stepper-item' data-kt-stepper-element='nav'>
                <div className='stepper-line w-40px'></div>

                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>8</span>
                </div>

                <div className='stepper-label'>
                  <h3 className={stepper.current?.currentStepIndex === 8 ? 'text-primary' : 'stepper-title'}>complément & Modalité de paiement
                  </h3>

                  {/* <div className='stepper-desc'>Aperçu et validation</div> */}
                </div>
              </div>
            </a>


            <a id="myLink" href="#" onClick={() => {
              stepper.current?.goto(9)
            }}>

              <div className='stepper-item' data-kt-stepper-element='nav'>
                <div className='stepper-line w-40px'></div>

                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>9</span>
                </div>

                <div className='stepper-label'>
                  <h3 className={stepper.current?.currentStepIndex === 9 ? 'text-primary' : 'stepper-title'}>Charges , Garanties & Assurances</h3>


                  {/* <div className='stepper-desc'>Review and Submit</div> */}
                </div>
              </div>
            </a>

            <a id="myLink" href="#" onClick={() => {
              // viderInfo()
              stepper.current?.goto(10)
            }}>

              <div className='stepper-item' data-kt-stepper-element='nav'>
                <div className='stepper-line w-40px'></div>

                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>10</span>
                </div>

                <div className='stepper-label'>
                  <h3 className={stepper.current?.currentStepIndex === 10 ? 'text-primary' : 'stepper-title'}>Dépenses énergétiques & Travaux</h3>

                </div>
              </div>
            </a>

            <a id="myLink" href="#" onClick={() => {
              // viderInfo()
              stepper.current?.goto(11)
            }}>

              <div className='stepper-item' data-kt-stepper-element='nav'>
                <div className='stepper-line w-40px'></div>

                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>11</span>
                </div>

                <div className='stepper-label'>
                  <h3 className={stepper.current?.currentStepIndex === 11 ? 'text-primary' : 'stepper-title'}>Autres Conditions</h3>

                  {/* <div className='stepper-desc'>Aperçu et validation</div> */}
                </div>
              </div>
            </a>

            <a id="myLink" href="#" onClick={() => {
              // viderInfo()
              stepper.current?.goto(12)
            }}>

              <div className='stepper-item' data-kt-stepper-element='nav'>
                <div className='stepper-line w-40px'></div>

                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>12</span>
                </div>

                <div className='stepper-label'>
                  <h3 className={stepper.current?.currentStepIndex === 12 ? 'text-primary' : 'stepper-title'}>Annexes</h3>

                  {/* <div className='stepper-desc'>Aperçu et validation</div> */}
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>

      <div className='d-flex align-items-start flex-column flex-center bg-white rounded'>

        <Formik enableReinitialize validationSchema={currentSchema} initialValues={stateContract} onSubmit={submitStep}>
          {() => (
            <Form className='py-12 w-100 w-xl-700px px-5' noValidate id='kt_modal_create_app_form'>

              {/* step1 */}
              <div className='current' data-kt-stepper-element='content'>


                <Etape1 onChangeOwner={onChangeOwner} addOwner={addOwner} state={stateContract} onChange={handleChange} />
              </div>

              <div data-kt-stepper-element='content'>

                <Etape2 onChangeTenant={onChangeTenant} addTenant={addTenant} state={stateContract} onChange={handleChange} />
              </div>

              {/* step3 */}
              <div data-kt-stepper-element='content'>

                <Etape3 state={stateContract} onChange={handleChange}   onChangeSelect={handleChangeSelect} handleInputChange={handleInputChange} />


              </div>

              {/* step4 */}
              <div data-kt-stepper-element='content'>

                <Etape4 handleChangeCheckbox={handleChangeCheckbox} state={stateContract} onChange={handleChange} />
              </div>

              {/* step5 */}
              <div data-kt-stepper-element='content'>

                <Etape5 state={stateContract} onChange={handleChange} />

              </div>

              {/* step6 */}
              <div data-kt-stepper-element='content'>

                <Etape6 state={stateContract} onChange={handleChange} />

              </div>

              {/* step7 */}
              <div data-kt-stepper-element='content'>

                <Etape7 state={stateContract} onChange={handleChange} />

              </div>
              {/* step8 */}
              <div data-kt-stepper-element='content'>

                <Etape8 state={stateContract} onChange={handleChange} />

              </div>
              {/* step9 */}
              <div data-kt-stepper-element='content'>

                <Etape9 handleChangeCheckbox={handleChangeCheckbox} state={stateContract} onChange={handleChange} />

              </div>
              {/* step10 */}
              <div data-kt-stepper-element='content'>

                <Etape10 handleChangeCheckbox={handleChangeCheckbox} state={stateContract} onChange={handleChange} />

              </div>
              <div data-kt-stepper-element='content'>

                <Etape12 state={stateContract} onChange={handleChange} />

              </div>
              <div data-kt-stepper-element='content'>

                <Etape11 handleChangeCheckbox={handleChangeCheckbox} state={stateContract} onChange={handleChange} />

              </div>



              {/* btn */}
              <div className='d-flex flex-stack pt-10'>
                <div className='mr-2'>
                  <button
                    onClick={prevStep}
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'>
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-4 me-1' />
                    Précédent
                  </button>
                </div>

                <div>

                  <button
                    onClick={submitStep}
                    type='button'
                    className='btn btn-lg btn-primary me-3'
                  // disabled={stepper.current?.currentStepIndex === 10 ? true : false}
                  >

                    {stepper.current?.currentStepIndex === 12 ? 'Signer ou imprimer' : 'Suivant'}
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-2 me-0'
                    />
                  </button>
                </div>
              </div>

            </Form>
          )}
        </Formik>
      </div>

      <div className='d-flex justify-content-center bg-white rounded justify-content-xl-start flex-row-auto w-100 w-xl-250px w-xxl-300px mx-9'>
        {/* <div className="px-6 px-lg-10 px-xxl-15 py-10">
          apercu
          <Document file="http://127.0.0.1:8000/media/cont.pdf" />
        </div> */}
      </div>


    </div>
  )
}

export default CreateContract
