/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState, useEffect } from 'react'
import { PageTitle } from '../../_metronic/layout/core'
// import { PropertyModel, initsProperty } from '../modules/properties/models/PropertyModel'
// import { getPropertiesList } from '../modules/properties/propertiesController'
// import Collapsible from 'react-collapsible';
import  {TenantDoc} from '../modules/properties/TenantDoc'
 const TenantPage: FC = (props) => {
  // const [StateProperty, setStateProperty] = useState<PropertyModel>(initsProperty)


  // useEffect(() => {

  //   getPropertiesList().then(response => {
  //     console.log(response.data)
  //     setStateProperty(prevValues => ({
  //       ...prevValues,
  //       properties_list: response.data
  //     }))

  //   })

  // }, [])


  return (
    <>
      <PageTitle breadcrumbs={[]}>Aperçu Dossier locataire de username</PageTitle>

      <TenantDoc className='mb-5 mb-xl-8' props={props} />



      {/* <Collapsible trigger="Start here">
      <p>
        This is the collapsible content. It can be any element or React
        component you like.
      </p>
      <p>
        It can even be another Collapsible component. Check out the next
        section!
      </p>
    </Collapsible> */}
    </>
  )
}

export { TenantPage }
