export const translate :{[key: string]: string } = {
  '':'',
  FRENCH_IDENTITY_CARD: 'Carte d\'identité francaise',
  FRENCH_PASSPORT:'Passeport français',
  FRENCH_RESIDENCE_PERMIT:'Carte séjour française',
  OTHER_IDENTIFICATION:'Autre',

  TENANT: 'Locataire',
  OWNER: 'Propriétaire',
  GUEST: 'Hébergé gratuitement',
  GUEST_PARENTS:'Chez les parents',
  
  ALONE:'Seul',
  COUPLE:'Couple',
  GROUP:'Colocation',
 
  SALARY : 'Salaire',
  SOCIAL_SERVICE: 'Service sociale',
  RENT : 'Rente',
  PENSION : 'Pension',
  SCHOLARSHIP: 'Bourse',
 
  MY_NAME: 'Personnel',
  MY_PARENTS: 'Au nom des parents',
  OTHER_TAX: 'Autre ',
  LESS_THAN_YEAR: 'Moins d\'un an',

  CDI: 'Contrat CDI',
  CDI_TRIAL: 'CDI période d\'essai',
  CDD: 'Contrat CDI',
  ALTERNATION: 'Alternance',
  CTT: 'CTT',
  INDEPENDENT: 'Indépendent',
  INTERNSHIP: 'Stage',
  RETIRED: 'Retraité',
  UNEMPLOYED: 'Chomage',
  OTHER: 'Autre',
  PUBLIC: 'Public',
  STUDENT: 'Etudiant',

  TO_PROCESS:'En cours',
  'color_En cours':'warning',
    
  DECLINED:'Rejeté',
  'color_Rejeté':'danger'
}