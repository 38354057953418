/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../_metronic/helpers'
import { translate } from './translate_df'
// import {IconUserModel} from '../../../../app/modules/profile/ProfileModels'
// import {UsersList} from '../../../../app/modules/profile/components/UsersList'

type Props = {
    //   icon: string
    badgeColor: string
    status: string
    statusColor: string
    title: string
    description: string
    date: string
    budget: string
    doc_url: string
    //   users?: Array<IconUserModel>
}

const Card2: FC<Props> = ({
    //   icon,
    badgeColor,
    status,
    statusColor,
    title,
    description,
    date,
    budget,
    doc_url,
    //   users = undefined,
}) => {
    return (

        <div className='row g-6 g-xl-9'>
            <div className='col-md-6 col-xl-6'>

                <div className='card-body'>

                    <div className='row'>
                        <div className='fs-6  col-4 text-dark my-3'>{title}</div>
                        <div className='border border-gray-300 border-dashed rounded col-6 py-3 '>
                            <div className='fs-6 fw-bolder text-dark '>{description}</div>
                        </div>
                        <div className='col-2'>
                            <span className={`badge badge-light-${translate['color_' + status]} fw-bolder my-3 px-4 py-3`}>
                                {status}
                            </span>
                        </div>

                    </div>

                </div>


            </div>

            <div className='col-6'>

                <div className='card-body'>
                    <div className='row'>
                        <a href={doc_url} target="_blank" className='text-gray-800 text-hover-primary fw-bolder fs-6'>
                        <div className='d-flex align-items-center col-9'>
                            <span className='bullet bullet-vertical h-20px bg-primary'></span>
                            <KTSVG path="/media/icons/duotune/files/fil021.svg" className="svg-icon-muted svg-icon-2hx" />
                            <div className='flex-grow-1 mx-5'>
                                Document
                                <span className='text-muted fw-bold d-block'>cliquer pour télécharger le document</span>
                            </div>
                        </div>
                    </a>
                    {/* <div className='col-3'>
                        <span className={`badge badge-light-warning fw-bolder my-3 px-4 py-3`}>
                            {"en cours"}
                        </span>
                    </div> */}
                </div>

            </div>


        </div>
                </div >

    )
}

export { Card2 }
