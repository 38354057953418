/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useLayoutEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {PropertyModel, initsProperty} from './models/PropertyModel'
import {Formik, Form, ErrorMessage} from 'formik'
// import { assignTenant } from './propertiesController'
import Accordion from 'react-bootstrap/Accordion'
import {Card2} from './card'
import {Link} from 'react-router-dom'
import {getCandidateDfFile} from './propertiesController'
import {inits, TenantModel} from '../tenant-file/models/TenantFileModel'
import {translate} from './translate_df'
import { validateTenantFolder } from '../tenant-file/tenantFileController'
import toast from 'react-hot-toast'

type Props = {
  className: string
  props: any
}
const TenantDoc: React.FC<Props> = ({className, props}) => {
  const [candidateState, setCandidateState] = useState<TenantModel>(inits)
  const [infoPersonState, setInfoPersonState] = useState("")
  const [situHabitState, setSituHabitState] = useState("")
  const [situProState, setSituProState] = useState("")
  const [situFinState, setSituFinState] = useState("")
  const [taxState, setTaxState] = useState("")
  const [garantorState, setGarantorState] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [statusFolder, setStatusFolder] = useState(false)
  const [statusRanking, setStatusRanking] = useState("")
  
  useEffect(() => {
    // loader(true)
    getProfileFromDF()
  }, [])

  const getProfileFromDF = () => {
    const formData = new FormData()
    // console.log(props.match.params.df_id)
    formData.append('tenant_id', props.match.params.df_id)
    getCandidateDfFile(formData)
      .then((response) => {
        const payload = response.data
        let identification_type = ''
        let identification_doc_url = ''
        let identification_doc_id = ''
        var identification_files: any = []
        let identification_doc_status = ''

        var hebergement_files: any = []
        var situation_pro_files: any = []
        var tax_doc_files: any = []

        let hebergement_doc_url = ''
        let situation_pro_doc_url = ''
        let tax_doc_url = ''
        // let revenus_doc_url = ""

        let hebergement = ''
        var situation_pro = ''
        let tax_type = ''

        let hebergement_doc_id = ''
        let situation_pro_doc_id = ''
        let tax_doc_id = ''

        let hebergement_doc_status = ''
        let situation_pro_doc_status = ''
        let tax_doc_status = ''

        let type_location = ''
        let tenants: any = []
        var revenus_array_all_df: any = []
        var all_guarantors_id: Array<string>
        all_guarantors_id = []
        var guarantor_type = ''
        var revenus_value = 0
        var myguarantors: Array<object>
        myguarantors = []
        
        payload.guarantors.map((garant: any) => {
          // console.log(garant)
          all_guarantors_id.push(garant.id)

          guarantor_type = garant.typeGuarantor
          var garant_name = garant.firstName + ' ' + garant.lastName
          var representant_name = garant.firstName
          var garant_id = garant.id
          var legal_name = garant.legalPersonName
          var legal_person_df_url = ''
          var legal_person_df_id = ''
          var legal_person_df_files = ''
          var legal_doc_status = ''
          var garant_identification_type = ''
          var garant_identification_doc_url = ''
          var garant_identification_doc_id = ''
          var garant_identification_doc_status = ''
          var garant_identification_files = ''
          var garant_hebergement = ''
          var garant_hebergement_doc_url = ''
          var garant_hebergement_doc_id = ''
          var garant_hebergement_doc_status = ''
          var garant_hebergement_files = ''
          var garant_revenus_array_all_df: Array<object>
          garant_revenus_array_all_df = []
          var garant_situation_pro = ''
          var garant_situation_pro_doc_url = ''
          var garant_situation_pro_doc_id = ''
          var garant_situation_pro_doc_status = ''
          var garant_situation_pro_files = ''
          var garant_tax_type = ''
          var garant_tax_doc_url = ''
          var garant_tax_doc_id = ''
          var garant_tax_doc_status = ''
          var garant_tax_doc_files = ''
          garant.documents.map((doc: any) => {
            if (doc.documentCategory == 'IDENTIFICATION_LEGAL_PERSON') {
              legal_person_df_url = doc.name
              legal_person_df_id = doc.id
              legal_person_df_files = doc.files
              legal_doc_status = doc.documentStatus
            }
            if (doc.documentCategory == 'IDENTIFICATION') {
              garant_identification_type = doc.documentSubCategory
              garant_identification_doc_url = doc.name
              garant_identification_doc_id = doc.id
              garant_identification_doc_status = doc.documentStatus
              garant_identification_files = doc.files
            }

            if (doc.documentCategory == 'RESIDENCY') {
              garant_hebergement = doc.documentSubCategory
              garant_hebergement_doc_url = doc.name
              garant_hebergement_doc_id = doc.id
              garant_hebergement_doc_status = doc.documentStatus
              garant_hebergement_files = doc.files
            }

            if (doc.documentCategory == 'FINANCIAL') {
              var income_type = doc.documentSubCategory
              var document_income_url = doc.name
              var doc_income_id = doc.id
              var monthly_sum = doc.monthlySum
              var income_doc_status = doc.documentStatus
              var income_id = doc.id
              var income_files = doc.files

              // add_income = false
              garant_revenus_array_all_df.push({
                income_type: income_type,
                id: income_id,
                income_value: monthly_sum,
                income_file: document_income_url,
                income_doc_id: doc_income_id,
                income_doc_status: income_doc_status,
                income_files: income_files,
              })
            }

            if (doc.documentCategory == 'PROFESSIONAL') {
              garant_situation_pro = doc.documentSubCategory
              garant_situation_pro_doc_url = doc.name
              garant_situation_pro_doc_id = doc.id
              garant_situation_pro_doc_status = doc.documentStatus
              garant_situation_pro_files = doc.files
            }
            if (doc.documentCategory == 'TAX') {
              garant_tax_type = doc.documentSubCategory
              garant_tax_doc_url = doc.name
              garant_tax_doc_id = doc.id
              garant_tax_doc_status = doc.documentStatus
              garant_tax_doc_files = doc.files
            }
          })
          myguarantors.push({
            representant_name: representant_name,
            legal_name: legal_name,
            guarantor_type: guarantor_type,
            garant_name: garant_name,
            garant_id: garant_id,
            legal_person_df_url: legal_person_df_url,
            legal_person_df_id: legal_person_df_id,
            legal_person_df_files: legal_person_df_files,
            legal_doc_status: legal_doc_status,
            garant_identification_type: garant_identification_type,
            garant_identification_doc_url: garant_identification_doc_url,
            garant_identification_doc_id: garant_identification_doc_id,
            garant_identification_doc_status: garant_identification_doc_status,
            garant_identification_files: garant_identification_files,
            garant_hebergement: garant_hebergement,
            garant_hebergement_doc_url: garant_hebergement_doc_url,
            garant_hebergement_doc_id: garant_hebergement_doc_id,
            garant_hebergement_doc_status: garant_hebergement_doc_status,
            garant_hebergement_files: garant_hebergement_files,
            garant_revenus_array_all_df: garant_revenus_array_all_df,
            garant_situation_pro: garant_situation_pro,
            garant_situation_pro_doc_url: garant_situation_pro_doc_url,
            garant_situation_pro_doc_id: garant_situation_pro_doc_id,
            garant_situation_pro_doc_status: garant_situation_pro_doc_status,
            garant_situation_pro_files: garant_situation_pro_files,
            garant_tax_type: garant_tax_type,
            garant_tax_doc_url: garant_tax_doc_url,
            garant_tax_doc_id: garant_tax_doc_id,
            garant_tax_doc_status: garant_tax_doc_status,
            garant_tax_doc_files: garant_tax_doc_files,
          })
        })
        type_location = payload.apartmentSharing.applicationType
        if (type_location !== 'ALONE')
          payload.apartmentSharing.tenants.map((tenant: any) => {
            if (tenant.tenantType == 'JOIN')
              tenants.push({
                email: tenant.email,
                id: tenant.id,
              })
          })
        payload.documents.map((doc: any) => {
          if (doc.documentCategory == 'IDENTIFICATION') {
            identification_type = doc.documentSubCategory
            identification_doc_url = doc.name
            identification_doc_id = doc.id
            identification_doc_status = doc.documentStatus
            identification_files = doc.files
          }
          if (doc.documentCategory == 'RESIDENCY') {
            hebergement = doc.documentSubCategory
            hebergement_doc_url = doc.name
            hebergement_doc_id = doc.id
            hebergement_doc_status = doc.documentStatus
            hebergement_files = doc.files
          }
          if (doc.documentCategory == 'FINANCIAL') {
            var income_type = doc.documentSubCategory
            var document_income_url = doc.name
            var doc_income_id = doc.id
            var monthly_sum = doc.monthlySum
            var income_doc_status = doc.documentStatus
            var income_files = doc.files
            revenus_value = revenus_value + monthly_sum
            revenus_array_all_df.push({
              income_type: income_type,
              income_value: monthly_sum,
              income_file: document_income_url,
              income_doc_id: doc_income_id,
              income_doc_status: income_doc_status,
              income_files: income_files,
            })
          }

          if (doc.documentCategory == 'PROFESSIONAL') {
            situation_pro = doc.documentSubCategory
            situation_pro_doc_url = doc.name
            situation_pro_doc_id = doc.id
            situation_pro_doc_status = doc.documentStatus
            situation_pro_files = doc.files
          }
          if (doc.documentCategory == 'TAX') {
            tax_type = doc.documentSubCategory
            tax_doc_url = doc.name
            tax_doc_id = doc.id
            tax_doc_status = doc.documentStatus
            tax_doc_files = doc.files
          }
        })

        setCandidateState((prevValues: any) => ({
          ...prevValues,
          revenus_value: revenus_value,
          firstname: response.data.firstName,
          lastname: response.data.lastName,
          address: response.data.zipCode,
          email: response.data.email,

          piece_identite: identification_type,
          document_df_url: identification_doc_url,
          doc_id: identification_doc_id,

          type_location: type_location,
          tenants: tenants,
          revenus_array_all_df: revenus_array_all_df,

          hebergement: hebergement,
          situation_pro: situation_pro,
          tax_type: tax_type,

          hebergement_doc_url: hebergement_doc_url,
          situation_pro_doc_url: situation_pro_doc_url,
          tax_doc_url: tax_doc_url,

          hebergement_doc_id: hebergement_doc_id,
          situation_pro_doc_id: situation_pro_doc_id,
          tax_doc_id: tax_doc_id,

          hebergement_doc_status: hebergement_doc_status,
          situation_pro_doc_status: situation_pro_doc_status,
          tax_doc_status: tax_doc_status,
          identification_doc_status: identification_doc_status,

          guarantors: myguarantors,
          guarantor_type: guarantor_type,
          guarantors_id: all_guarantors_id,
          identification_files: identification_files,
          hebergement_files: hebergement_files,
          situation_pro_files: situation_pro_files,
          tax_doc_files: tax_doc_files,
        }))
      })
      .catch((error) => {
        // console.log(error.response)
      })
  }


  // #verification Dossier locataire par Mcl

  // const VerifDL = () => {
  //   const formDataVerif = new FormData();
  //   formDataVerif.append('u_userid', '3')
  //   // formDataAssign.append('email', StateProperty.tenant_email)
  //   setIsLoading(true)
  //   validateTenantFolder(formDataVerif)
  //     .then(response => {
  //       // toast.success('invitation locataire envoyée avec succès !')
  //       // set result of verification on state
  //       const res = response.data.personne[0]
  //       var bulletin :any = []
  //       setTimeout(() => {
  //         res.fichier.map((situation :any) => {
  //           if(situation.type === 'cin' && situation.reconnu === 'true' && situation.etat ==='Valide')
  //             setInfoPersonState("ok")
  //           if(situation.type === 'cin' && situation.etat !='Valide')
  //             setInfoPersonState("no")
  //           if(situation.type === "Pièce d'identité" && situation.etat =='Illisible' && situation.reconnu === 'false')
  //             setInfoPersonState("no")
  //           if(situation.type === "cin" && situation.etat =='Illisible' && situation.reconnu === 'true')
  //             setInfoPersonState("illisible")
  //           if(situation.type === 'avis_imposition' && situation.reconnu === 'true' && situation.etat ==='Valide')
  //             setTaxState("ok")
  //           if(situation.type === 'avis_imposition' && situation.etat !='Valide')
  //             setTaxState("no")
  //           if(situation.type === 'quittance' && situation.reconnu === 'true' && situation.etat ==='Valide')
  //             setSituHabitState("ok")
  //           if(situation.type === 'quittance' && situation.etat !='Valide')
  //             setSituHabitState("no")
  //           if(situation.type === 'Bulletin_de_paie' && situation.reconnu === 'true' && situation.etat ==='Valide')
  //             bulletin.push(true)
  //           if(situation.type === 'Bulletin_de_paie' && situation.etat !='Valide')
  //             bulletin.push(false)
  //           setStatusRanking(response.data.note)
            
  //         })
  //         if(bulletin.length === bulletin.filter((x:any) => x).length)
  //           setSituFinState("ok")
  //         else
  //           setSituFinState("no")
  //         setIsLoading(false)
  //         setStatusFolder(true)
  //       }, 1000)
        
  //       // setGarantorState([])
        
  //     })
  //     .catch(error => {
  //       toast.error('Une erreur s\'est produite')
  //       setTimeout(() => {
  //         setIsLoading(false)
  //       }, 1000)
  //     })
  // }
  
  return (
    <div className={`card ${className}`}>
      {/* <div className='fs-3 text-dark px-4 mx-8 my-4'>
       
      </div> */}
      {/* <div className="d-flex flex-row fs-3 px-4 mx-4 my-4"> */}
      <div className="row fs-3 px-4 mx-2 my-4">
        <div className="col-sm-12 col-md-6">
          Dossier locataire du candidat :{' '}
        </div>
        <div className="col-sm-12 col-md-6">
          <strong className='text-primary'>
            {candidateState.firstname} {candidateState.lastname} 
          </strong>
        </div>
       
        {/* {parseFloat(statusRanking) < 33.33? 
            <KTSVG path="/media/icons/duotune/general/gen026.svg" className="svg-icon-success svg-icon-2hx" />
          :parseFloat(statusRanking) >= 33.33 && parseFloat(statusRanking) < 50 ?
            <KTSVG path="/media/icons/duotune/general/gen044.svg" className="svg-icon-warning svg-icon-2hx" />
          :parseFloat(statusRanking) >= 50 ?
            <KTSVG path="/media/icons/duotune/general/gen040.svg" className="svg-icon-danger svg-icon-2hx" />
          :''
          } */}
      </div>
      <div className="d-flex flex-row-reverse fs-6 px-4 mx-4 my-4">
        
        {/* <button
         className='btn btn-sm bg-primary text-white mx-2'
         onClick={() => VerifDL()}
        > 
          {!isLoading? 
            <span className="indicator-label">
              Vérifier 
            </span>
            :
            <span className="indicator-label">
              Chargement... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          }
          
        </button>
        <span className="mt-2">
          Lancer la vérification du dossier {' '}
        </span> */}
        
      </div>
      {/* defaultActiveKey="0"  */}
      <Accordion>
        <Accordion.Item eventKey='0'>
          <Accordion.Header>Informations personnelles 
            {infoPersonState ==="ok"? 
              <div title="document valide"><KTSVG path="/media/icons/duotune/files/fil016.svg" className="svg-icon-success svg-icon-2hx" /></div>
             : infoPersonState  ==="no"?
              <div title="document invalide"><KTSVG path="/media/icons/duotune/files/fil015.svg" className="svg-icon-danger svg-icon-2hx"/></div>
             : infoPersonState  ==="illisible"?
              <div title="document illisible"><KTSVG path="/media/icons/duotune/files/fil015.svg" className="svg-icon-warning svg-icon-2hx" /> </div>
             : ''
            }
            
          </Accordion.Header>
          <Accordion.Body>
            <Card2
              // icon='/media/svg/brand-logos/plurk.svg'
              badgeColor='primary'
              status={translate[candidateState.identification_doc_status]}
              statusColor='primary'
              title={`Pièce d'identité`}
              description={translate[candidateState.piece_identite]}
              date='November 10, 2021'
              budget='$284,900.00'
              doc_url={candidateState.document_df_url}
              // users={users1}
            />
            <div className='card-body py-0'>
              <div className='row'>
                <div className='fs-6  col-2 text-dark my-3'>{'email'}</div>
                <div className='border border-gray-200 border-dashed rounded col-4 py-3 '>
                  <div className='fs-5 fw-bolder text-dark '>{candidateState.email}</div>
                </div>
                <div className='fs-6  col-2 text-dark my-3'>Code postal</div>
                <div className='border border-gray-200 border-dashed rounded col-4 py-3 '>
                  <div className='fs-5 fw-bolder text-dark '>{candidateState.address}</div>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='1'>
          <Accordion.Header>
            Type de location et situation d'hébergement
            {situHabitState  ==="ok"?
              <div title="document valide"><KTSVG path="/media/icons/duotune/files/fil016.svg" className="svg-icon-success svg-icon-2hx" /></div>
             : situHabitState  ==="no"? 
             <div title="document invalide"><KTSVG path="/media/icons/duotune/files/fil015.svg" className="svg-icon-danger svg-icon-2hx" /></div>
             : ''
            }
          </Accordion.Header>
          <Accordion.Body>
            <Card2
              // icon='/media/svg/brand-logos/plurk.svg'
              badgeColor='primary'
              status={translate[candidateState.hebergement_doc_status]}
              statusColor='primary'
              title='Hébergement actuel  '
              description={translate[candidateState.hebergement]}
              date='November 10, 2021'
              budget='$284,900.00'
              doc_url={candidateState.hebergement_doc_url}
              // users={users1}
            />
            <div className='card-body py-0'>
              <div className='row'>
                <div className='fs-6 col-2 text-dark my-3'>{'Type de location'}</div>
                <div className='border border-gray-200 border-dashed rounded col-4 py-3 '>
                  <div className='fs-5 fw-bolder text-dark '>
                    {translate[candidateState.type_location]}
                  </div>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey='2'>
          <Accordion.Header>
            Informations professionnelles
            {situProState  ==="ok"?
              <div title="document valide"><KTSVG path="/media/icons/duotune/files/fil016.svg" className="svg-icon-success svg-icon-2hx" /></div>
             : situProState  ==="no"? 
             <div title="document invalide"><KTSVG path="/media/icons/duotune/files/fil015.svg" className="svg-icon-danger svg-icon-2hx" /></div>
             : ''
            }
          </Accordion.Header>
          <Accordion.Body>
            <Card2
              // icon='/media/svg/brand-logos/plurk.svg'
              badgeColor='primary'
              status={translate[candidateState.situation_pro_doc_status]}
              statusColor='primary'
              title='Situation professionnelle'
              description={translate[candidateState.situation_pro]}
              date='November 10, 2021'
              budget='$284,900.00'
              doc_url={candidateState.situation_pro_doc_url}
              // users={users1}
            />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey='3'>
          <Accordion.Header>
            Situation financière
            {situFinState  ==="ok"? 
              <div data-toggle="tooltip" title="document valide"><KTSVG path="/media/icons/duotune/files/fil016.svg" className="svg-icon-success svg-icon-2hx" /></div>
             : situFinState  ==="no"?
             <div title="document invalide"><KTSVG path="/media/icons/duotune/files/fil015.svg" className="svg-icon-danger svg-icon-2hx" /></div>
             : ''
            }
          </Accordion.Header>
          <Accordion.Body>
            {candidateState.revenus_array_all_df.map((rev: any, index :number) => (
              <Card2
                key = {index}
                // icon='/media/svg/brand-logos/plurk.svg'
                badgeColor='primary'
                status={translate[rev.income_doc_status]}
                statusColor='primary'
                title={translate[rev.income_type]}
                description={rev.income_value + ' €'}
                date='November 10, 2021'
                budget='$284,900.00'
                doc_url={rev.income_file}
                // users={users1}
              />
            ))}
            {/* candidateState.revenus_value Somme des Revenus
income_type
income_value
income_file
income_doc_status */}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='4'>
          <Accordion.Header>
            Avis d'impôt
            {taxState  ==="ok"?
              <div title="document valide"><KTSVG path="/media/icons/duotune/files/fil016.svg" className="svg-icon-success svg-icon-2hx" /></div>
             : taxState ==="no"? 
              <div title="document invalide"><KTSVG path="/media/icons/duotune/files/fil015.svg" className="svg-icon-danger svg-icon-2hx" /></div>
             : ''
            }
          </Accordion.Header>
          <Accordion.Body>
            <Card2
              // icon='/media/svg/brand-logos/plurk.svg'
              badgeColor='primary'
              status={translate[candidateState.tax_doc_status]}
              statusColor='primary'
              title="Avis d'impôt"
              description={translate[candidateState.tax_type]}
              date='November 10, 2021'
              budget='$284,900.00'
              doc_url={candidateState.tax_doc_url}
              // users={users1}
            />
          </Accordion.Body>
        </Accordion.Item>
        {candidateState.guarantor_type == 'NATURAL_PERSON' &&
          candidateState.guarantors.map((garant: any, idx: any) => (
            // <p> {garant.firstName} </p>

            <Accordion.Item eventKey={5 + idx}>
              <Accordion.Header>Garant physique N° {idx + 1} du locataire </Accordion.Header>
              <Accordion.Body>
                <p> {candidateState.guarantors[idx].garant_name} </p>

                <div className='card-body py-0'>
                  {/* begin::Table container */}
                  <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                      {/* begin::Table head */}
                      <thead>
                        <tr className='fw-bolder text-muted'>
                          <th className='min-w-150px'>Documents</th>
                          {/* <th className='min-w-140px'>Mis à jour le</th> */}
                          <th className='min-w-120px'>Labélisation par l'Etat</th>
                          <th className='min-w-120px'>Consulter le documents</th>
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                        {/* /////////////// */}

                        <tr>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                  Pièce d'identité
                                </a>
                                <span className='text-muted fw-bold text-muted d-block fs-7'>
                                  {translate[garant.garant_identification_type]}
                                </span>
                              </div>
                            </div>
                          </td>

                          <td>
                            <a
                              href='#'
                              className='text-dark fw-bolder text-hover-primary d-block fs-6'
                            >
                              {translate[garant.garant_identification_doc_status]}
                            </a>
                          </td>
                          <td>
                            <a target='_blank' href={garant.garant_identification_doc_url}>
                              <button className='btn btn-sm bg-light-primary border-primary border mx-2'>
                                <KTSVG
                                  path='/media/icons/duotune/general/gen016.svg'
                                  className='svg-icon-3'
                                />
                                <span> Cliquer pour télécharger </span>
                              </button>
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                  Situation d'hébergement
                                </a>
                                <span className='text-muted fw-bold text-muted d-block fs-7'>
                                  {translate[garant.garant_hebergement]}
                                </span>
                              </div>
                            </div>
                          </td>

                          <td>
                            <a
                              href='#'
                              className='text-dark fw-bolder text-hover-primary d-block fs-6'
                            >
                              {translate[garant.garant_hebergement_doc_status]}
                            </a>
                          </td>
                          <td>
                            <a target='_blank' href={garant.garant_hebergement_doc_url}>
                              <button className='btn btn-sm bg-light-primary border-primary border mx-2'>
                                <KTSVG
                                  path='/media/icons/duotune/general/gen016.svg'
                                  className='svg-icon-3'
                                />
                                <span> Cliquer pour télécharger </span>
                              </button>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                  Situation professionnelle
                                </a>
                                <span className='text-muted fw-bold text-muted d-block fs-7'>
                                  {translate[garant.garant_situation_pro]}
                                </span>
                              </div>
                            </div>
                          </td>

                          <td>
                            <a
                              href='#'
                              className='text-dark fw-bolder text-hover-primary d-block fs-6'
                            >
                              {translate[garant.garant_situation_pro_doc_status]}
                            </a>
                          </td>
                          <td>
                            <a target='_blank' href={garant.garant_situation_pro_doc_url}>
                              <button className='btn btn-sm bg-light-primary border-primary border mx-2'>
                                <KTSVG
                                  path='/media/icons/duotune/general/gen016.svg'
                                  className='svg-icon-3'
                                />
                                <span> Cliquer pour télécharger </span>
                              </button>
                            </a>
                          </td>
                        </tr>

                        {garant.garant_revenus_array_all_df.map((rev: any, i: number) => (
                          <tr>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  <a
                                    href='#'
                                    className='text-dark fw-bolder text-hover-primary fs-6'
                                  >
                                    Revenu - N° {i + 1}
                                  </a>
                                  <span className='text-muted fw-bold text-muted d-block fs-7'>
                                    {translate[rev.income_type]}
                                  </span>
                                  <span className=' fw-bold d-block fs-7'>{rev.monthly_sum}</span>
                                </div>
                              </div>
                            </td>

                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block fs-6'
                              >
                                {translate[rev.income_doc_status]}
                              </a>
                            </td>
                            <td>
                              <a target='_blank' href={rev.document_income_url}>
                                <button className='btn btn-sm bg-light-primary border-primary border mx-2'>
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen016.svg'
                                    className='svg-icon-3'
                                  />
                                  <span> Cliquer pour télécharger </span>
                                </button>
                              </a>
                            </td>
                          </tr>
                        ))}

                        <tr>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                  Avis d'impôt
                                </a>
                                <span className='text-muted fw-bold text-muted d-block fs-7'>
                                  {translate[garant.garant_tax_type]}
                                </span>
                              </div>
                            </div>
                          </td>

                          <td>
                            <a
                              href='#'
                              className='text-dark fw-bolder text-hover-primary d-block fs-6'
                            >
                              {translate[garant.garant_tax_doc_status]}
                            </a>
                          </td>
                          <td>
                            <a target='_blank' href={garant.garant_tax_doc_url}>
                              <button className='btn btn-sm bg-light-primary border-primary border mx-2'>
                                <KTSVG
                                  path='/media/icons/duotune/general/gen016.svg'
                                  className='svg-icon-3'
                                />
                                <span> Cliquer pour télécharger </span>
                              </button>
                            </a>
                          </td>
                        </tr>
                        {/* ////////////////// */}
                      </tbody>
                      {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        {candidateState.guarantor_type == 'ORGANISM' && (
          <Accordion.Item eventKey='5'>
            <Accordion.Header>Organisme garant de locataire </Accordion.Header>
            <Accordion.Body>
              {' '}
              <a target='_blank' href={candidateState.guarantors[0].garant_identification_doc_url}>
                <button className='btn btn-sm bg-light-primary border-primary border mx-2'>
                  <KTSVG path='/media/icons/duotune/general/gen016.svg' className='svg-icon-3' />
                  <span> Cliquer pour télécharger le document justificatif de l'organisme</span>
                </button>
              </a>
            </Accordion.Body>
          </Accordion.Item>
        )}

        {candidateState.guarantor_type == 'LEGAL_PERSON' && (
          <Accordion.Item eventKey='5'>
            <Accordion.Header>Garant moral de locataire </Accordion.Header>
            <Accordion.Body>
              <p> nom de représentant: {candidateState.guarantors[0].representant_name} </p>
              <p> nom de l'entreprise: {candidateState.guarantors[0].legal_name} </p>
              <div className='card-body py-0'>
                <div className='table-responsive'>
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    <thead>
                      <tr className='fw-bolder text-muted'>
                        <th className='min-w-150px'>Documents</th>
                        <th className='min-w-120px'>Labélisation par l'Etat</th>
                        <th className='min-w-120px'>Consulter le documents</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                Pièce d'identité de représentant
                              </a>
                              <span className='text-muted fw-bold text-muted d-block fs-7'>
                                {translate[candidateState.guarantors[0].garant_identification_type]}
                              </span>
                            </div>
                          </div>
                        </td>

                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bolder text-hover-primary d-block fs-6'
                          >
                            {
                              translate[
                                candidateState.guarantors[0].garant_identification_doc_status
                              ]
                            }
                          </a>
                        </td>
                        <td>
                          <a
                            target='_blank'
                            href={candidateState.guarantors[0].garant_identification_doc_url}
                          >
                            <button className='btn btn-sm bg-light-primary border-primary border mx-2'>
                              <KTSVG
                                path='/media/icons/duotune/general/gen016.svg'
                                className='svg-icon-3'
                              />
                              <span> Cliquer pour télécharger </span>
                            </button>
                          </a>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                identité de la personne moral
                              </a>
                            </div>
                          </div>
                        </td>

                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bolder text-hover-primary d-block fs-6'
                          >
                            {translate[candidateState.guarantors[0].legal_doc_status]}
                          </a>
                        </td>
                        <td>
                          <a
                            target='_blank'
                            href={candidateState.guarantors[0].legal_person_df_url}
                          >
                            <button className='btn btn-sm bg-light-primary border-primary border mx-2'>
                              <KTSVG
                                path='/media/icons/duotune/general/gen016.svg'
                                className='svg-icon-3'
                              />
                              <span> Cliquer pour télécharger </span>
                            </button>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        )}
      </Accordion>
    </div>
  )
}

export {TenantDoc}
