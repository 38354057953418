/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { ErrorMessage } from 'formik'
import Select from 'react-select'

// const MyComponent = (list:any) => (
//   <Select options={list} />
// )
const Etape3: React.FC<{handleInputChange:any,onChangeSelect:any, onChange: any, state: any }> = ({handleInputChange,onChangeSelect, onChange, state }) => {
  return (
    <div className='w-100' key={`etape3`}>
 
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>Adresse de bien</span>
        </label>
        <Select 
      name='address'
      // isClearable={true}
      onChange={onChangeSelect}  
      options={state.list_address}
      getOptionLabel={(option:any) => "" || option?.properties?.label }
      getOptionValue={(option: any) => "" || option?.properties?.label}
      onInputChange={handleInputChange}
      />


        {/* <input
          type='text'
          className='form-control form-control-lg form-control-solid'
          name={`property_address`}
          placeholder=''
          onChange={onChange}
          value={state.property_address}
        /> */}
        <div className='text-danger mt-2'>
          <ErrorMessage name={`firstname`} />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>Complément d'adresse</span>
        </label>
        <input
          type='text'
          className='form-control form-control-lg form-control-solid'
          name={`complement_adresse`}
          placeholder=''
          onChange={onChange}
          value={state.complement_adresse}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={`firstname`} />
        </div>
      </div>

      {/* <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>Numéro de lot</span>
        </label>
        <input
          type='number'
          className='form-control form-control-lg form-control-solid'
          name={`batch_number`}
          placeholder=''
          onChange={onChange}
          value={state.batch_number}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={`firstname`} />
        </div>
      </div> */}
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>Surface habitable</span>
        </label>
        <input
          type='number'
          className='form-control form-control-lg form-control-solid'
          name={`living_space`}
          placeholder=''
          onChange={onChange}
          value={state.living_space}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={`firstname`} />
        </div>
      </div>

   
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>Type de bien</span>
        </label>
        <select
          name='property_type'
          className='form-select form-select-lg form-select-solid'
          onChange={onChange}
          value={state.property_type}
        >
          <option value="" disabled>choisir le type du logement </option>
          <option value='Maison'>Maison</option>
          <option value='Appartement'>Appartement</option>

        </select> 
      </div>

      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>Nombre de pièces</span>
        </label>
        <input
          type='number'
          className='form-control form-control-lg form-control-solid'
          name={`rooms_number`}
          placeholder=''
          onChange={onChange}
          value={state.rooms_number}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={`firstname`} />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'> Régime juridique</span>
        </label>

        <select
          name='legal_system'
          className='form-select form-select-lg form-select-solid'
          onChange={onChange}
          value={state.legal_system}
        >
          <option value="" disabled>choisir le système juridique </option>
          <option value='Coproprieté'>Copropriété</option>
          <option value='Monopropriété'>Monopropriété</option>

        </select>



        <div className='text-danger mt-2'>
          <ErrorMessage name={`legal_system`} />
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>Période de construction</span>
        </label>


        <select
          name='building_period'
          className='form-select form-select-lg form-select-solid'
          onChange={onChange}
          value={state.building_period}
        >
          <option value="" disabled>choisir la période</option>
          <option value='avant 1949'>avant 1949</option>
          <option value='Entre 1949 et 1974'>Entre 1949 et 1974</option>
          <option value='Entre 1975 et 1989'>Entre 1975 et 1989</option>
          <option value='Entre 1990 et 2005'>Entre 1990 et 2005</option>
          <option value='après 2005'>après 2005</option>

        </select>



        <div className='text-danger mt-2'>
          {/* <ErrorMessage name={`firstname`} /> */}
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>Usage</span>
        </label>
        <select
          name='property_use'
          className='form-select form-select-lg form-select-solid'
          onChange={onChange}
          value={state.property_use}
        >
          <option value="" disabled>choisir l'usage</option>
          <option value='Habitation'>Habitation</option>
          <option value='Mixte'>Mixte</option>

        </select>
        <div className='text-danger mt-2'>
          <ErrorMessage name={`property_use`} />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>Equipements d'accès IT</span>
        </label>

        <select
          name='equipment_it_access'
          className='form-select form-select-lg form-select-solid'
          onChange={onChange}
          value={state.equipment_it_access}
        >
          <option value="" disabled>choisir l'équipements d'accès IT</option>
          <option value='Aucun'>Aucun</option>
          <option value='ADSL'>ADSL</option>
          <option value='Fibre'>Fibre</option>
        </select>

        <div className='text-danger mt-2'>
          <ErrorMessage name={`firstname`} />
        </div>
      </div>


    </div>
  )
}

export { Etape3 }
