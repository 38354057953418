/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC,useState,useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../_metronic/layout/core'
import { ListCandidats } from '../modules/properties/ListCandidats'
import { PropertyModel ,initsProperty} from '../modules/properties/models/PropertyModel'
import { assignTenant, getPropertiesCandidatesList,deleteAssignement } from '../modules/properties/propertiesController'
import toast from 'react-hot-toast'
import swal from 'sweetalert';


const CandidatePage: FC = () => {
  const [StateProperty, setStateProperty] = useState<PropertyModel>(initsProperty)


  useEffect(() => {
    loader(true)
    getlistpropcandidates()
    

  }, [])

  const getlistpropcandidates=()=>{
    getPropertiesCandidatesList().then(response => {
      loader(false)
      setStateProperty(prevValues => ({
        ...prevValues,
        properties_list: response.data
      }))
    })

  }

  const addTenant = () => {
    loader(true)
    const formDataAssign = new FormData();
    formDataAssign.append('property', StateProperty.prop_id)
    formDataAssign.append('email', StateProperty.tenant_email)

    assignTenant(formDataAssign)
      .then(response => {
        getlistpropcandidates()
        toast.success('invitation locataire envoyée avec succes!')
      })
      .catch(error => {
        loader(false)
        toast.error('une erreur s\'est produite!')

      })
  }
  const removeTenant = (id:any) => {
    // loader(true)
    const formRemove = new FormData();
    formRemove.append('assignement_id',id)
    swal({
      title: "Êtes-vous sûr?",
      text: "Once deleted, you will not be able to recover this candidate!",
      icon: "warning",
      // buttons: true,
      dangerMode: true,
  })
    .then((willDelete) => {
        if (willDelete) {
            deleteAssignement(formRemove)
            .then(response => {
                    swal("Your candidate has been deleted!", {
                      icon: "success",
                    });
                    getlistpropcandidates()
                  })
                .catch(error => {
                  swal("Your candidate is safe!");
                });
        } else {
          swal("Your  candidate is safe!");
        }
    });
  }
  const handleChange = (event: any) => {
    setStateProperty(prevValues => ({
      ...prevValues,
      [event.target.name]: event.target.value,
    }))
  }
  const loader = (value: boolean) => {
    setStateProperty(prevValues => ({
      ...prevValues,
      loader: value
    }))
  }
  

  return (
    <>
      <PageTitle breadcrumbs={[]}>Mes Logements</PageTitle>
      <ListCandidats removeTenant={removeTenant} addTenant={addTenant} className='mb-5 mb-xl-8' onchange={handleChange}  properties={StateProperty.properties_list} state={StateProperty} />
    </>
  )
}

export {CandidatePage}
