/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import * as auth from '../redux/AuthRedux'
import {register} from '../redux/AuthCRUD'
import {Link, useHistory, useParams} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {resetPassword} from '../../tenant-file/userController'
import toast from 'react-hot-toast'
const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  changepassword: '',
  acceptTerms: false,
  // zipcode: '',
  // phone: '',
  accountGoal: ''
}

const registrationSchema = Yup.object().shape({
  password: Yup.string()
    .min(3, 'Au moins 3 symboles')
    .max(50, '50 symboles maximum')
    .required('Le mot de passe est obligatoire'),
  changepassword: Yup.string()
    .required('La confirmation du mot de passe est requise')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        'Le mot de passe et la confirmation du mot de passe ne correspondent pas'
      ),
    }),
})

export function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const [statusForm, setStatusForm] = useState(false)
  const history = useHistory()
  const { id } :any= useParams();
  const { tok } :any= useParams();
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        const formDataRegisterAccount = new FormData()
        formDataRegisterAccount.append('new_password1', values.password)
        formDataRegisterAccount.append('new_password2', values.changepassword)
        formDataRegisterAccount.append('uid', id)
        formDataRegisterAccount.append('token', tok)
        resetPassword(formDataRegisterAccount,id,tok)
          .then((response: any) => {
            if (response.status === 200) {
              toast.success('Mot de passe réinitialisé avec succès !')
              setLoading(false)
              setStatusForm(true)
              setSubmitting(true)
              history.push("/auth")

            } else {
              toast.error("Une erreur s'est produit")
              setStatus("Une erreur s'est produit")
              setLoading(false)
            }
            
          })
          .catch((error: any) => {
            setLoading(false)
            setSubmitting(false)
            setStatus("Une erreur s'est produit")
            toast.error("Une erreur s'est produit")
          })
      }, 1000)
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='mcl_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Réinitialisation du mot de passe</h1>
        {/* end::Title */}

        {/* begin::Link */}
        {statusForm && 
          <div className='mb-lg-15 alert alert-success'>
            <div className='alert-text font-weight-bold'>Mot de passe réinitialisé avec succès</div>
          </div>
        }
        {/* end::Link */}
      </div>
      {/* end::Heading */}

      {/* <button type='button' className='btn btn-light-primary fw-bolder w-100 mb-10'>
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
          className='h-20px me-3'
        />
        Se connecter avec Google
      </button>

      <div className='d-flex align-items-center mb-10'>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        <span className='fw-bold text-gray-400 fs-7 mx-2'>OU</span>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
      </div> */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Password */}
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Nouveau mot de passe</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder=''
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container text-danger'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirmez le mot de passe</label>
        <input
          type='password'
          placeholder=''
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container text-danger'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      <div className='text-center'>
        <button
          type='submit'
          id='mcl_sign_up_submit'
          className='btn btn-lg btn-primary w-100 my-2' 
          disabled={formik.isSubmitting}
        >
          {!loading && <span className='indicator-label'>Valider</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Chargement...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='mcl_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 my-2'
          >
            Annuler
          </button>
        </Link>
      </div>
    </form>
  )
}
