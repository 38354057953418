import { cpuUsage } from "process"

export const API_URL = process.env.REACT_APP_API_URL || 'api'

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/users/user/`
export const LOGIN_URL = `${API_URL}/users/login/`
export const LOGOUT_URL = `${API_URL}/users/logout/`
export const REGISTER_URL = `${API_URL}/users/registration/`
export const ACTIVATION_URL = `${API_URL}/users/account-confirm-email/`
export const REQUEST_PASSWORD_URL = `${API_URL}/users/reset-password/`
export const RESET_PASSWORD_URL = `${API_URL}/users/password/reset/confirm/`
export const REFRESH_URL = `${API_URL}/users/token/refresh/`
export const EDIT_ACCOUNT_URL = `${API_URL}/users/edit-user/`
export const DELETE_ACCOUNT_URL = `${API_URL}/users/delete-account/`

export const VALIDATE_DOC_URL = `${API_URL}/tf/honor-declaration/`

export const DELETE_FILE_FROM_DB = `${API_URL}/tf/delete-file-from-local/`
export const DELETE_FILE_URL = `${API_URL}/tf/delete-file/`
export const DELETE_DOC_URL = `${API_URL}/tf/delete-document/`
export const GET_PROFILE_URL = `${API_URL}/tf/tenant-profile/`


export const STEP_ONE_EMAIL_URL = `${API_URL}/tf/create-tenant-account/`
export const STEP_ONE_INFO_URL = `${API_URL}/tf/tenant-information/`
export const STEP_ONE_URL = `${API_URL}/tf/identification/`
export const STEP_TWO_RENT_URL = `${API_URL}/tf/rent-type/`
export const STEP_TWO_RESIDENCY_URL = `${API_URL}/tf/residency/`
export const STEP_THREE_URL = `${API_URL}/tf/professional/`

export const STEP_FOUR_URL = `${API_URL}/tf/income/`
export const STEP_FIVE_URL = `${API_URL}/tf/tax/`

export const GAR_CREATE_URL = `${API_URL}/tf/guarantor/create/`
export const GAR_DELETE_URL = `${API_URL}/tf/guarantor/delete/`

export const GAR_STEP_ONE_URL = `${API_URL}/tf/guarantor/natural-person/identification/`
export const GAR_STEP_TWO_URL = `${API_URL}/tf/guarantor/natural-person/residency/`
export const GAR_STEP_THREE_URL = `${API_URL}/tf/guarantor/natural-person/professional/`
export const GAR_STEP_FOUR_URL = `${API_URL}/tf/guarantor/natural-person/income/`
export const GAR_STEP_FIVE_URL = `${API_URL}/tf/guarantor/natural-person/tax/`

export const GAR_ORGANISM = `${API_URL}/tf/guarantor/organism/`

export const GAR_LEGAL_STEP_ONE = `${API_URL}/tf/guarantor/legal-person/identification/`
export const GAR_LEGAL_STEP_TWO = `${API_URL}/tf/guarantor/legal-person/representant/`
//////////////////////////////////////////////////////////////////////////////////////////////////////
export const PROPERTIES = `${API_URL}/properties/`
export const LIST_CANDIDATES = `${API_URL}/properties-candidates/`
export const LIST_PROPERTIES = `${API_URL}/properties-inv-cand/`

export const ASSIGN_TENANT = `${API_URL}/assign-tenant`
export const DELETE_ASSIGNEMENT = `${API_URL}/owner-remove-assignement`
export const TENANT_DELETE_ASSIGNEMENT = `${API_URL}/tenant-remove-assignement`
export const GET_TENANT_FILE = `${API_URL}/candidate-tenant-file`
export const TENANT_ASSIGNEMENTS = `${API_URL}/assignements-tenant/`
export const MANAGE_ASSIGNEMENTS = `${API_URL}/tenant-folder-share`

export const GENERATE_CONTRACT = `${API_URL}/tf/generatePDF/`
export const VALIDATE_TENANT_FOLDER = `${API_URL}/tf/verifytf/`

////////////////////////////////////////////////////////////////////////////////////////////////////
/* Simulateur-dpe */
export const DEPERDITION_Mur = `${API_URL}/deperdition-mur`