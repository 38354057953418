/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {checkIsActive, KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { useLocation } from 'react-router-dom'

export function AsideMenuMain() {
  const intl = useIntl()
  const user = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const {pathname} = useLocation()
  // console.log('USER ::: ', user)
  if(user.account_goal === 1)
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/graphs/gra001.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {/* locataire
      <AsideMenuItem
        to='/addressbook'
        icon='/media/icons/duotune/communication/com005.svg'
        title="Carnet d'adresse"
        fontIcon='bi-layers'
      /> */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Carnet d'information du logement</span>
        </div>
      </div> */}
      
      <AsideMenuItem
        to='/properties'
        title='Mes Logements'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen001.svg' 
      />
      <AsideMenuItem
        to='#'
        title="Carnet d'information du logement"
        fontIcon='bi-archive'
        icon='/media/icons/duotune/files/fil016.svg' 
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Gestion Dossier Locataire</span>
        </div>
      </div>
      <AsideMenuItem
        to='/candidats'
        title='Les Candidats Locataires'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/abstract/abs015.svg'
      />
      
      <div className='menu-item'>
        <a 
          href='https://moncarnetlogement.fr/garantie-loyer/' 
          className={clsx('menu-link without-sub', {active: checkIsActive(pathname, 'https://moncarnetlogement.fr/garantie-loyer/')})}
          target='_blank'
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/finance/fin001.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Garantie Loyer Impayé</span>
        </a>
      </div>

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Assurances</span>
        </div>
      </div>

      <div className='menu-item'>
        <a 
          href='https://moncarnetlogement.fr/assurance-habitation/' 
          className={clsx('menu-link without-sub', {active: checkIsActive(pathname, 'https://moncarnetlogement.fr/assurance-habitation/')})}
          target='_blank'
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen048.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Assurance Habitation</span>
        </a>
      </div>

      <div className='menu-item'>
        <a 
          href='https://moncarnetlogement.fr/assurance-emprunteur/' 
          className={clsx('menu-link without-sub', {active: checkIsActive(pathname, 'https://moncarnetlogement.fr/assurance-emprunteur/')})}
          target='_blank'
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen049.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Assurance Emprunteur</span>
        </a>
      </div>
       
      {/* <AsideMenuItemWithSub
        to='#'
        title='Assistant Location'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen049.svg'
      > */}
        {/*
        en attente de la partie contractuelle 
        <AsideMenuItem
          to='/tenants'
          title='Gestions des locataires'
          hasBullet={true}
        /> */}
        {/* hided to be completed */}
        {/* <AsideMenuItem
          to='/crafted/pages/wizards/vertical'
          title='Bail de location'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/crafted/pages/wizards/vertical'
          title='Etat des lieux'
          hasBullet={true}
        /> */}
      {/* </AsideMenuItemWithSub> */}
      
      

      
      
      
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Services</span>
        </div>
      </div>
      {/* hided to be complete
      <AsideMenuItemWithSub
        to='#'
        title='Assurances'
        icon='/media/icons/duotune/general/gen048.svg'
        fontIcon='bi-archive'
      >
       
        
        <AsideMenuItem
          to='#'
          title='Assurance PNO'
          hasBullet={true}
        />
        <AsideMenuItem
          to='#'
          title='Assurance Habitation'
          hasBullet={true}
        />
      </AsideMenuItemWithSub>  */}
      <AsideMenuItem
        to='/services/estimate'
        title='Estimations'
        icon='/media/icons/duotune/maps/map007.svg'
        fontIcon='bi-layers'
      />
      {/* <AsideMenuItem
        to='/services/homestaging'
        title='Home-Staging Virtuel'
        icon='/media/icons/duotune/art/art002.svg'
        fontIcon='bi-archive'
      /> */}
      <div className='menu-item'>
        <a 
          href='https://moncarnetlogement.fr/home-staging-virtuel/' 
          className={clsx('menu-link without-sub', {active: checkIsActive(pathname, 'https://moncarnetlogement.fr/home-staging-virtuel/')})}
          target='_blank'
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/art/art002.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Home-Staging Virtuel</span>
        </a>
      </div>
      {/* <AsideMenuItem
        to='/services/energyopt'
        title='Rénovation Energétique'
        icon='/media/icons/duotune/abstract/abs013.svg'
        fontIcon='bi-archive'
      /> */}
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
  else
    return (
      <>
        <AsideMenuItem
          to='/dashboard'
          icon='/media/icons/duotune/graphs/gra001.svg'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          fontIcon='bi-app-indicator'
        /> 

        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Mon Dossier Locataire</span>
          </div>
        </div>
        
        <AsideMenuItem
          to='/tenant-file/create'
          title='Mon Dossier'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/files/fil012.svg'
        />

        <AsideMenuItem
          to='/candidature'
          title='Mes Candidatures'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen028.svg'
        />
      
        
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Services</span>
          </div>
        </div>
        {/* hided to be complete
        <AsideMenuItemWithSub
          to='#'
          title='Assurances'
          icon='/media/icons/duotune/general/gen048.svg'
          fontIcon='bi-archive'
        >
        
          
          <AsideMenuItem
            to='#'
            title='Assurance PNO'
            hasBullet={true}
          />
          <AsideMenuItem
            to='#'
            title='Assurance Habitation'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>  */}
        <AsideMenuItem
          to='#'
          title='Assurance Habitation'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen048.svg'
        />
        <AsideMenuItem
          to='/services/estimate'
          title='Estimations'
          icon='/media/icons/duotune/maps/map007.svg'
          fontIcon='bi-layers'
        />
        {/* <AsideMenuItem
          to='/services/homestaging'
          title='Home-Staging Virtuel'
          icon='/media/icons/duotune/art/art002.svg'
          fontIcon='bi-archive'
        /> */}
        <div className='menu-item'>
          <a 
            href='https://moncarnetlogement.fr/home-staging-virtuel/' 
            className={clsx('menu-link without-sub', {active: checkIsActive(pathname, 'https://moncarnetlogement.fr/home-staging-virtuel/')})}
            target='_blank'
          >
            <span className='menu-icon'>
              <KTSVG path='/media/icons/duotune/art/art002.svg' className='svg-icon-2' />
            </span>
            <span className='menu-title'>Home-Staging Virtuel</span>
          </a>
        </div>
        {/* <AsideMenuItem
          to='/services/energyopt'
          title='Rénovation Energétique'
          icon='/media/icons/duotune/abstract/abs013.svg'
          fontIcon='bi-archive'
        /> */}
        <div className='menu-item'>
          <a 
            href='https://moncarnetlogement.fr/renovation-energetique/' 
            className={clsx('menu-link without-sub', {active: checkIsActive(pathname, 'https://moncarnetlogement.fr/renovation-energetique/')})}
            target='_blank'
          >
            <span className='menu-icon'>
              <KTSVG path='/media/icons/duotune/abstract/abs013.svg' className='svg-icon-2' />
            </span>
            <span className='menu-title'>Rénovation Energétique</span>
          </a>
        </div>
        
        <div className='menu-item'>
          <div className='menu-content'>
            <div className='separator mx-1 my-4'></div>
          </div>
        </div>
        {/* <div className='menu-item'>
          <a
            target='_blank'
            className='menu-link'
            href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
          >
            <span className='menu-icon'>
              <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
            </span>
            <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
          </a>
        </div> */}
      </>
    )
}
