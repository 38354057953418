import { ErrorMessage } from 'formik'
import { TypeGenerateurEcs, VolumeBallon,TypeGenerateurCh } from './typeadjacence'
import { KTSVG } from '../../../../_metronic/helpers'
const SimulateurStep9: React.FC<{addEcs:any, editEcs:any,deleteEcs:any ,onChange: any, state: any }> = ({addEcs,editEcs, deleteEcs,onChange, state }) => {

     
  
    return (
        <div className='d-flex flex-column flex-lg-row'>
            <div className='flex-column flex-lg-row-auto w-100 w-lg-400px w-xl-550px mb-10 mb-lg-0'>
            <TypeGenerateurEcs onChange={onChange} statevalue={state.type_generateur_ecs} name="type_generateur_ecs" />

            <VolumeBallon onChange={onChange} statevalue={state.volume_ballon} name="volume_ballon" />
            <TypeGenerateurCh onChange={onChange} statevalue={state.type_generateur_ch} name="type_generateur_ch" />
                <div className='fv-row mb-10'>

                    <label className='form-label required mb-2'>Type reseau collectif </label>

                    <input
                        type='number'
                        className='form-control form-control-lg form-control-solid border-dark'
                        name={`type_reseau_collectif`}
                        placeholder=''
                        onChange={onChange}
                        value={state.type_reseau_collectif}
                    />
                    <div className='text-danger mt-2'>
                        <ErrorMessage name={`type_reseau_collectif`} />
                    </div>
                </div>
                <div className='fv-row mb-10'>

                    <label className='form-label required mb-2'>Configuration logement </label>

                    <input
                        type='number'
                        className='form-control form-control-lg form-control-solid border-dark'
                        name={`configuration_logement`}
                        placeholder=''
                        onChange={onChange}
                        value={state.configuration_logement}
                    />
                    <div className='text-danger mt-2'>
                        <ErrorMessage name={`configuration_logement`} />
                    </div>
                </div>
                <div className='fv-row mb-10'>
                    <label className='form-label required mb-2'>
                        Type d'installation
                    </label>
                    <select
                        name='type_installation'
                        className='form-select form-select-lg form-select-solid border-dark'
                        onChange={onChange}
                        value={state.type_installation}
                    >
                        <option value="" disabled>choisir </option>
                        <option value='1'>individuelle</option>
                        <option value='2'>collective</option>

                    </select>
                </div>
        
                <br />
                <button type="button" className='btn btn-lg btn-info w-100 ' onClick={addEcs}>
                    <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                    Sauvegarder
                </button>
            </div>

            <div className='flex-lg-row-fluid ms-lg-7  w-xl-400px ms-xl-10'>
                <div className='card card-xl-stretch mb-5 mb-xl-8'>


                    <div className='card-body pt-0' style={{ overflowY: "scroll", height: "650px" }}>

                        {state.array_ecs.length > 0 && state.array_ecs.map((ecs: any, idx: any) => (<>
                            <div className='d-flex align-items-center bg-light-info rounded p-5'>
                                <span className='svg-icon svg-icon-info me-5'>
                                    <KTSVG path='/media/icons/duotune/general/gen028.svg' className='svg-icon-1' />
                                </span>

                                <div className='flex-grow-1 me-2'>
                                    <a href='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>
                                        ecs n° {idx + 1} 
                                    </a>
                                    <span className=' fw-bold d-block'> {ecs.type_generateur_ecs} </span>
                                </div>
                                <button type="button" className='btn btn-sm btn-warning me-5' onClick={e => editEcs(idx)}>
                                    <span className='svg-icon svg-icon-warning'>
                                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-1' />
                                    </span>
                                </button>
                                <button type="button" className='btn btn-sm btn-danger ' onClick={e => deleteEcs(idx)}>
                                    <span className='svg-icon svg-icon-danger'>
                                        <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-1' />
                                    </span></button>
                            </div>
                            <br />
                        </>
                        ))}

                    </div>
                </div>
            </div>
        </div>
    )
}
export {SimulateurStep9}