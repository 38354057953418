/* eslint-disable jsx-a11y/anchor-is-valid */
import { Formik, Form, ErrorMessage } from 'formik'
import React, { useEffect, useState } from 'react'
import state from 'sweetalert/typings/modules/state'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { PropertyModel, initsProperty } from '../properties/models/PropertyModel'
import { addProperty, assignTenant, getPropertiesList ,  deleteProperty} from './propertiesController'
import toast from 'react-hot-toast'
import swal from 'sweetalert';

type Props = {
  className: string
  // properties: Array<PropertyModel>
  // state: any
  // onchange: any
  // setStateProperty: any
}

const ListProperties: React.FC<Props> = ({ className }) => {
  const [StateProperty, setStateProperty] = useState<PropertyModel>(initsProperty)

  useEffect(() => {
    loader(true)
    getlistProperties()

  }, [])
  const getlistProperties = () => {
    getPropertiesList().then(response => {
      loader(false)
      setStateProperty(prevValues => ({
        ...prevValues,
        properties_list: response.data
      }))

    })
  }

  const handleChange = (event: any) => {
    setStateProperty(prevValues => ({
      ...prevValues,
      [event.target.name]: event.target.value,
    }))
  }

  const createProperty = () => {
    loader(true)
    const formDataProperty = new FormData();
    formDataProperty.append('loyer', StateProperty.loyer)
    formDataProperty.append('surface', StateProperty.surface)
    formDataProperty.append('address', StateProperty.address)
    formDataProperty.append('typology', StateProperty.typology)
    formDataProperty.append('category', StateProperty.category)
    formDataProperty.append('lot', StateProperty.lot)
    addProperty(formDataProperty)
      .then(response => {
        getlistProperties()
        toast.success('Bien ajouté avec succès !')

        //  loader(false)
      })
      .catch(error => {
        toast.error('Une erreur s\'est produite')
        loader(false)
      })
  }
  const loader = (value: boolean) => {
    setStateProperty(prevValues => ({
      ...prevValues,
      loader: value
    }))
  }
  const addTenant = () => {
    loader(true)
    const formDataAssign = new FormData();
    formDataAssign.append('property', StateProperty.prop_id)
    formDataAssign.append('email', StateProperty.tenant_email)

    assignTenant(formDataAssign)
      .then(response => {
        getlistProperties()
        toast.success('invitation locataire envoyée avec succès !')
      })
      .catch(error => {
        toast.error('Une erreur s\'est produite')
        loader(false)
      })
  }
  const saveID = (id: any) => {

    setStateProperty((prevValues: any) => ({
      ...prevValues, prop_id: id
    }))
    // console.log(id)
  }
  const removeProperty  = (id:any) => {
    // loader(true)
    swal({
      title: "Êtes-vous sûr?",
      text: "Once deleted, you will not be able to recover this property!",
      icon: "warning",
      // buttons: true,
      dangerMode: true,
  })
      .then((willDelete) => {
          if (willDelete) {
              deleteProperty(id)
              .then(response => {
                      swal("Your property has been deleted!", {
                          icon: "success",
                      });
                      getlistProperties()
                    })
                  .catch(error => {
                      swal("Your property is safe!");
                  });
          } else {
            swal("Your property is safe!");
          }
      });
  }

  return (
    <div className={`card ${className}`} style={StateProperty.loader ? { pointerEvents: "none", color: "grey", background: "#02111e12" } : {}}>

      <div className='bg-white'>  
      {StateProperty.loader && 
        <div>
          <span 
            style={{position: 'relative'}}
            className='spinner-border spinner-border-lg align-middle'>
          </span>
          <span> Chargement... </span>
       </div>
      }
      </div>

      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Mes logements</span>
          {/* <span className='text-muted mt-1 fw-bold fs-7'>Liste de vos logements dont vous êtes propriétaire</span> */}
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Cliquez ici pour ajouter un logement'
        >
          <a
            href='#'
            className='btn btn-sm btn-light-primary'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_add_property'
          >
            <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            Ajouter un logement
          </a>
        </div>
      </div>
      {/* end::Header */}


      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='min-w-150px'>Adresse</th>
                <th className='min-w-140px'>Type/Catégorie</th>
                <th className='min-w-120px'>Invitation</th>
                <th className='min-w-120px'>Candidats</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {StateProperty.properties_list.map((property: any, index:any) => (
                <tr key={index}>
                  <td>
                    <div className='d-flex align-items-center'>
                      {/* <div className='symbol symbol-45px me-5'>
                        <i className="bi bi-flat text-primary"></i>
                      </div> */}
                      <div className='d-flex justify-content-start flex-column'>
                        <span className='text-dark fw-bolder text-hover-primary fs-6'>
                          {property.address}
                        </span>
                        <span className='text-muted fw-bold text-muted d-block fs-7'>
                          {property.address}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                      {property.typology}
                    </span>
                    <span className='text-muted fw-bold text-muted d-block fs-7'>
                      {property.category}
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                      {property.pending_assignements.length}
                    </span>
                  </td>

                  <td>
                    <span className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                      {property.accepted_assignements.length}
                    </span>
                  </td>
                  <td>
                    <div className='d-flex justify-content-end flex-shrink-0'>
                      <button
                        // href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_assign_tenant'
                        onClick={() => saveID(property.id)}
                      >
                        <i className="bi bi-person-plus-fill fs-2x text-primary"></i>
                      </button>
                      {/* <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                      </a> */}
                      <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        onClick={()=>removeProperty(property.id)}
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen027.svg'
                          className='svg-icon-3'
                        />
                      </a>
                    </div>
                  </td>
                </tr>
              ))}

            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

      <div className='modal fade' id='kt_modal_add_property' aria-hidden='true'>
        <div className='modal-dialog mw-850px'>
          <div className='modal-content'>


            <div className='modal-header'>
              <h2>Ajouter un logement</h2>

              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>

            <div className='d-flex flex-row-fluid flex-center bg-white rounded'>
              <Formik enableReinitialize initialValues={initsProperty} onSubmit={createProperty}>
                {() => (

                  <Form className='py-12 w-100 w-xl-700px px-5'  noValidate id='kt_modal_create_app_form'>
                    <div className='row'>
                      <div className='col-6 mb-10'>
                        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                          <span className='required'>Type de logement</span>
                        </label>


                        <select
                          name={`${''}typology`}
                          className='form-select form-select-lg form-select-solid'
                          onChange={handleChange}
                          value={StateProperty.typology}
                        >
                          <option disabled>choisir le type du logement </option>
                          <option value='Maison'>Maison</option>
                          <option value='Appartement'>Appartement</option>

                        </select>
                        <div className='text-danger mt-2'>
                          <ErrorMessage name={`${''}typology`} />
                        </div>

                      </div>

                      <div className='col-6 mb-10'>
                        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                          <span className='required'>Catégorie de logement</span>
                        </label>


                        <select
                          name={`${''}category`}
                          className='form-select form-select-lg form-select-solid'
                          onChange={handleChange}
                          value={StateProperty.category}
                        >
                          <option disabled>choisir le type du logement </option>
                          <option value='T1'>T1</option>
                          <option value='T2'>T2</option>
                          <option value='T3'>T3</option>
                          <option value='T4'>T4</option>
                          <option value='T5'>T5</option>

                        </select>
                        <div className='text-danger mt-2'>
                          <ErrorMessage name={`${''}category`} />
                        </div>

                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-6 mb-10'>
                        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                          <span className='required'>Loyer</span>
                        </label>

                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          name={`${''}loyer`}
                          placeholder='Saisir le loyer'
                          onChange={handleChange}
                        // value={StateProperty.loyer}
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name={`${''}loyer`} />
                        </div>
                      </div>

                      <div className='col-6 mb-10'>
                        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                          <span className='required'>Surface</span>
                          {/* <i
                            className='fas fa-exclamation-circle ms-2 fs-7'
                            data-bs-toggle='tooltip'
                            title='votre code postal'
                          ></i> */}
                        </label>

                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          name={`${''}surface`}
                          placeholder='Saisir la surface'
                          onChange={handleChange}
                        // value={StateProperty.surface}
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name={`${''}surface`} />
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-6 mb-10'>
                        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                          <span className='required'>Adresse </span>
                        </label>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          name={`${''}address`}
                          placeholder='Saisir votre adresse'
                          onChange={handleChange}
                          value={StateProperty.address}
                        />
                        {/* <div className='fv-plugins-message-container invalid-feedback'> */}
                        <div className='text-danger mt-2'>
                          <ErrorMessage name={`${''}address`} />
                        </div>
                      </div>
                      <div className='col-6 mb-10'>
                        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                          <span className='required'>Lot</span>
                          {/* <i
                            className='fas fa-exclamation-circle ms-2 fs-7'
                            data-bs-toggle='tooltip'
                            title='votre lot'
                          ></i> */}
                        </label>

                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          name={`${''}lot`}
                          placeholder='Saisir le lot'
                          onChange={handleChange}
                        // value={StateProperty.surface}
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name={`${''}lot`} />
                        </div>
                      </div>
                    </div>

                    <div className='d-flex flex-stack pt-10'>
                      <div className='mr-2' data-bs-dismiss='modal'>
                        <button
                          type='button'
                          className='btn btn-lg btn-light-primary me-3'
                        >
                          Annuler
                        </button>
                      </div>



                      <div data-bs-dismiss='modal'>

                        <button
                          onClick={createProperty}
                          type='button'
                          className='btn btn-lg btn-primary me-3'>
                          Sauvegarder
                          {/* <KTSVG  path='/media/icons/duotune/arrows/arr064.svg'
                                                className='svg-icon-3 ms-2 me-0'
                                            /> */}
                        </button>
                      </div>
                    </div>


                  </Form>
                )}
              </Formik>

            </div>
          </div>
        </div>

      </div>

      <div className='modal fade' id='kt_modal_assign_tenant' aria-hidden='true'>
        <div className='modal-dialog mw-850px'>
          <div className='modal-content'>

            <div className='modal-header'>
              <h2>Ajout d'un candidat locataire</h2>

              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>

            <div className='d-flex flex-row-fluid flex-center bg-white rounded'>
              <Formik enableReinitialize initialValues={initsProperty} onSubmit={createProperty}>
                {() => (

                  <Form className='py-12 w-100 w-xl-700px px-5' style={StateProperty.loader ? { pointerEvents: "none", color: "grey", background: "#02111e12" } : {}} noValidate id='kt_modal_create_app_form'>


                    <div className='row mb-10'>
                      <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                        <span className='required'>
                          Email
                        </span>
                      </label>

                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        name={`${''}tenant_email`}
                        placeholder='exemple@email.fr'
                        onChange={handleChange}
                      // value={StateProperty.loyer}
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name={`${''}tenant_email`} />
                      </div>
                    </div>


                    <div className='d-flex flex-stack pt-10'>
                      <div className='mr-2' data-bs-dismiss='modal'>
                        <button
                          type='button'
                          className='btn btn-lg btn-light-primary me-3'
                        >
                          Annuler
                        </button>
                      </div>



                      <div data-bs-dismiss='modal'>

                        <button
                          onClick={addTenant}
                          type='button'
                          className='btn btn-lg btn-primary me-3'>
                          Envoyer invitation
                          {/* <KTSVG  path='/media/icons/duotune/arrows/arr064.svg'
                                                className='svg-icon-3 ms-2 me-0'
                                            /> */}
                        </button>
                      </div>
                    </div>


                  </Form>
                )}
              </Formik>

            </div>
          </div>
        </div>

      </div>


    </div>
  )
}

export { ListProperties }
