/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { ErrorMessage } from 'formik'
import { KTSVG } from '../../../../../_metronic/helpers'

const Etape7: React.FC<{ onChange: any, state: any }> = ({ onChange, state }) => {
  return (
    <div className='w-100' key={`etape7`}>
      {/* <p>conditions financières</p> */}
      <div className='fv-row mb-10'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                      <span className='required'>Montant du loyer mensuel (hors charges) </span>
                    </label>
                    <input
                      type='number'
                      className='form-control form-control-lg form-control-solid'
                      name={`monthly_amount`}
                      placeholder=''
                      onChange={onChange}
                      value={state.monthly_amount}
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name={`monthly_amount`} />
                    </div>
                  </div>

      <div className='mb-10 fv-row'>
        <label className='d-flex align-items-center form-label mb-5'>
          Le logement loué est-il en zone tendue ?
        </label>

        <div className='mb-10'>

          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>

              <span className='form-check form-check-custom form-check-solid me-2'>
                <input className='form-check-input' onChange={onChange}
                  type='radio' name='tense_area' value='no' />
              </span>
              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  Le logement n'est pas situé en zone tendue                </span>
                {/* <span className='fs-6 fw-bold text-gray-400'>Use images to your post time</span> */}
              </span>
            </span>


          </label>

          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='form-check form-check-custom form-check-solid me-2'>
                <input className='form-check-input' type='radio' onChange={onChange}
                  name='tense_area' value='yes' />
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  Le logement est situé en zone tendue

                </span>
              </span>
            </span>

          </label>

        </div>
        {/* {state.tense_area === 'no' && (
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span className='required'>Montant du loyer mensuel (hors charges) </span>
            </label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              name={`monthly_amount`}
              placeholder=''
              onChange={onChange}
              value={state.monthly_amount}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name={`monthly_amount`} />
            </div>
          </div>
        )} */}
          
        {state.tense_area === 'yes' && (

          <>
            <label className='d-flex align-items-center form-label mb-5'>
              Le logement est situé à :
            </label>

            <div className='mb-10'>

              <label className='d-flex flex-stack mb-5 cursor-pointer'>
                <span className='d-flex align-items-center me-2'>
                  <span className='form-check form-check-custom form-check-solid me-2'>
                    <input className='form-check-input' onChange={onChange}
                      type='radio' name='tense_zone' value='paris' />
                  </span>
                  <span className='d-flex flex-column'>
                    <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>

                      Paris           </span>
                    {/* <span className='fs-6 fw-bold text-gray-400'>Use images to your post time</span> */}
                  </span>
                </span>


              </label>

              <label className='d-flex flex-stack mb-5 cursor-pointer'>
                <span className='d-flex align-items-center me-2'>
                  <span className='form-check form-check-custom form-check-solid me-2'>
                    <input className='form-check-input' type='radio' onChange={onChange}
                      name='tense_zone' value='other' />
                  </span>

                  <span className='d-flex flex-column'>
                    <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                      autre ville ou commune
                    </span>
                  </span>
                </span>

              </label>
            </div>

            {(state.tense_zone === 'paris' ) && (
            <>
              {/* <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                  <span className='required'>Maximum d'évolution de loyer</span>
                </label>
                <input
                  type='number'
                  className='form-control form-control-lg form-control-solid'
                  name={`maximum_evolution`}
                  placeholder=''
                  onChange={onChange}
                  value={state.maximum_evolution}
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name={`maximum_evolution`} />
                </div>
              </div> */}
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                  <span className='required'>Loyer de référence</span>
                </label>
                <input
                  type='number'
                  className='form-control form-control-lg form-control-solid'
                  name={`reference_rent`}
                  placeholder=''
                  onChange={onChange}
                  value={state.reference_rent}
                />
                <div className='text-danger mt-2'>  
                  <ErrorMessage name={`reference_rent`} />
                </div>
              </div>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                  <span className='required'>Loyer de référence majoré</span>
                </label>
                <input
                  type='number'
                  className='form-control form-control-lg form-control-solid'
                  name={`increased_reference_rent`}
                  placeholder=''
                  onChange={onChange}
                  value={state.increased_reference_rent}
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name={`increased_reference_rent`} />
                </div>
              </div>

            </>)}

            {state.tense_zone  && (<> <div className='mb-10'>

              <label className='d-flex flex-stack mb-5 cursor-pointer'>
                <span className='d-flex align-items-center me-2'>
                  <span className='form-check form-check-custom form-check-solid me-2'>
                    <input className='form-check-input' onChange={onChange}
                      type='radio' name='location_description' value='premiere' />
                  </span>
                  <span className='d-flex flex-column'>
                    <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>

                      Première location           </span>
                    {/* <span className='fs-6 fw-bold text-gray-400'>Use images to your post time</span> */}
                  </span>
                </span>


              </label>
              <label className='d-flex flex-stack mb-5 cursor-pointer'>
                <span className='d-flex align-items-center me-2'>
                  <span className='form-check form-check-custom form-check-solid me-2'>
                    <input className='form-check-input' type='radio' onChange={onChange}
                      name='location_description' value='sans_travaux' />
                  </span>

                  <span className='d-flex flex-column'>
                    <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                      A été loué il y a moins de 18 mois et n'a pas subi de travaux      </span>
                  </span>
                </span>

              </label>
              <label className='d-flex flex-stack mb-5 cursor-pointer'>
                <span className='d-flex align-items-center me-2'>
                  <span className='form-check form-check-custom form-check-solid me-2'>
                    <input className='form-check-input' type='radio' onChange={onChange}
                      name='location_description' value='avec_travaux' />
                  </span>

                  <span className='d-flex flex-column'>
                    <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                      A été loué il y a moins de 18 mois et a subi des travaux d'amélioration
                      il y a moins de 6 mois      </span>
                  </span>
                </span>

              </label>
            </div>



              {(state.location_description === "premiere") && (<>
                {/* <div className='fv-row mb-10'>
                  <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                    <span className='required'>Montant du loyer mensuel (hors charges) </span>
                  </label>
                  <input
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    name={`monthly_amount`}
                    placeholder=''
                    onChange={onChange}
                    value={state.monthly_amount}
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name={`monthly_amount`} />
                  </div>
                </div> */}

              </>)}
              {(state.location_description === "sans_travaux" || state.location_description === "avec_travaux") && (
                <>
                  {/* <div className='fv-row mb-10'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                      <span className='required'>Montant du loyer mensuel (hors charges) </span>
                    </label>
                    <input
                      type='number'
                      className='form-control form-control-lg form-control-solid'
                      name={`monthly_amount`}
                      placeholder=''
                      onChange={onChange}
                      value={state.monthly_amount}
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name={`monthly_amount`} />
                    </div>
                  </div> */}
            
            
                  <div className='fv-row mb-10'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                      <span className='required'>         ancien loyer* </span>
                    </label>
                    <input
                      type='number'
                      className='form-control form-control-lg form-control-solid'
                      name={`ancien_loyer`}
                      placeholder=''
                      onChange={onChange}
                      value={state.ancien_loyer}
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name={`ancien_loyer`} />
                    </div>
                  </div>
                  <div className='fv-row mb-10'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                      <span className='required'>Date de départ du dernier locataire </span>
                    </label>
                    <input
                      type='date'
                      className='form-control form-control-lg form-control-solid'
                      name={`depart_dernier_locataire`}
                      placeholder=''
                      onChange={onChange}
                      value={state.depart_dernier_locataire}
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name={`depart_dernier_locataire`} />
                    </div>
                  </div>
                </>
              )}
            </>)}

            {state.location_description === "sans_travaux" && (<>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                  <span className='required'>Indice de référence de loyer </span>
                </label>
                <input
                  type='number'
                  className='form-control form-control-lg form-control-solid'
                  name={`indice_ref_loyer`}
                  placeholder=''
                  onChange={onChange}
                  value={state.indice_ref_loyer}
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name={`indice_ref_loyer`} />
                </div>
              </div>
              
              <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Date de réference de l'indice de révision de loyer</span>
          </label>
          <input
            type='date'
            className='form-control form-control-lg form-control-solid'
            name={`date_revision_irl`}
            placeholder=''
            onChange={onChange}
            value={state.date_revision_irl}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={`date_revision_irl`} />
          </div>
        </div>
              
              </>)}


          </>)}




    </div >
    </div>
  )
}

export { Etape7 }
