import React, { FC, useState } from 'react'
import { ErrorMessage } from 'formik'
import { Adjacence, TypePlancheHaut } from './typeadjacence'
import { KTSVG } from '../../../../_metronic/helpers'

const SimulateurStep5: React.FC<{ addPlafond:any, editPlafond:any, deletePlafond:any, onChange: any, state: any }> = ({addPlafond,  editPlafond, deletePlafond, onChange, state }) => {

    return (
    <div className='d-flex flex-column flex-lg-row'>
         
            <div className='flex-column flex-lg-row-auto w-100 w-lg-400px w-xl-550px mb-10 mb-lg-0'>
               
            <div className='fv-row mb-10'>

                <label className='form-label required mb-2'>Surface planche haut</label>

                <input
                    type='number'
                    className='form-control form-control-lg form-control-solid border-dark'
                    name={`surface_plafon`}
                    placeholder=''
                    onChange={onChange}
                    value={state.surface_plafon}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name={`surface_plafon`} />
                </div>
            </div>
            <Adjacence onChange={onChange} statevalue={state.adjacence_plafon} name="adjacence_plafon" />

            <div className='row mb-10 align-items-center '>
                <div className='col'>
                    <label className='form-label required'>Etat d'isolation </label>
                </div>
                <div className='col'>
                    <input
                        type='radio'
                        className='btn-check'
                        name='isolation_plafon'
                        value='isole'
                        id='kt_etat_ph_isole'
                        onChange={onChange}

                    />
                    <label
                        className='btn btn-outline btn-outline-dashed btn-outline-default  align-items-center'
                        htmlFor='kt_etat_ph_isole'
                    >
                        {/* <img src={homeB} alt='fireSpot' width='45' height='40' /> */}

                        <span className='d-block fw-bold text-start'>
                            <span className='text-dark fw-bolder d-block text-center'> Isolé</span>
                        </span>
                    </label>
                </div>
                <div className='col '>
                    <input
                        type='radio'
                        className='btn-check'
                        name='isolation_plafon'
                        value='non_isole'
                        id='kt_etat_ph_non_isole'
                        onChange={onChange}


                    />
                    <label
                        className='btn btn-outline btn-outline-dashed btn-outline-default p-3 align-items-center '
                        htmlFor='kt_etat_ph_non_isole'
                    >
                        {/* <img src={buildingB} alt='fireSpot' width='45' height='40' /> */}

                        <span className='d-block fw-bold text-start'>
                            <span className='text-dark fw-bolder d-block text-center'>Non isolé</span>
                        </span>
                    </label>
                </div>
                <div className='col '>
                    <input
                        type='radio'
                        className='btn-check'
                        name='isolation_plafon'
                        value='inconnu'
                        id='kt_etat_ph_inconnu'
                        onChange={onChange}


                    />
                    <label
                        className='btn btn-outline btn-outline-dashed btn-outline-default p-3 align-items-center '
                        htmlFor='kt_etat_ph_inconnu'
                    >
                        {/* <img src={buildingB} alt='fireSpot' width='45' height='40' /> */}

                        <span className='d-block fw-bold text-start'>
                            <span className='text-dark fw-bolder d-block text-center'>Inconnu</span>
                        </span>
                    </label>
                </div>
            </div>
            {state.isolation_plafon == "isole" && (<>
                <div className=' mb-10 form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                    <input
                        className='form-check-input'
                        type='checkbox'
                        //   onChange={handleChangeCheckbox}
                        value={state.effet_joule_plafon}
                        name='effet_joule_plafon'
                    />
                    <label className='form-label fw-bold fs-4 mx-3'> Contient des cables</label>
                </div>
                
                <div className='row mb-10 align-items-center '>
                <div className='col'>
                    <label className='form-label required'>Type Toiture </label>
                </div>
                <div className='col'>
                    <input
                        type='radio'
                        className='btn-check'
                        name='type_toiture_plafon                        '
                        value='terrasse'
                        id='kt_ph_terrasse'
                        onChange={onChange}

                    />
                    <label
                        className='btn btn-outline btn-outline-dashed btn-outline-default  align-items-center'
                        htmlFor='kt_ph_terrasse'
                    >
                        {/* <img src={homeB} alt='fireSpot' width='45' height='40' /> */}

                        <span className='d-block fw-bold text-start'>
                            <span className='text-dark fw-bolder d-block text-center'> Terrasse</span>
                        </span>
                    </label>
                </div>
                <div className='col '>
                    <input
                        type='radio'
                        className='btn-check'
                        name='type_toiture_plafon'
                        value='comble'
                        id='kt_ph_comble'
                        onChange={onChange}


                    />
                    <label
                        className='btn btn-outline btn-outline-dashed btn-outline-default p-3 align-items-center '
                        htmlFor='kt_ph_comble'
                    >
                        {/* <img src={buildingB} alt='fireSpot' width='45' height='40' /> */}

                        <span className='d-block fw-bold text-start'>
                            <span className='text-dark fw-bolder d-block text-center'>Comble</span>
                        </span>
                    </label>
                </div>
              
            </div>

            </>)}

            {state.isolation_plafon == "non_isole" && (<>
        
                <TypePlancheHaut onChange={onChange} statevalue={state.type_materiau_plafon} name="type_materiau_plafon" />

            </>)}



                <br />
                <button type="button" className='btn btn-lg btn-info w-100 ' onClick={addPlafond}>
                    <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                    Sauvegarder
                </button>
            </div>

            <div className='flex-lg-row-fluid ms-lg-7  w-xl-400px ms-xl-10'>
                <div className='card card-xl-stretch mb-5 mb-xl-8'>


                    <div className='card-body pt-0' style={state?.isolation_plafon == "non_isole" ? { overflowY: "scroll", height: "650px" } : { overflowY: "scroll", height: "500px" }}>

                        {state.array_plafond.length > 0 && state.array_plafond.map((Plafond: any, idx: any) => (<>
                            <div className='d-flex align-items-center bg-light-info rounded p-5'>
                                <span className='svg-icon svg-icon-info me-5'>
                                    <KTSVG path='/media/icons/duotune/general/gen028.svg' className='svg-icon-1' />
                                </span>

                                <div className='flex-grow-1 me-2'>
                                    <a href='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>
                                        Plafond n° {idx + 1} : {Plafond.surface_plafon} m²
                                    </a>
                                    <span className=' fw-bold d-block'>isolation : {Plafond.isolation_plafon}</span>
                                </div>
                                <button type="button" className='btn btn-sm btn-warning me-5' onClick={e => editPlafond(idx)}>
                                    <span className='svg-icon svg-icon-warning'>
                                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-1' />
                                    </span>
                                </button>
                                <button type="button" className='btn btn-sm btn-danger ' onClick={e=>deletePlafond(idx)}>
                                    <span className='svg-icon svg-icon-danger'>
                                        <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-1' />
                                    </span></button>
                            </div>
                            <br />
                        </>
                        ))}

                    </div>
                </div>
            </div>


        </div>
    )
}

export { SimulateurStep5 }
