/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { ErrorMessage } from 'formik'
import { KTSVG } from '../../../../../_metronic/helpers'

const Etape11: React.FC<{ handleChangeCheckbox: any, onChange: any, state: any }> = ({ handleChangeCheckbox, onChange, state }) => {
  return (

    <div className='w-100' key={`etape10
    `}>
      <div className=' mb-10 form-check form-switch form-switch-sm form-check-custom form-check-solid'>
        <input
          className='form-check-input'
          type='checkbox'
          onChange={handleChangeCheckbox}

          value={state.annexe_etat_lieux}
          name='annexe_etat_lieux'
        // defaultChecked={true}
        />
        <label className='form-label fw-bold mx-3'>      État des lieux établis contradictoirement (ou par huissier) lors de la remise des clefs au LOCATAIRE.</label>
      </div>
      <div className=' mb-10 form-check form-switch form-switch-sm form-check-custom form-check-solid'>
        <input
          className='form-check-input'
          type='checkbox'
          onChange={handleChangeCheckbox}

          value={state.annnexe_etat_descriptif}
          name='annnexe_etat_descriptif'
        // defaultChecked={true}
        />
        <label className='form-label fw-bold mx-3'> État descriptif et inventaire du mobilier. (si non meublé à cacher)</label>
      </div>
      <div className=' mb-10 form-check form-switch form-switch-sm form-check-custom form-check-solid'>
        <input
          className='form-check-input'
          type='checkbox'
          onChange={handleChangeCheckbox}

          value={state.annexe_acte_caution}
          name='annexe_acte_caution'
        // defaultChecked={true}
        />
        <label className='form-label fw-bold mx-3'>       Acte de caution solidaire.</label>
      </div>
      <div className=' mb-10 form-check form-switch form-switch-sm form-check-custom form-check-solid'>
        <input
          className='form-check-input'
          type='checkbox'
          onChange={handleChangeCheckbox}

          value={state.annexe_charge_recuperable}
          name='annexe_charge_recuperable'
        // defaultChecked={true}
        />
        <label className='form-label fw-bold mx-3'>Décret n° 87-713 du 26 août 1987 fixant la liste des charges récupérables.</label>
      </div>
      <div className=' mb-10 form-check form-switch form-switch-sm form-check-custom form-check-solid'>
        <input
          className='form-check-input'
          type='checkbox'
          onChange={handleChangeCheckbox}

          value={state.annexe_reparation_locative}
          name='annexe_reparation_locative'
        // defaultChecked={true}
        />
        <label className='form-label fw-bold mx-3'>Décret n°87-712 du 26 août 1987 fixant la liste des réparations locatives.</label>
      </div>
      {/* <div className=' mb-10 form-check form-switch form-switch-sm form-check-custom form-check-solid'>
        <input
          className='form-check-input'
          type='checkbox'
          onChange={handleChangeCheckbox}

          value={state.annexe_dossier_diagnostique}
          name='annexe_dossier_diagnostique'
        // defaultChecked={true}
        />
        <label className='form-label fw-bold mx-3'>Dossier de diagnostic technique (comprenant un DPE établi par un diagnostiqueur certifié, et, pour les biens concernés un état des risques naturels, miniers et technologiques, un état de l'installation intérieure d'électricité et de gaz, une copie de l'état d'amiante et/ou un constat de risque d'exposition au plomb).</label>
      </div> */}
      <div className=' mb-10 form-check form-switch form-switch-sm form-check-custom form-check-solid'>
        <input
          className='form-check-input'
          type='checkbox'
          onChange={handleChangeCheckbox}

          value={state.annexe_attestation_assurance}
          name='annexe_attestation_assurance'
        // defaultChecked={true}
        />
        <label className='form-label fw-bold mx-3'>Attestation d'assurance contre les risques locatifs souscrite par le LOCATAIRE.</label>
      </div>
      {/* <div className=' mb-10 form-check form-switch form-switch-sm form-check-custom form-check-solid'>
        <input
          className='form-check-input'
          type='checkbox'
          onChange={handleChangeCheckbox}

          value={state.annexe_notice_infos}
          name='annexe_notice_infos'
        // defaultChecked={true}
        />
        <label className='form-label fw-bold mx-3'>Notice d'information relative aux droits et obligations des locataires et des bailleurs établis par l'arrêté du 29 mai 2015.</label>
      </div> */}
      <div className=' mb-10 form-check form-switch form-switch-sm form-check-custom form-check-solid'>
        <input
          className='form-check-input'
          type='checkbox'
          onChange={handleChangeCheckbox}

          value={state.annexe_autorisation_prealable}
          name='annexe_autorisation_prealable'
        // defaultChecked={true}
        />
        <label className='form-label fw-bold mx-3'>Une autorisation préalable de mise en location.</label>
      </div>
    
      <div className=' mb-10 form-check form-switch form-switch-sm form-check-custom form-check-solid'>
        <input
          className='form-check-input'
          type='checkbox'
          onChange={handleChangeCheckbox}

          value={state.annexe_extrait_reglement}
          name='annexe_extrait_reglement'
        // defaultChecked={true}
        />
        <label className='form-label fw-bold mx-3'>Extrait du règlement de copropriété concernant la destination de l'immeuble, la jouissance et l'usage des parties privatives et communes et précisant la quote-part afférente au lot loué dans chacune des catégories de charges.</label>
      </div>
      <div className=' mb-10 form-check form-switch form-switch-sm form-check-custom form-check-solid'>
        <input
          className='form-check-input'
          type='checkbox'
          onChange={handleChangeCheckbox}

          value={state.annexe_reference_loyer}
          name='annexe_reference_loyer'
        // defaultChecked={true}
        />
        <label className='form-label fw-bold mx-3'> Les références aux loyers habituellement constatés dans le voisinage pour des logements comparables (si la détermination du loyer est la conséquence d'une procédure liée au fait que le loyer précédemment appliqué était manifestement sous-évalué).</label>
      </div>

    </div>
  )
}

export { Etape11 }
