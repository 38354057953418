/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import * as auth from '../redux/AuthRedux'
import {getUserByToken, login} from '../redux/AuthCRUD'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {activateAccount} from '../../tenant-file/userController'
import toast from 'react-hot-toast'
import getStoredState from 'redux-persist/es/getStoredState'
export function Activation() {
    const [loading, setLoading] = useState(false)
    const [activeS, setActiveS] = useState(false)
    const { id } :any= useParams();
    const activateAccountStatus = (id : string) => {
        if(!loading){
            const formDataActivateAccount= new FormData()
            formDataActivateAccount.append('key', id)
            activateAccount(formDataActivateAccount).then((response:any)=>{
                if(response.data.detail === 'ok'){
                    toast.success('Compte activé avec succès !')
                    setLoading(true)
                    setActiveS(true)
                } else {
                    toast.error('Vérifier les données de votre compte ou contactez l\'administrateur')
                    setLoading(true)
                }     
            }).catch((error:any)=>{
                toast.error('Vérifier les données de votre compte ou contactez l\'administrateur')
            })
        }
    }
    useEffect(()=>{
        activateAccountStatus(id)
    },[])
    return (
        <div className='text-center mb-10'>
            <h1 className='text-dark mb-3'>Activation de mon compte MonCarnetLogement</h1>
            
            {activeS? 
                <div className='text-success fw-bold fs-4'>Votre compte a été activé avec succès {' '} <br/><br/></div>
                :   <div className='text-danger fw-bold fs-4'>Vérifier vos informations {' '} <br/><br/></div>

            }
            
            <Link to='/auth/login' className='link-primary fw-bolder'>
            Se connecter
            </Link>
            
        </div>
    )
}
