import axios from "axios";
var server = "http://197.24.242.216:7070"
var localhost = "http://127.0.0.1:8000/"
export default axios.create({
  baseURL: server,
  headers: { "Content-Type": "application/json" },
 
 
  
});
