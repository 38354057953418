import React, {FC, useEffect, useRef, useState} from 'react'
import {Step1} from './steps/Step1'
import {Step2} from './steps/Step2'
import {Step3} from './steps/Step3'

import {Step4} from './steps/Step4'
import {KTSVG} from '../../../_metronic/helpers'
import {StepperComponent} from '../../../_metronic/assets/ts/components'
import {Formik, Form, FormikValues} from 'formik'
import {createLogementSchemas, CreateLogement, inits} from './CreateSimulEnergy'

const SimulEnergy: FC = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createLogementSchemas[0])
  const [initValues] = useState<CreateLogement>(inits)
  const [isSubmitButton, setSubmitButton] = useState(false)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }
    
    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber! )

    stepper.current.goPrev()

    setCurrentSchema(createLogementSchemas[stepper.current.currentStepIndex -1])
  }

  const submitStep = (values: CreateLogement, actions: FormikValues) => {
    console.log(values)
    
    if (!stepper.current) {
      return
    }

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber! -2)

    setCurrentSchema(createLogementSchemas[stepper.current.currentStepIndex ])

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber ) {
      stepper.current.goNext()
    } else {
      stepper.current.goto(1)
      actions.resetForm()
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div className='card'>
      <br/>
      <h1 className='text-center'>Estimer l'étiquette d'énergie de votre logement</h1>
      <p className='mx-auto mw-700px w-200 pt-8  '> Quelle est la classe énergétique estimée de votre (futur) logement ? Devez-vous envisager des travaux de rénovation pour réduire vos dépenses énergétiques ? Si oui, lesquels ? Et qu'avez-vous à y gagner ? Découvrez toutes les réponses à vos questions grâce à notre simulateur étiquette énergie !
Pour commencer l'estimation de votre étiquette énergétique, renseignez les caractéristiques de votre logement et indiquez le département dans lequel il est situé.</p> 
      <div className='card-body '>
        <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column pt-15'
          id='kt_create_account_stepper'
        >
          <div className='stepper-nav mb-5'>
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Logement</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Enveloppe</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Énergie</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Résultat</h3>
            </div>

          
          </div>

          <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
            {() => (
              <Form className='mx-auto mw-650px w-100 pt-15 pb-10 ' id='kt_create_account_form'>
                <div className='current' data-kt-stepper-element='content'>
                  <Step1 />
                </div>

                <div data-kt-stepper-element='content'>
                  <Step2 />
                </div>

                <div data-kt-stepper-element='content'>
                  <Step3 />
                </div>
                

                <div data-kt-stepper-element='content'>
                  <Step4 />
                </div>

                <div className='d-flex flex-stack pt-15'>
                  <div className='mr-2'>
                    <button
                      onClick={prevStep}
                      type='button'
                      className='btn btn-lg btn-light-greenish me-3'
                      data-kt-stepper-action='previous'
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr063.svg'
                        className='svg-icon-4 me-1'
                      />
                      Précendent
                    </button>
                  </div>

                  <div>
                    <button type='submit' className='btn btn-lg btn-greenish me-3'>
                      <span className='indicator-label'>
                        {!isSubmitButton && 'Continue'}
                        {isSubmitButton && 'Valider'}
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export {SimulEnergy}
