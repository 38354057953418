/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { PropertyModel, initsProperty } from './models/PropertyModel'
import { Formik, Form, ErrorMessage } from 'formik'
import { Link } from 'react-router-dom'
import moment from 'moment'
// import { assignTenant } from './propertiesController'

type Props = {
  className: string
  properties: Array<PropertyModel>
  state: any
  onchange: any
  addTenant: any
  removeTenant: any
}
// 22288758
const ListCandidats: React.FC<Props> = ({ onchange, className, properties, state, addTenant, removeTenant }) => {


  return (
    <div className={`card ${className}`} style={state.loader ? { pointerEvents: "none", color: "grey", background: "#02111e12" } : {}}>

      <div style={{ position: 'absolute' }} >

        {state.loader && <span style={{ marginLeft: '500px', marginTop: "300px", width: '100px', height: "100px", }}
          className='spinner-border spinner-border-lg align-middle'></span>}


      </div>

      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Liste des candidats</span>
          <span className='text-muted mt-1 fw-bold fs-7'>Liste des candidats par logement</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Cliquez ici pour ajouter un logement'
        >
          <a
            href='#'
            className='btn btn-sm btn-light-primary'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_invite_tenant'
          >
            <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            Inviter un locataire
          </a>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='min-w-150px'>Candidats</th>
                <th className='min-w-250px'>Logements</th>
                <th className='min-w-150px'>Mis à jour le</th>
                <th className='min-w-120px'>Revenu</th>
                <th className='min-w-120px'>Labélisation </th>
                <th className='min-w-120px'>Statut</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>

              {/* /////////////// */}
              {properties.map(prop => (
                prop.assignements.map((assignement: any, index: any) => (
                  <tr key={index}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <Link to="#" className='text-dark fw-bolder text-hover-primary fs-6'>
                            {assignement.first_name} {assignement.last_name}
                          </Link>
                          <span className='text-muted fw-bold text-muted d-block fs-7'>
                            {assignement.email}
                          </span>
                          
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-45px me-5'>
                          <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'> {prop.address.length > 30? prop.address.substring(0, 30)+'...': prop.address}</a>
                          <span className='text-muted fw-bold text-muted d-block fs-7'>
                            <i className="bi bi-house text-primary"></i>  {prop.typology}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                        {/* {assignement.last_date ? assignement.last_date.substring(0, 10) : ''} */}
                        {assignement.last_date ? moment(assignement.last_date).format('DD/MM/YYYY, hh:mm') : ''}
                      </a>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                        {assignement.revenu}  {assignement.revenu > 0 ? '€' : ''}
                      </a>
                    </td>
                    <td>
                      {assignement.status === "DECLINED" && <span className='badge badge-light-danger fs-7 fw-bold'>Refusé </span>}
                      {assignement.status === "TO_PROCESS" && <span className='badge badge-light-warning fs-7 fw-bold'>En cours </span>}
                      {assignement.status === "ACCEPTED" && <span className='badge badge-light-success fs-7 fw-bold'>Labelisé </span>}
                      {assignement.status === "" && <span className='badge badge-light-success fs-7 fw-bold'>Labelisé </span>}
                    </td>
                    <td>
                      {assignement.invi ? <span className='badge badge-light-success fs-7 fw-bold'> Invitation acceptée </span> :
                        <span className='badge badge-light-warning fs-7 fw-bold'>
                          Invitation envoyée </span>}
                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                       {assignement.df_id && ( <Link to={{
                          pathname: `/tenant-details/${assignement.df_id}`,
                        }} >
                          <button className='btn btn-sm bg-light-success border-success border mx-2'>
                              <KTSVG
                                path='/media/icons/duotune/general/gen016.svg'
                                className='svg-icon-3'
                              />
                              <span> Consulter</span>
                          </button>

                        </Link>)}
                        <button
                          className='btn btn-sm bg-light-danger border-danger border mx-2'
                          onClick={() => removeTenant(assignement.assign_id)}
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen034.svg'
                            className='svg-icon-3'
                          />
                          <span>Retirer</span>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
      </div>

      <div className='modal fade' id='kt_modal_invite_tenant' aria-hidden='true'>
        <div className='modal-dialog mw-850px'>
          <div className='modal-content'>

            <div className='modal-header'>
              <h2>Ajouter un logement</h2>

              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>

            <div className='d-flex flex-row-fluid flex-center bg-white rounded'>
              <Formik enableReinitialize initialValues={initsProperty} onSubmit={addTenant}>
                {() => (

                  <Form className='py-12 w-100 w-xl-700px px-5' style={state.loader ? { pointerEvents: "none", color: "grey", background: "#02111e12" } : {}} noValidate >

                    <div className='row mb-10'>
                      <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                        <span className='required'>Selectionner un logement</span>
                      </label>


                      <select
                        name={`${''}prop_id`}
                        className='form-select form-select-lg form-select-solid'
                        onChange={onchange}
                        value={state.prop_id}
                      >
                        <option>choisir le logement </option>
                        {properties.map((prop:any, index:any) => (
                          <option value={prop.id} key={index}>{prop.typology} {prop.category} {prop.address}</option>
                        ))}


                      </select>
                      <div className='text-danger mt-2'>
                        <ErrorMessage name={`${''}prop_id`} />
                      </div>

                    </div>


                    <div className='row mb-10'>
                      <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                        <span className='required'>
                          Email
                        </span>
                      </label>

                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        name={`${''}tenant_email`}
                        placeholder='Saisir email locataire'
                        onChange={onchange}
                      // value={state.loyer}
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name={`${''}tenant_email`} />
                      </div>
                    </div>
                    <div className='d-flex flex-stack pt-10'>
                      <div className='mr-2' data-bs-dismiss='modal'>
                        <button
                          type='button'
                          className='btn btn-lg btn-light-primary me-3'
                        >
                          Annuler
                        </button>
                      </div>
                      <div data-bs-dismiss='modal'>
                        <button
                          onClick={addTenant}
                          type='button'
                          className='btn btn-lg btn-primary me-3'>
                          Envoyer invitation
                        </button>
                      </div>
                    </div>


                  </Form>
                )}
              </Formik>

            </div>
          </div>
        </div>

      </div>



    </div>
  )
}

export { ListCandidats }
