import axios from 'axios'
import {AuthModel} from '../models/AuthModel'
import {UserModel} from '../models/UserModel'
import { LOGIN_URL,GET_USER_BY_ACCESSTOKEN_URL,
  LOGOUT_URL,
  REGISTER_URL,
  REQUEST_PASSWORD_URL,
  REFRESH_URL } from '../../../routes'
// const LAYOUT_CONFIG_KEY = process.env.REACT_APP_BASE_LAYOUT_CONFIG_KEY || 'LayoutConfig'
const API_URL = process.env.REACT_APP_API_URL || 'api'

export interface JwtDecoded {
  token_type: string
  exp: number
  jti: string
  user_id: number
}
const headers = {
  headers:{
    'Content-Type':'application/json',
  },
}

// Server should return AuthModel
export async function login(data :any){
  const {email, password} = data
  var res = {}
  await axios.post(LOGIN_URL, {email, password},headers)
    .then(response => {  
      if(response.status === 200){
        if(response.data.status === 400){
          const auth = {}
          res = [403, {...auth, password: undefined}]
        } else {
          const auth = { 
            'accessToken': response.data.access,
            'refreshToken': response.data.refresh,
          }
          res = [200, {...auth, password: undefined}]
        }
      }else{
        res = [400]
      }
    })
    .catch(error => {
      // console.log(error.response)
    });
    return res
}

// Server should return AuthModel
export function refresh(access: string|undefined, refresh: string|undefined) {
  return axios.post(REFRESH_URL, {access,refresh})
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string, zipcode: string, phone: string) {
  return axios.post<AuthModel>(REGISTER_URL, {
    email,
    firstname,
    lastname,
    password,
    zipcode,
    phone
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {email})
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}

export function logout(accessToken: string, refreshToken?: string) {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.post(LOGOUT_URL, {accessToken, refreshToken})
}
