import React, {FC, useState} from 'react'
import {ErrorMessage} from 'formik'
import {record} from './adj_text'

const Orientation: React.FC<{onChange: any; statevalue: any; name: string}> = ({
  onChange,
  statevalue,
  name,
}) => {
  return (
    <div className='w-100' key={`orientation`}>
      <div className='fv-row mb-10'>
        <label className='form-label required mb-2'>Orientation</label>
        <select
          name={name}
          className='form-select form-select-lg form-select-solid border-dark'
          onChange={onChange}
          value={statevalue}
        >
          <option value='' disabled>
            selectionner l'orientation{' '}
          </option>
          <option value='1'>Nord</option>
          <option value='6'>Sud</option>
          <option value='5'>Est/Ouest </option>
          <option value='0'>N'importe</option>
        </select>
      </div>
    </div>
  )
}
const Building: React.FC<{onChange: any; statevalue: any; name: string}> = ({
    onChange,
    statevalue,
    name,
  }) => {
    return (
      <div className='w-100' key={`periode_construction_logement`}>
        <div className='fv-row mb-10'>
          <label className='form-label required mb-2'>Période de construction</label>
          <select
          name={name}
          className='form-select form-select-lg form-select-solid border-dark'
          onChange={onChange}
          value={statevalue}
        >
          <option value='' disabled>
            choisir la période
          </option>
          <option value='1|2'>avant 1974 ou inconnu</option>
          <option value='3'>Entre 1975 et 1977</option>
          <option value='4'>Entre 1978 et 1982</option>
          <option value='5'>Entre 1983 et 1988</option>
          <option value='6'>Entre 1989 et 2000 </option>
          <option value='7'>Entre 2001 et 2005</option>
          <option value='8'>Entre 2006 et 2012</option>
          <option value='9|10'>après 2013</option>
        </select>
        </div>
      </div>
    )
  }
const Adjacence: React.FC<{onChange: any; statevalue: any; name: string}> = ({
  onChange,
  statevalue,
  name,
}) => {
  return (
    <div className='w-100' key={`simstep1`}>
      <div className='fv-row mb-10'>
        <label className='form-label required mb-2'>Type d'adjacence</label>

        <select
          name={name}
          className='form-select form-select-lg form-select-solid border-dark'
          onChange={onChange}
          value={statevalue}
        >
          <option value='' disabled>
            selectionner le type d'adjacence
          </option>
          <option value='1'>Extérieur</option>
          <option value='2'>Parroi enterré</option>
          <option value='3'>Vide sanitaire</option>
          <option value='4'>Batiment ou locale à usage autre que d'habitation</option>
          <option value='5'>Terre plein</option>
          <option value='6'>Sous-sol non chauffé</option>
          <option value='7'>Locaux non chauffés non accessible</option>
          <option value='20'>
            Local tertiaire à l'intérieur de l'immeuble en contact avec l'appartement
          </option>
          <option value='14'>local non chauffé</option>
          <option value='13|15|17'>local non chauffé</option>
          <option value='8|9|12|16|18|19|21'>local non chauffé</option>
          <option value='11'>local non chauffé</option>
          <option value='10'>espace tampon solarisé (véranda,loggia fermée) </option>
        </select>
      </div>
    </div>
  )
}
const TypePorte: React.FC<{onChange: any; statevalue: any; name: string}> = ({
  onChange,
  statevalue,
  name,
}) => {
  return (
    <div className='w-100' key={`simstep1`}>
      <div className='fv-row mb-10'>
        <label className='form-label required mb-2'>Type de matériau porte</label>

        <select
          name={name}
          className='form-select form-select-lg form-select-solid border-dark'
          onChange={onChange}
          value={statevalue}
        >
          <option value='' disabled>
            choisir{' '}
          </option>
          <option value=' 1|5'> Porte simple en bois ou PVC Porte opaque plein</option>
          <option value='2|6'>
            Porte simple en bois ou PVC Porte avec moins de 30% de vitrage simple
          </option>
          <option value='3|7'>
            Porte simple en bois ou PVC Porte avec 30-60% de vitrage simple
          </option>
          <option value='4|8'>Porte simple en bois ou PVC Porte avec double vitrage</option>
          <option value='9'>Porte simple en métal Porte opaque pleine</option>
          <option value='10'>Porte simple en métal Porte avec vitrage simple</option>
          <option value='11'>
            Porte simple en métal Porte avec moins de 30% de double vitrage
          </option>
          <option value='12'>Porte simple en métal Porte avec 30-60% de double vitrage</option>
          <option value='13'>Toute menuiserie Porte opaque pleine isolée</option>
          <option value='14'>Toute menuiserie Porte précédée d’un SAS</option>
          <option value='15'>Toute menuiserie Porte isolée avec double vitrage</option>
        </select>
      </div>
    </div>
  )
}
const TypeBaie: React.FC<{onChange: any; statevalue: any; name: string}> = ({
  onChange,
  statevalue,
  name,
}) => {
  return (
    <div className='w-100' key={`simstep1`}>
      <div className='fv-row mb-10'>
        <label className='form-label required mb-2'>Type de Baie</label>

        <select
          name={name}
          className='form-select form-select-lg form-select-solid border-dark'
          onChange={onChange}
          value={statevalue}
        >
          <option value='' disabled>
            choisir{' '}
          </option>
          <option value='1'>Paroi en brique de verre pleine</option>
          <option value='2'>Paroi en brique de verre creuse</option>
          <option value='3'>Paroi en polycarbonnate</option>
          <option value='4'>Fenêtres battantes</option>
          <option value='5'>Fenêtres coulissantes</option>
          <option value='9'>Portes-fenêtres battantes</option>
          <option value='6'>Portes-fenêtres coulissantes</option>
          <option value='7'>Portes-fenêtres battantes sans soubassement</option>
          <option value='8'>Portes-fenêtres battantes avec soubassement</option>
        </select>
      </div>
    </div>
  )
}
const TypeFermeture: React.FC<{onChange: any; statevalue: any; name: string}> = ({
  onChange,
  statevalue,
  name,
}) => {
  return (
    <div className='w-100' key={`simstep1`}>
      <div className='fv-row mb-10'>
        <label className='form-label required mb-2'>Type de fermeture</label>

        <select
          name={name}
          className='form-select form-select-lg form-select-solid border-dark'
          onChange={onChange}
          value={statevalue}
        >
          <option value='' disabled>
            choisir{' '}
          </option>
          <option value='2'>
            Jalousie accordéon, fermeture à lames orientables y compris les vénitiens extérieurs
            tout métal, volets battants ou persiennes avec ajours fixes
          </option>
          <option value='3'>Fermeture sans ajours en position déployée, volets roulants alu</option>
          <option value='4'>Volets roulants PVC ou bois (e inf 12 mm)</option>
          <option value='5'>
            Persienne coulissante et volet battant PVC ou bois (e inf 22 mm)
          </option>
          <option value='6'>Volets roulants PVC ou bois (e sup 12 mm)</option>
          <option value='7'>
            Persienne coulissante et volet battant PVC ou bois (e sup 22 mm)
          </option>
          <option value='8'>Fermeture isolée sans ajours en position déployée</option>
        </select>
      </div>
    </div>
  )
}
const TypeMenuisier: React.FC<{onChange: any; statevalue: any; name: string}> = ({
  onChange,
  statevalue,
  name,
}) => {
  return (
    <div className='w-100' key={`simstep1`}>
      <div className='fv-row mb-10'>
        <label className='form-label required mb-2'>Type de Matériau</label>

        <select
          name={name}
          className='form-select form-select-lg form-select-solid border-dark'
          onChange={onChange}
          value={statevalue}
        >
          <option value='' disabled>
            choisir{' '}
          </option>
          <option value='1'>Brique de Verre</option>
          <option value='2'>Polycarbonate</option>
          <option value='3'>Bois ou Bois/métal</option>
          {/* <option value='4'>Volets roulants PVC ou bois (e inf 12 mm)</option> */}
          <option value='5'>PVC</option>
          <option value='6'>Métal avec rupture de pont thermique</option>
          <option value='7'>Métal sans rupture de pont thermique</option>
        </select>
      </div>
    </div>
  )
}
const TypePlancheBas: React.FC<{onChange: any; statevalue: any; name: string}> = ({
  onChange,
  statevalue,
  name,
}) => {
  return (
    <div className='fv-row mb-10'>
      <label className='form-label required mb-2'>Type de Planche Bas</label>

      <select
        name={name}
        className='form-select form-select-lg form-select-solid border-dark'
        onChange={onChange}
        value={statevalue}
      >
        <option value='' disabled>
          choisir{' '}
        </option>
        <option value='2'>Inconnu</option>
        <option value='2'>Plancher avec ou sans remplissage</option>
        <option value='3'>Plancher entre solives métalliques avec ou sans remplissage</option>
        <option value='4'>Plancher entre solives bois avec ou sans remplissage</option>
        <option value='5'>Plancher bois sur solives métalliques</option>
        <option value='6'>Bardeaux et remplissage</option>
        <option value='7'>Voutains sur solives métalliques</option>
        <option value='8'>Voutains en briques ou moellons</option>
        <option value='8'>Dalle béton</option>
        <option value='8'>Plancher bois sur solives bois</option>
        <option value='8'>Plancher lourd type entrevous terre-cuite, poutrelles béton</option>
        <option value='8'>Plancher à entrevous isolant</option>
      </select>
    </div>
  )
}
const TypePlancheHaut: React.FC<{onChange: any; statevalue: any; name: string}> = ({
  onChange,
  statevalue,
  name,
}) => {
  return (
    <div className='fv-row mb-10'>
      <label className='form-label required mb-2'>Type de Planche Haut</label>

      <select
        name={name}
        className='form-select form-select-lg form-select-solid border-dark'
        onChange={onChange}
        value={statevalue}
      >
        <option value='' disabled>
          choisir{' '}
        </option>
        <option value='1'>Inconnu</option>
        <option value='2'>Plafond avec ou sans remplissage</option>
        <option value='3'>Plafond entre solives métalliques avec ou sans remplissage</option>
        <option value='4'>Plafond entre solives bois avec ou sans remplissage</option>
        <option value='5'>Plafond bois sur solives métalliques</option>
        <option value='6'>Plafond bois sous solives métalliques</option>
        <option value='7'>Bardeaux et remplissage</option>
        <option value='8'>Dalle béton</option>
        <option value='9'>Plafond bois sur solives bois</option>
        <option value='10'>Plafond bois sous solives bois</option>
        <option value='11'>Plafond lourd type entrevous terre-cuite, poutrelles béton</option>
        <option value='12'>Combles aménagés sous rampant</option>
        <option value='13'>Toiture en chaume</option>
        <option value='14'>Plafond en plaque de plâtre</option>
        <option value='15'>Plancher à entrevous isolant</option>
        <option value='16'>Plancher à entrevous isolant</option>
      </select>
    </div>
  )
}
const TypeGenerateurEcs: React.FC<{onChange: any; statevalue: any; name: string}> = ({
  onChange,
  statevalue,
  name,
}) => {
  return (
    <div className='w-100' key={`orientation`}>
      <div className='fv-row mb-10'>
        <label className='form-label required mb-2'>Type generateur ecs</label>
        <select
          name={name}
          className='form-select form-select-lg form-select-solid border-dark'
          onChange={onChange}
          value={statevalue}
        >
          <option value='' disabled>
            selectionner{' '}
          </option>
          <option value='1'>Ballon électrique à accumulation horizontal</option>
          <option value='2'>Ballon électrique à accumulation vertical Autres ou inconnue</option>
          <option value='3'>
            Ballon électrique à accumulation vertical Catégorie B ou 2 étoiles{' '}
          </option>
          <option value='4'>
            Ballon électrique à accumulation vertical Catégorie C ou 3 étoiles
          </option>
        </select>
      </div>
    </div>
  )
}
const VolumeBallon: React.FC<{onChange: any; statevalue: any; name: string}> = ({
  onChange,
  statevalue,
  name,
}) => {
  return (
    <div className='w-100' key={`orientation`}>
      <div className='fv-row mb-10'>
        <label className='form-label required mb-2'>Volume ballon</label>
        <select
          name={name}
          className='form-select form-select-lg form-select-solid border-dark'
          onChange={onChange}
          value={statevalue}
        >
          <option value='' disabled>
            selectionner le volume{' '}
          </option>
          <option value='1'>Nord</option>
          <option value='6'>Sud</option>
          <option value='5'>Est/Ouest </option>
          <option value='0'>N'importe</option>
        </select>
      </div>
    </div>
  )
}

const TypeGenerateurCh: React.FC<{onChange: any; statevalue: any; name: string}> = ({
  onChange,
  statevalue,
  name,
}) => {
  return (
    <div className='w-100' key={`orientation`}>
      <div className='fv-row mb-10'>
        <label className='form-label required mb-2'>Type generateur chauffage</label>
        <select
          name={name}
          className='form-select form-select-lg form-select-solid border-dark'
          onChange={onChange}
          value={statevalue}
        >
          <option value='' disabled>
            selectionner{' '}
          </option>
          <option value='1'>Gaz</option>

          <option value='20'> Fioul </option>
          <option value='21'>électricité</option>
          <option value='22'> Bois </option>
          <option value='23'> Autre </option>
        </select>
      </div>
    </div>
  )
}

const TypeInstallation: React.FC<{onChange: any; statevalue: any; name: string}> = ({
  onChange,
  statevalue,
  name,
}) => {
  return (
    <div className='w-100' key={`orientation`}>
      <div className='fv-row mb-10'>
        <label className='form-label required mb-2'>Type installation</label>
        <select
          name={name}
          className='form-select form-select-lg form-select-solid border-dark'
          onChange={onChange}
          value={statevalue}
        >
          <option value='' disabled>
            selectionner{' '}
          </option>
          <option value='1'>Installation de chauffage simple</option>

          <option value='20'> installation de chauffage avec chauffage solaire </option>
          <option value='21'>installation de chauffage avec appoint </option>
          <option value='22'> installation de chauffage PAC avec relève par chaudière </option>
          <option value='23'> Autre </option>
        </select>
      </div>
    </div>
  )
}

export {
  Orientation,
  TypeInstallation,
  Adjacence,
  TypePorte,
  TypeMenuisier,
  TypeFermeture,
  TypePlancheBas,
  TypePlancheHaut,
  TypeBaie,
  TypeGenerateurCh,
  VolumeBallon,
  TypeGenerateurEcs,
  Building
}
