/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch, Link} from 'react-router-dom'
import {Registration} from './components/Registration'
import {Activation} from './components/Activation'
import {ForgotPassword} from './components/ForgotPassword'
import {ResetPassword} from './components/ResetPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

export function AuthPage() {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="d-flex flex-column flex-lg-row flex-column-fluid"
        id="kt_login"
      >
        {/* Aside */}
        <div className="d-flex flex-column flex-lg-row-auto bg-dark w-lg-600px pt-15 pt-lg-0">
          {/* Top */}
          <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15 text-center">
            {/* begin::Aside Logo */}
            {/* <Link to="/" className="mb-6">
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/logos/logo-1.svg")}
                className="h-75px"
              />
            </Link> */}
            {/* end::Aside Logo */}

            {/* begin::Aside Subtitle */}
            <h3 className="fw-bolder fs-2x text-white lh-lg">
              Découvrez
              <br />
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/logos/logo-1-dark.svg")}
                className="h-90px"
                style={{paddingLeft:'150px'}}
              />
            </h3>
            {/* end::Aside Subtitle */}
          </div>

          {/* Bottom */}
          <div
            className="d-flex flex-row-fluid bgi-size-contain bgi-no-repeat bgi-position-x-center min-h-350px"
            style={{
              backgroundImage: `url(${toAbsoluteUrl(
                "/media/illustrations/mcl/mcl-bg.png"
              )})`,
            }}
          ></div>
        </div>

        {/* Content */}
        <div className="login-content flex-lg-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden py-20 px-10 p-lg-7 mx-auto mw-450px w-100">
          <div className="d-flex flex-column-fluid flex-center py-10">
            <Switch>
              <Route path="/auth/login" component={Login} />
              <Route path="/auth/registration" component={Registration} />
              <Route path="/auth/activation/:id" component={Activation} />
              <Route path="/auth/forgot-password" component={ForgotPassword} />
              <Route path="/auth/reset-password/:id/:tok" component={ResetPassword} />
              <Route path='/googleplay' component={() => { 
                  window.location.href = 'https://play.google.com/store/apps/details?id=fr.digilogement.mcl'; 
                  return null;
              }}/>
              <Route path='/applestore' component={() => { 
                  window.location.href = 'https://apps.apple.com/fr/app/mon-carnet-logement/id1632900315?platform=iphone'; 
                  return null;
              }}/>
              <Redirect from="/auth" exact={true} to="/auth/login" />
              <Redirect to="/auth/login" />
            </Switch>
          </div>
          <div className="d-flex justify-content-lg-start justify-content-center align-items-center py-7 py-lg-0">
            <span className="text-primary fw-bolder fs-4 cursor-pointer">
              <a href="https://moncarnetlogement.fr/cgu/" target='_blank'>CGU</a>
            </span>
            <span className="text-primary ms-10 fw-bolder fs-4">
              <a href="https://moncarnetlogement.fr/nous-contacter/" target='_blank'>Contactez-nous</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
