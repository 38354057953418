import React, {FC, useState} from 'react'
import {Field, ErrorMessage} from 'formik'
import Elec from '../../../../imgs/Elec.png'
import Gas from '../../../../imgs/Gas.png'
import gasoline from '../../../../imgs/gasoline.png'
import Bois from '../../../../imgs/Bois.png'
import none from '../../../../imgs/none.png'
import solaire from '../../../../imgs/solaire.png'


export const TypeChElec = () => {
  return(
<div className='fv-row mb-10'>
         <label className='form-label required'>Type de Chauffage</label>

        
         <Field
          as='select'
          name='TChauffage'
          className='form-select  border-dark'
         >
          <option value='1'>Convecteurs</option>
          <option value='2'>Radiateurs électriques à inertie</option>
          <option value='3'>Pompe à chaleur aérothermique air/air</option>
          <option value='4'>Pompe à chaleur aérothermique air/eau</option>
          <option value='5'>Pompe à chaleur géothermique</option>
          <option value='6'>Chaudière électrique</option>
         </Field>

        <div className='text-danger mt-2'>
          <ErrorMessage name='TChauffage' />
        </div>
        </div>
  )
}


export const TypeChGaz = () => {
  return(
<div className='fv-row mb-10'>
         <label className='form-label required'>Type de Chauffage</label>

        
         <Field
          as='select'
          name='TChauffage'
          className='form-select  border-dark'
         >
          <option value='1'>Pompe à chaleur hybride</option>
          <option value='2'>Chaudière Standard</option>
          <option value='3'>Chaudière basse température</option>
          <option value='4'>Chaudière à condensation</option>
         </Field>

        <div className='text-danger mt-2'>
          <ErrorMessage name='TChauffage' />
        </div>
        </div>
  )
}

export const TypeChFioul = () => {
  return(
<div className='fv-row mb-10'>
         <label className='form-label required'>Type de Chauffage</label>

        
         <Field
          as='select'
          name='TChauffage'
          className='form-select  border-dark '
         >
          <option value='1'>Pompe à chaleur hybride</option>
          <option value='2'>Chaudière Standard</option>
          <option value='3'>Chaudière basse température</option>
          <option value='4'>Chaudière à condensation</option>
         </Field>

        <div className='text-danger mt-2'>
          <ErrorMessage name='TChauffage' />
        </div>
        </div>
  )
}

export const TypeChBois = () => {
  return(
<div className='fv-row mb-10'>
         <label className='form-label required'>Type de Chauffage</label>

        
         <Field
          as='select'
          name='TChauffage'
          className='form-select   border-dark'
         >
          <option value='1'>Chaudière Standard à bûches</option>
          <option value='2'>Poêle granulés et radiateurs électriques</option>
          <option value='3'>Chaudière à combustion améliorée</option>
          <option value='4'>Chaudière à granulés</option>
         </Field>

        <div className='text-danger mt-2'>
          <ErrorMessage name='TChauffage' />
        </div>
        </div>
  )
}


const Step3: FC = () => {
  var [elecIsToggled, setElecIsToggled] = useState(false);
  var [gazIsToggled, setGazIsToggled] = useState(false);
  var [fioulIsToggled, setFioulIsToggled] = useState(false);
  var [boisIsToggled, setBoisIsToggled] = useState(false);

  return (

    
    <div className='w-100'>


      <div className='fv-row mb-10 '>
      <label className='form-label required'>L'énergie de Chauffage Principale</label>
      <div className='col text-center'>
      <Field
        type='radio'
        className='btn-check'
        name='TEnCP'
        value='Electricité_P'
        id='TEnCP_Electricité'
        onClick = {() => {
            setElecIsToggled(elecIsToggled= true)
            setGazIsToggled(gazIsToggled = false)
            setFioulIsToggled(fioulIsToggled = false)
            setBoisIsToggled(boisIsToggled = false)

        }
        } />
      <label
              className='btn btn-outline btn-outline-dashed btn-outline-default  align-items-center'
              htmlFor='TEnCP_Electricité'
            >
        <img src={Elec} alt="fireSpot" width="85" height="90" />
        <span className='d-block fw-bold text-start'>
          <span className='text-dark fw-bolder d-block fs-6 text-center'>Electricité</span>    
        </span>
        </label>
       

       
      <Field
        type='radio'
        className='btn-check'
        name='TEnCP'
        value='Gaz_P'
        id='TEnCP_Gaz' 
        onClick = {() => {
        setElecIsToggled(elecIsToggled= false)
          setGazIsToggled(gazIsToggled = true)
          setFioulIsToggled(fioulIsToggled = false)
          setBoisIsToggled(boisIsToggled = false)
        }
        } />
      <label
              className='btn btn-outline btn-outline-dashed btn-outline-default '
              htmlFor='TEnCP_Gaz'
            >
        <img src={Gas} alt="fireSpot" width="85" height="90"/>
        <span className='d-block fw-bold text-start'>
          <span className='text-dark fw-bolder d-block fs-6 text-center'>Gaz</span>    
        </span>
        </label>

        <Field
        type='radio'
        className='btn-check'
        name='TEnCP'
        value='Fioul_P'
        id='TEnCP_Fioul' 
        onClick = {() => {
          setElecIsToggled(elecIsToggled= false)
          setGazIsToggled(gazIsToggled = false)
          setFioulIsToggled(fioulIsToggled = true)
          setBoisIsToggled(boisIsToggled = false)

      }
      } />
      <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-3'
              htmlFor='TEnCP_Fioul'
            >
        <img src={gasoline} alt="fireSpot" width="80" height="90" />
        <span className='d-block fw-bold text-start'>
         
          <span className='text-dark fw-bolder d-block fs-6 text-center'>Fioul</span>    
        </span>
        </label>

        <Field
        type='radio'
        className='btn-check'
        name='TEnCP'
        value='Bois_P'
        id='TEnCP_Bois' 
        onClick = {() => {
          setElecIsToggled(elecIsToggled= false)
          setGazIsToggled(gazIsToggled = false)
          setFioulIsToggled(fioulIsToggled = false)
          setBoisIsToggled(boisIsToggled = true)
      }
      } />
      <label
              className='btn btn-outline btn-outline-dashed btn-outline-default'
              htmlFor='TEnCP_Bois'
            >
        <img src={Bois} alt="fireSpot" width="80" height="90" />
        <span className='d-block fw-bold text-start'>
         
          <span className='text-dark fw-bolder d-block fs-6 text-center'>Bois</span>    
        </span>
        </label>


        </div>
      </div>


      <div className='fv-row mb-10 '>
      <label className='form-label required'>L'énergie de Chauffage Secondaire</label>
      <div className='col text-center'>
      <Field
        type='radio'
        className='btn-check'
        name='TEnCS'
        value='Electricité_S'
        id='TEnCS_Electricité' />
      <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-3'
              htmlFor='TEnCS_Electricité'
            >
        <img src={Elec} alt="fireSpot" width="85" height="90" />
        <span className='d-block fw-bold text-start'>
          <span className='text-dark fw-bolder d-block fs-6 text-center'>Electricité</span>    
        </span>
        </label>
       

       
      <Field
        type='radio'
        className='btn-check'
        name='TEnCS'
        value='Gaz_S'
        id='TEnCS_Gaz' />
      <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-3'
              htmlFor='TEnCS_Gaz'
            >
        <img src={Gas} alt="fireSpot" width="85" height="90"/>
        <span className='d-block fw-bold text-start'>
          <span className='text-dark fw-bolder d-block fs-6 text-center'>Gaz</span>    
        </span>
        </label>

        <Field
        type='radio'
        className='btn-check'
        name='TEnCS'
        value='Fioul_S'
        id='TEnCS_Fioul' />
      <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-3'
              htmlFor='TEnCS_Fioul'
            >
        <img src={gasoline} alt="fireSpot" width="80" height="90" />
        <span className='d-block fw-bold text-start'>
         
          <span className='text-dark fw-bolder d-block fs-6 text-center'>Fioul</span>    
        </span>
        </label>

        <Field
        type='radio'
        className='btn-check'
        name='TEnCS'
        value='Bois_S'
        id='TEnCS_Bois' />
      <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-3'
              htmlFor='TEnCS_Bois'
            >
        <img src={Bois} alt="fireSpot" width="80" height="90" />
        <span className='d-block fw-bold text-start'>
         
          <span className='text-dark fw-bolder d-block fs-6 text-center'>Bois</span>    
        </span>
        </label>

        <Field
        type='radio'
        className='btn-check'
        name='TEnCS'
        value='Aucune_S'
        id='TEnCS_Aucune' />
      <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-3'
              htmlFor='TEnCS_Aucune'
            >
        <img src={none} alt="fireSpot" width="80" height="90" />
        <span className='d-block fw-bold text-start'>
         
          <span className='text-dark fw-bolder d-block fs-6 text-center'>Aucune</span>    
        </span>
        </label>

        </div>
      </div>
      <div className='fv-row'>
            {elecIsToggled && <TypeChElec/> }
            {gazIsToggled &&  <TypeChGaz/> }
            {fioulIsToggled &&  <TypeChFioul/> }
            {boisIsToggled &&  <TypeChBois/> }

            </div>

     <div className='mb-10 fv-row'>
        <label className='form-label required '>Moyenne d'heures d'utilisation du Chauffage</label>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid  border-dark'
          name='MHC'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='MHC' />
        </div>
      </div>


      <div className='mb-10 fv-row'>
        
        <label className='form-check form-switch form-check-custom form-check-solid'>
        <label className='form-label required'>Avez-vous un programmateur de Chauffage?  </label>       
            <Field className='form-check-input ms-10' type='checkbox' value='1' checked={false} />
            
          </label>
      </div>




      <div className='fv-row mb-10 '>
      <label className='form-label required'>L'énergie utilisée pour la production de l'eau chaude sanitaire :</label>
      <div className='col text-center'>
      <Field
        type='radio'
        className='btn-check'
        name='TEnEcs'
        value='Electricité'
        id='TEnEcs_Electricité' />
      <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-3'
              htmlFor='TEnEcs_Electricité'
            >
        <img src={Elec} alt="fireSpot" width="85" height="90" />
        <span className='d-block fw-bold text-start'>
          <span className='text-dark fw-bolder d-block fs-6 text-center'>Electricité</span>    
        </span>
        </label>
       

       
      <Field
        type='radio'
        className='btn-check'
        name='TEnEcs'
        value='Gaz'
        id='TEnEcs_Gaz' />
      <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-3'
              htmlFor='TEnEcs_Gaz'
            >
        <img src={Gas} alt="fireSpot" width="85" height="90"/>
        <span className='d-block fw-bold text-start'>
          <span className='text-dark fw-bolder d-block fs-6 text-center'>Gaz</span>    
        </span>
        </label>

        <Field
        type='radio'
        className='btn-check'
        name='TEnEcs'
        value='Fioul'
        id='TEnEcs_Fioul' />
      <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-3'
              htmlFor='TEnEcs_Fioul'
            >
        <img src={gasoline} alt="fireSpot" width="80" height="90" />
        <span className='d-block fw-bold text-start'>
         
          <span className='text-dark fw-bolder d-block fs-6 text-center'>Fioul</span>    
        </span>
        </label>

        <Field
        type='radio'
        className='btn-check'
        name='TEnEcs'
        value='Solaire'
        id='TEnEcs_Solaire' />
      <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-3'
              htmlFor='TEnEcs_Solaire'
            >
        <img src={solaire} alt="fireSpot" width="80" height="90" />
        <span className='d-block fw-bold text-start'>
         
          <span className='text-dark fw-bolder d-block fs-6 text-center'>Solaire</span>    
        </span>
        </label>


        </div>
      </div>
   
      <div className='fv-row mb-10'>
         <label className='form-label required'>Quel moyen utilisé pour renouveler l'air</label>

        
         <Field
          as='select'
          name='MRenovAir'
          className='form-select  border-dark'
         >
          <option value='1'>Naturel</option>
          <option value='2'>VMC Simple Flux</option>
          <option value='3'>VMC Double Flux</option>
         </Field>

        <div className='text-danger mt-2'>
          <ErrorMessage name='MRenovAir' />
        </div>
        </div> 


        <div className='mb-10 fv-row'>
        
        <label className='form-check form-switch form-check-custom form-check-solid'>
        <label className='form-label required'> Le logement est-il équipé d'une climatisation :</label>       
            <Field className='form-check-input ms-10' type='checkbox' value='1' checked={true} />
            
          </label>
      </div>

    </div>
  )
}

export {Step3}
